import { Link } from "react-router-dom";

const AsideNavMenu = () => {
    return (
        <aside class="widget widget-nav-menu">
                                        <ul class="widget-menu fs-5">
                                            <li class="active">
                                                <Link to="#">Our Services</Link>
                                            </li>
                                            <li>
                                                <Link to="/para-medical-staffing-service">Para Medical Staffing Service</Link>
                                            </li>
                                            <li>
                                                <Link to="/it-management-service">Information Technology Management Service</Link>
                                            </li>
                                            <li>
                                                <Link to="/infrastructure-state-management-service">Infrastructure and State Management Services</Link>
                                            </li>
                                            <li>
                                                <Link to="/facility-management-service">Facility Management Service</Link>
                                            </li>
                                        </ul>
                                    </aside>
    )
}
export default AsideNavMenu;