const Telecom=()=>{
    return(
        <>
        <div id="full_slider_1"
            class="avia-fullwidth-slider main_color avia-shadow avia-builder-el-0 el_before_av_slideshow_full_custom_mobile avia-builder-el-first container-fluid no-padding">
            <div data-size="no scaling" data-lightbox_size="large" data-animation="slide" data-ids="138879"
                data-video_counter="0" data-autoplay="false" data-bg_slider="false" data-slide_height=""
                data-handle="av_slideshow_full_custom" data-interval="5000" data-class="" data-css_id=""
                data-scroll_down="" data-control_layout="av-control-default" data-custom_markup="" data-perma_caption=""
                data-autoplay_stopper="" data-image_attachment="" data-alttext="" data-custom_id="" data-src=""
                data-position="top left" data-repeat="no-repeat" data-attach="scroll" data-stretch=""
                class="avia-slideshow avia-slideshow-1 av-control-default av-default-height-applied avia-slideshow-no-scaling av_slideshow_full_custom avia-slide-slider">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src="./images/telecom-banner.jpg" class="d-block w-100 img-fluid" alt="Telecom"/>
                    </div>
                    <div class="position-absolute col-lg-4 col-md-6 col-8"
                        style={{left: '8%',top: '14%',zIndex: 50,transform: `translateY("-50%")`}}>
                        <h2 class="text-white">Design next-generation customer experiences for the 5G world
                        </h2>
                        <h3 class="text-warning mt-5">Telecom
                        </h3>
                        <p class="text-white">We help you to unlock the full potential of 5G by transitioning to a
                            leaner infrastructure, rolling out intelligent edge networks and enabling a world-class
                            online customer experience.
                        </p>
                        <p class="fw-bold mt-5 text-warning">Contact us for a demo <span><i
                                    class="fa-solid fa-arrow-right"></i></span></p>
                    </div>
                </div>
            </div>
        </div>
        <div id="breadcrumb" class="container-fluid bg-light py-2">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item fs-6">
                                    <a href="./home" title="iClimb" rel="home">Home</a>
                                </li>
                                <li class="breadcrumb-item fs-6">
                                    <a href="#" title="Services">Services</a>
                                </li>
                                <li class="breadcrumb-item active fs-6" aria-current="page">Telecom
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Our Practices area Start --> */}
        <div class="container">
            <div class="row mb-5">
                <div class="col-md-12">
                    <h1 class="text-center text-dark">Our Practice Areas</h1>
                    <h6 class="text-center text-dark">We provide a broad range of services from transitioning to a
                        leaner infrastructure, rolling out intelligent edge networks to enabling top notch customer
                        experience</h6>
                </div>
            </div>
            <div class="row text-center">
                <div class="col-lg-4 col-md-6 my-3">
                    <img src="./images/Telicom-1.png" alt="Transition to leaner infrastructure" />
                    <h3 class="text-primary mb-4">Transition to leaner infrastructure</h3>

                    <a href="#Transition" class="bg-primary rounded p-3 text-light">
                        Learn More
                        <img src="./img/rthtyjr6j.png" alt="" />
                    </a>
                </div>
                <div class="col-lg-4 col-md-6 my-3">
                    <img src="./images/Telicom-2.png" class="mt-5 mt-sm-0 mt-md-0"
                        alt="Roll out intelligent edge networks"/>
                    <h3 class="text-primary mb-4">Roll out intelligent edge networks</h3>
                    <a href="#rollout" class="bg-primary rounded p-3 text-light">
                        Learn More
                        <img src="./img/rthtyjr6j.png" alt="" />
                    </a>
                </div>
                <div class="col-lg-4 col-md-12 my-3">
                    <img src="./images/Telicom-3.png" class="mt-5 mt-sm-0 mt-md-0"
                        alt="Enable top notch customer experience"/>
                    <h3 class="text-primary mb-4">Enable top notch customer experience</h3>
                    <a href="#Enable" class="bg-primary rounded p-3 text-light">
                        Learn More
                        <img src="./img/rthtyjr6j.png" alt="" />
                    </a>
                </div>
            </div>
        </div>
        {/* <!-- Our Practices area End --> */}
        <div class="container my-5 pt-5 text-dark" id="Transition">
            <div class="row my-2">
                <div class="col-lg-6 col-md-12 p-1 pt-3 setImage text-center">
                    <img src="./images/Telicom-4.jpg" class="img-fluid" alt="Patient Engagement" />
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 class="text-primary">Simplify legacy networks, transition to leaner infrastructure​</h1>
                            <h6 class="text-justify">
                                We partner with you to simplify legacy networks, remove redundant systems, enhance
                                security measures, and consolidate and convert to a more agile and scalable platform.
                                Our services include:
                            </h6>
                            <ul class="text-li">
                                <li class="text-justify">
                                        Legacy OSS modernization for next generation OSS design and development deploying a
                                        micro-services based architecture
                                </li>
                                <li class="text-justify">
                                    VNF testing and onboarding that uses our network transformation as a service
                                    capability
                                </li>
                                <li class="text-justify"> Alignment to ONAP to enable standardized, real-time, policy-driven automation of
                                    PNFs and VNFs that helps to introduce new services faster
                                </li>
                                <li class="text-justify">Communications technology services, namely computer engineering services for OEM and
                                    service providers to ensure connectivity, reliability, and security</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- What make Iclimbs Different Start  --> */}
        <div class="container">
            <div class="row ">
                <h1 class="text-primary text-center">What makes iClimbs different
                </h1>
            </div>
            <div class="row text-center">
                <div class="col-lg-4 col-md-6 my-3">
                    <img src="images/Telicom-5.png" alt="Deep experience in hybrid networks" width="20%" />
                    <div class="card-body">
                        <h5 class="card-title mb-2" style={{color: 'orangered',textAlign: 'center'}}>
                            Deep experience in hybrid networks
                        </h5>
                        <p class="card-text">
                            Our deep experience in design, instantiation and operation in hybrid networks with modular
                            capabilities powered by ONAP to automatically scale, operate and manage virtual services
                            lifecycle.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 my-3">
                    <img src="images/Telicom-6.png" alt="Accelerated deployment" width="20%"/>
                    <div class="card-body">
                        <h5 class="card-title mb-4" style={{color: 'orangered',textAlign: 'center'}}>
                            Accelerated deployment
                        </h5>
                        <p class="card-text ">
                            We provide accelerated service creation and deployment of automated vendor-agnostic VNF
                            onboarding.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 my-3">
                    <img src="images/Telicom-7.png" alt="Strong data and Advanced technical capabilities"
                        width="20%"/>
                    <div class="card-body">
                        <h5 class="card-title mb-1" style={{color: 'orangered',textAlign: 'center'}}>
                            Strong data and Advanced technical capabilities
                        </h5>
                        <p class="card-text ">We have deep expertise in OSS stack combined with our advanced data
                            science capabilities.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- What make Iclimbs End --> */}

        <div class="container my-5 pt-5 text-dark" id="rollout">
            <div class="row my-2">
                <div class="col-lg-6 col-md-12 d-block d-lg-none setImage text-center">
                    <img src="./images/Telicom-8.jpg" class="img-fluid setImage"
                        alt="Reduce rollout costs with Intelligent Edge Networks" />
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 class="text-primary">Reduce rollout costs with Intelligent Edge Networks</h1>
                            <h6>
                                We leverage advanced capabilities in the GIS, AI/ML and workflow analytics and design to
                                help you achieve significant reductions in infrastructure rollout costs. Our services
                                include:
                            </h6>
                            <ul class="text-li">
                                <li class="text-justify">
                                    Advanced GIS based network planning to improve network design efficiency. This
                                    framework provides the capability to integrate accurate location based insights with
                                    network planning and optimization, inventory management and streamlined dispatch in
                                    real time.
                                </li>
                                <li class="text-justify">
                                        Workflow and capacity optimization of infrastructure build. Using advanced AI/ML
                                        techniques, we identify and fix bottlenecks in infrastructure buildout
                                        projects/workflow.
                                </li>
                                <li class="text-justify">
                                        Demand analytics to prioritize sites for rollout. Using advanced customer demand
                                        analytics, we can prioritize intelligent edge network rollout sites with a focus on
                                        those with high ROI.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 d-none d-lg-block setImage text-center">
                    <img src="./images/Telicom-8.jpg" class="img-fluid setImage"
                        alt="Reduce rollout costs with Intelligent Edge Networks" />
                </div>
            </div>
        </div>
        {/* <!-- What make Iclimbs Different Start  --> */}
        <div class="container">
            <div class="row">
                <h1 class="text-primary text-center">
                    What makes iClimbs different
                </h1>
            </div>
            <div class="row text-center">
                <div class="col-lg-4 col-md-6 my-3">
                    <img src="images/Telicom-9.png" alt="Automated network planning capabilities"
                        width="20%" />
                    <div class="card-body">
                        <h5 class="card-title mb-2 text-center text-primary">
                            Automated network planning capabilities
                        </h5>
                        <p class="card-text ">
                            We provide automated network planning and forecasting capabilities for real-time inventory
                            management of assets that drive opex reduction and support next-gen network deployments.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 my-3">
                    <img class="" src="images/Telicom-10.png" alt="Accelerated deployment" width="20%"/>
                    <div class="card-body">
                        <h5 class="card-title mb-4 text-center text-primary">
                            Deep AI/ML capabilities
                        </h5>
                        <p class="card-text">
                            We have a deep understanding of workflow systems and challenges and combine that
                            understanding with advanced AI/ML capabilities to deliver efficiencies in network rollout
                            processes.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 my-3">
                    <img src="images/Telicom-11.png" alt="Advanced modeling techniques" width="20%"/>
                    <div class="card-body">
                        <h5 class="card-title mb-1 text-center text-primary">
                            Advanced modeling techniques
                        </h5>
                        <p class="card-text">
                            We have experience in advanced modeling techniques that are needed to build 5G adoption
                            predictive models to inform site prioritization.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- What make Iclimbs End --> */}
        <div class="container my-5 pt-5 text-dark" id="Enable">
            <div class="row my-2">
                <div class="col-lg-6 col-md-12 setImage text-center">
                    <img src="./images/Telicom-2.jpg" class="img-fluid" alt="Patient Engagement" />
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 class="text-primary">Enable a top notch online experience</h1>
                            <h6 class="text-justify">
                                We leverage advanced data science capabilities, outstanding experience design studio,
                                modern engineering and its platform accelerators to optimize your digital channels. Our
                                offerings include:
                            </h6>
                            <ul class="text-li">
                                <li class="text-justify">Improvement in digital sales. We partner with you to improve your sales from digital
                                    channels by using funnel analyses for root-causes with recommended interventions to
                                    improve conversion rates, personalized digital experiences to drive
                                    cross-sell/upsell of your offerings and improved retargeting and conversion with
                                    optimized campaign design and execution.</li>
                                <li class="text-justify">Redesign of customer service. We help to drive targeted customer engagement
                                    strategies using VOC analyses powered by AI/ML algorithms, improve call deflection
                                    rates using ML driven anomaly detection and root-cause analyses by customer cohorts
                                    and enhance customer experience in the self-serve channels using AI chatbots.</li>
                                <li class="text-justify">
                                    Predictive device maintenance. To keep your devices running at optimum levels, we
                                    provide maintenance services that deploy AI/ML algorithms to identify specific data
                                    issues such as noisy IoT and rare event classification; modeling pipelines for fault
                                    classification and profiling; and anomaly detection and root cause analyses
                                    algorithms to enable an automated data-driven fault diagnostic process.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- What make Iclimbs Different Start  --> */}
        <div class="container">
            <div class="row">
                <h1 class="text-primary text-center">What makes iClimbs different
                </h1>
            </div>
            <div class="row text-center">
                <div class="col-lg-4 col-md-6 my-3">
                    <img class="" src="images/Telicom-13.png" alt="Rich e-commerce and design expertise" width="20%" />
                    <div class="card-body">
                        <h5 class="card-title text-center" style={{color: "orangered"}}>
                            Rich e-commerce and design expertise
                        </h5>
                        <p class="card-text">
                            We bring deep expertise in e-commerce and combine that with data science, UX design and
                            engineering to solve your customer experience problems.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 my-3">
                    <img class="" src="images/Telicom-14.png" alt="Exclusive platform with AI/ML capabilities"
                        width="20%"/>
                    <div class="card-body">
                        <h5 class="card-title mb-1 text-center" style={{color: "orangered"}}>
                            Exclusive platform with AI/ML capabilities
                        </h5>
                        <p class="card-text">
                            Our exclusive digital platform powered by AI/ML models optimizes customer service
                            initiatives.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 my-3">
                    <img src="images/Telicom-7.png" alt="Cross-industry experience for enriched modeling"
                        width="20%"/>
                    <div class="card-body">
                        <h5 class="card-title mb-1 text-center" style={{color: "orangered"}}>
                            Cross-industry experience for enriched modeling
                        </h5>
                        <p class="card-text ">
                            We have cross-industry experience to continuously improve AI/ML algorithms for fault
                            prediction rates and root cause analysis for predictive device maintenance.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- What make Iclimbs End --> */}

        </>
    )
}
export default Telecom