import React from "react";
import { Link } from "react-router-dom";
const Consulting = () => {
    return (
        <>
            <div id="full_slider_1"
                className="avia-fullwidth-slider main_color avia-shadow avia-builder-el-0 el_before_av_slideshow_full_custom_mobile avia-builder-el-first container-fluid no-padding"
            >
                <div
                    data-size="no scaling"
                    data-lightbox_size="large"
                    data-animation="slide"
                    data-ids={138879}
                    data-video_counter={0}
                    data-autoplay="false"
                    data-bg_slider="false"
                    data-slide_height=""
                    data-handle="av_slideshow_full_custom"
                    data-interval={5000}
                    data-class=""
                    data-css_id=""
                    data-scroll_down=""
                    data-control_layout="av-control-default"
                    data-custom_markup=""
                    data-perma_caption=""
                    data-autoplay_stopper=""
                    data-image_attachment=""
                    data-alttext=""
                    data-custom_id=""
                    data-src=""
                    data-position="top left"
                    data-repeat="no-repeat"
                    data-attach="scroll"
                    data-stretch=""
                    className="avia-slideshow avia-slideshow-1 av-control-default av-default-height-applied avia-slideshow-no-scaling av_slideshow_full_custom avia-slide-slider"
                >
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img
                                src="./img/Consulting-Services-1.png"
                                className="d-block w-100 img-fluid"
                                alt="Consulting Services"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div id="breadcrumb" className="container-fluid bg-light py-2">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/home" className="text-primary" title="iClimb" rel="home">
                                            Home
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a href="#" title="Services" className="text-dark">
                                            Services
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Consulting Services
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div id="content-head" className="container-fluid bg-light my-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-5 mb-3 mb-md-0">
                            <div className="av-special-heading av-special-heading-h1">
                                <h1 className="av-special-heading-tag text-primary">Consulting Services</h1>
                                <div className="special-heading-border">
                                    <div className="special-heading-inner-border" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="avia-icon-list-container">
                                <ul className="list-unstyled avia-icon-list avia-icon-list-left av-innerpagelink-big avia_animate_when_almost_visible d-flex flex-wrap">
                                    <li className="mr-2 mb-2 pe-2">
                                        <a href="#overview" className="text-dark">Overview</a> |
                                    </li>
                                    <li className="mr-2 mb-2 pe-2">
                                        <a href="#strategic-focus" className="text-dark">Strategic Focus</a> |
                                    </li>
                                    <li className="mr-2 mb-2 pe-2">
                                        <a href="#offerings" className="text-dark">Our Offerings</a> | 
                                    </li>
                                    <li className="mr-2 mb-2 pe-2">
                                        <a href="#contact" className="text-dark">Resources</a> 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="overview"
                className="avia-section main_color avia-section-default avia-no-shadow avia-bg-style-scroll avia-builder-el-13 el_after_av_section el_before_av_section container_wrap fullsize"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div
                                className="flex_column av_one_half flex_column_div av-zero-column-padding first avia-builder-el-14 el_before_av_one_half avia-builder-el-first"
                                style={{ borderRadius: 0 }}
                            >
                                <section className="av_textblock_section">
                                    <div className="avia_textblock">
                                        <p>
                                            <strong className="text-dark fs-5">
                                                Capture the Tech Alpha harnessing the power of innovation,
                                                strategy, and experience.
                                            </strong>
                                        </p>
                                        <p className="text-dark fs-5">
                                            Welcome to iClimb’s dynamic consulting practice. We provide
                                            more than just standard advice with our technology and data
                                            consulting services. We collaborate closely with your team to
                                            comprehend the particular difficulties and goals you face. We
                                            provide you the tools to maximize the return on your IT
                                            expenditures by putting cutting-edge technologies into
                                            practice and developing data-driven strategies that spur
                                            innovation and expansion.
                                        </p>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div className="col-md-6" id="overview">
                            <div
                                className="flex_column av_one_half flex_column_div av-zero-column-padding avia-builder-el-16 el_after_av_one_half el_before_av_hr"
                                style={{ borderRadius: 0 }}
                            >
                                <section className="av_textblock_section">
                                    <div className="avia_textblock">
                                        <p className="text-dark fs-5">
                                            Challenge us to accelerate and transform your business in any
                                            of our core specialties:
                                        </p>
                                        <ul className="text-li">
                                            <li>Technology and Data</li>
                                            <li>AI &amp; Automation</li>
                                            <li>Strategy, Experience &amp; Applied Innovation</li>
                                            <li>ESG &amp; Sustainability</li>
                                            <li>Transformation and Change Management</li>
                                            <li>Value Engineering</li>
                                        </ul>
                                    </div>
                                </section>
                                <br />
                                <section className="avia_codeblock_section avia_code_block_2">
                                    <div className="avia_codeblock"></div>
                                </section>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{ height: 10 }}
                        className="hr hr-invisible avia-builder-el-19 el_after_av_one_half el_before_av_hr"
                    >
                        <span className="hr-inner">
                            <span className="hr-inner-style" />
                        </span>
                    </div>
                    <div
                        style={{ height: 10 }}
                        className="hr hr-invisible avia-builder-el-20 el_after_av_hr avia-builder-el-last"
                    >
                        <span className="hr-inner">
                            <span className="hr-inner-style" />
                        </span>
                    </div>
                </div>
            </div>
            <div
                id="strategic-focus"
                className="avia-section main_color avia-section-default avia-no-border-styling av-section-color-overlay-active avia-bg-style-scroll avia-builder-el-21 el_after_av_section el_before_av_section container_wrap fullsize"
                style={{ backgroundColor: "#e1e1e1" }}
            >
                <div className="av-section-color-overlay-wrap">
                    <div
                        className="av-section-color-overlay"
                        style={{ opacity: "0.5", backgroundColor: "#ffffff" }}
                    />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 p-0">
                                <div className="template-page content av-content-full alpha units">
                                    <div className="post-entry post-entry-type-page post-entry-138494">
                                        <div className="entry-content-wrapper clearfix">
                                            <div
                                                style={{ paddingBottom: 10, fontSize: 36 }}
                                                className="av-special-heading av-special-heading-h2 blockquote modern-quote modern-centered avia-builder-el-22 el_before_av_image avia-builder-el-first av-inherit-size text-center"
                                            >
                                                <h2 className="special-heading text-primary" style={{ padding: 15, margin: 0 }}>
                                                    Strategic focus
                                                </h2>
                                                <div className="special-heading-border">
                                                    <div className="special-heading-inner-border" />
                                                </div>
                                            </div>
                                            <div className="avia-image-container av-styling avia-builder-el-23 el_after_av_heading avia-builder-el-last avia-align-center">
                                                <div className="avia-image-container-inner">
                                                    <img
                                                        className="avia_image img-fluid"
                                                        src="./img/Global-Consulting-2-1500x731.png"
                                                        alt="Global Consulting"
                                                        title="Global Consulting"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ////////////// Update content start /// */}
            <div id="offerings" className="container-fluid pb-5" style={{
                backgroundRepeat: "no-repeat",
                backgroundImage: `url("./img/white_polygon.jpg")`,
                backgroundAttachment: "scroll",
                backgroundPosition: "top left",
                backgroundSize: '100% 100%'
            }}>
                <div className="row mx-0">
                    <div className="col-md-12">
                        <div className="container my-0">
                            <div className="row">
                                <div className="col-md-12 pb-3">
                                    <h2 className="special-heading text-primary text-center py-5" style={{ padding: 15, margin: 0 }}>
                                        Our Offerings
                                    </h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <section className="text-center">
                                        <a href="#" title="Maximize Business Opportunities with Technology & Data Transformation">
                                            <img src="./img/Customer-Experience-Transformation-Icon.svg" alt="Maximize Business Opportunities with Technology & Data Transformation" className="img-fluid my-5" />
                                        </a>
                                        <h3 className="small-heading pb-1">
                                            <a href="#" title="Maximize Business Opportunities with Technology & Data Transformation">
                                                Maximize Business Opportunities with Technology &amp; Data Transformation
                                            </a>
                                        </h3>
                                    </section>
                                    <p>
                                        Leverage the potential of leading-edge technology and harness the data to propel your business towards success and beyond. We assist you with optimizing and streamlining your technological infrastructure, rationalizing, and extending your business capabilities.
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <section className="text-center">
                                        <a href="#" title="Expert Consultants for Seamless Organizational Change & Transformation">
                                            <img src="./img/Future-of-work-Icon.svg" alt="Expert Consultants for Seamless Organizational Change & Transformation" className="img-fluid my-5" />
                                        </a>
                                        <h3 className="small-heading pb-1">
                                            <a href="#" title="Expert Consultants for Seamless Organizational Change & Transformation">
                                                Expert Consultants for Seamless Organizational Change & Transformation
                                            </a>
                                        </h3>
                                    </section>
                                    <p>
                                        Navigate complex organizational changes with confidence. Our experienced consultants will guide you through transformational journeys, ensuring smooth transitions, fostering employee engagement, and maximizing the success of your change initiatives.
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <section className="text-center">
                                        <a href="#" title="Unlock Success with Strategic Thinking, Human-Centric Experiences & Innovation">
                                            <img src="./img/Core-Modernization-Icon.svg" alt="Unlock Success with Strategic Thinking, Human-Centric Experiences & Innovation" className="img-fluid my-5" />
                                        </a>
                                        <h3 className="small-heading pb-1">
                                            <a href="#" title="Unlock Success with Strategic Thinking, Human-Centric Experiences & Innovation">
                                                Unlock Success with Strategic Thinking, Human-Centric Experiences & Innovation
                                            </a>
                                        </h3>
                                    </section>
                                    <p>
                                        Stay ahead of the competition by combining strategic thinking, human-centric experiences, and innovative solutions. Our consultants will assist you with developing a comprehensive strategy that aligns with your business goals and creates unique experiences for your customers.
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <section className="text-center">
                                        <a href="#" title="Unlock Sustainability with Holistic ESG Integration for Positive Impact">
                                            <img src="./img/Product-and-Service-Innovation-Icon-1.svg" alt="Unlock Sustainability with Holistic ESG Integration for Positive Impact" className="img-fluid my-5" />
                                        </a>

                                        <h3 className="small-heading pb-1">
                                            <a href="#" title="Unlock Sustainability with Holistic ESG Integration for Positive Impact">
                                                Unlock Sustainability with Holistic ESG Integration for Positive Impact
                                            </a>
                                        </h3>
                                    </section>
                                    <p>
                                        Adopt environmental, social, and governance (ESG) practices to drive sustainability and long-term value creation. We help you integrate ESG considerations into your business strategy, operations, and reporting, enabling you to make a positive impact on society and the environment.
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <section className="text-center">
                                        <a href="#" title="Drive Intelligent Optimization & Efficiency with AI and Automation">
                                            <img src="./img/Data-and-Intelligence-Icon.svg" alt="Drive Intelligent Optimization & Efficiency with AI and Automation" className="img-fluid my-5" />
                                        </a>
                                        <h3 className="small-heading pb-1">
                                            <a href="#" title="Drive Intelligent Optimization & Efficiency with AI and Automation">
                                                Drive Intelligent Optimization & Efficiency with AI and Automation
                                            </a>
                                        </h3>
                                    </section>
                                    <p>
                                        Embrace the future of work with our AI and automation solutions. We can guide with implementing intelligent systems, from machine learning algorithms to robotic process automation, that enhance efficiency, productivity, and decision-making across your organization.
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <section className="text-center">
                                        <a href="#" title="Maximum Value Engineering through Process, Systems & Investment Optimization">
                                            <img src="./img/Sustainability-ESG-icon.svg" alt="Maximum Value Engineering through Process, Systems & Investment Optimization" className="img-fluid my-5" />
                                        </a>
                                        <h3 className="small-heading pb-1">
                                            <a href="#" title="Maximum Value Engineering through Process, Systems & Investment Optimization">
                                                Maximum Value Engineering through Process, Systems & Investment Optimization
                                            </a>
                                        </h3>
                                    </section>
                                    <p>
                                        Optimize your business processes, systems, and investments to maximise value creation. Our value engineering approach helps you identify opportunities for cost reduction, revenue growth, and operational efficiency, enabling you to achieve tangible and measurable results.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /////////////// updated content end /// */}
            </div>
            {/* <h1> form start here </h1> */}
            <div className="container-fluid py-5 bg-primary">
                <div className="row mx-0">
                    <div className="col-md-12">
                        <div className="container">
                            <div className="row" id="contact">
                                <div className="col-md-12 text-center pb-5">
                                    <h1 className="special-heading text-white">Contact Us</h1>
                                </div>
                            </div>
                            <form action="#" method="get" className="mx-auto fs-5" aria-label="Contact form" noValidate="novalidate" data-status="init">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="first-name" className="my-3 text-white">
                                                First Name <span className="required">*</span>
                                            </label>
                                            <input type="text" className="form-control" id="first-name" name="text-350" required />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="last-name" className="my-3 text-white">
                                                Last Name <span className="required">*</span>
                                            </label>
                                            <input type="text" className="form-control" id="last-name" name="text-352" required />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="email" className="my-3 text-white">
                                                Email Address <span className="required">*</span>
                                            </label>
                                            <input type="email" className="form-control" id="email" name="text-354" required />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="company" className="my-3 text-white">
                                                Company's Name <span className="required">*</span>
                                            </label>
                                            <input type="text" className="form-control" id="company" name="text-356" required />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="message" className="my-3 text-white">Message</label>
                                            <input type="text" className="form-control" id="message" name="leadDescription1" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="country" className="my-3 text-white">
                                                Country <span className="required">*</span>
                                            </label>
                                            <select className="form-control" name="country" required>
                                                <option value="">— Please choose an option —</option>
                                                <option value="india">India</option>
                                                <option value="usa">USA</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="privacycheck text-white my-3">
                                            <input type="checkbox" name="subscribed" defaultValue="I agree to receive marketing communication from iClimbs" />
                                            <span>&nbsp; I agree to receive marketing communication from iClimbs &nbsp;</span>
                                            By submitting my details I agree to iClimbs using my personal data as per the <Link to="/privacy-policy" className="text-info"> iClimbs privacy policy</Link>
                                        </p>
                                        <p>
                                            <input className="btn btn-primary" type="submit" defaultValue="Submit" />
                                            <input type="hidden" defaultValue="" name="idmkmdigid" />
                                            <input type="hidden" name="eventDescription" id="eventDescription" defaultValue="Nordic" />
                                            <input type="hidden" name="lets-meet-blog-form" id="lets-meet-blog-form" defaultValue="lets-meet-blog-form" />
                                        </p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Consulting;