import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import './usa-staffing.css'; // Import your local stylesheet

const UsaStaffing = () => {
    useEffect(() => {
        animateProgress('progress-circle-1', 25, 1865.49);
        animateProgress('progress-circle-2', 18, 2265.49);
        animateProgress('progress-circle-3', 150, 355.49);
    });

    const animateProgress = (circleId, percent, dashArray) => {
        const circle = document.getElementById(circleId);
        const progressBar = circle.querySelector('.fl-bar');
        const numberElement = circle.querySelector('.fl-number-int');

        // Set the initial styles
        progressBar.style.strokeDasharray = `0 ${dashArray}`;
        numberElement.textContent = '0';

        // Animate the progress
        setTimeout(() => {
            progressBar.style.strokeDasharray = `${percent / 100 * dashArray} ${dashArray}`;
            numberElement.textContent = percent;
        }, 100);
    };

    return (
        <div className="container-fluid px-0">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-md-12 position-relative">
                        <video muted autoPlay className="w-100" style={{ height: '75vh', objectFit: 'cover' }}>
                            <source src={process.env.PUBLIC_URL + '/video/USA-Header.mp4'} type="video/mp4" />
                        </video>
                        <div className="position-absolute top-0 py-5 start-0 px-5 my-5">
                            <div className="text-light">
                                <h1> Nationwide Staffing Solutions Based on Local<br /> Partner-Owned Offices.</h1>
                                <p className="text-large fw-bold my-4">
                                    We are uniquely positioned to work with customers in all states and industries, while still<br /> providing niche-based solutions for your business.
                                </p>
                            </div>
                            <Link to="/contact"><button className="btn btn-primary p-2">Contact us</button></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row p-5">
                    <div className="col-md-12 text-center text-dark">
                        <h2>
                            <span>A Staffing Firm
                                Like No Other.</span>
                        </h2>
                        <p className="text-center">
                            USA Staffing Services helps businesses find,
                            hire, and manage the best employees. We
                            offer customized solutions to reduce costs,
                            ensure compliance, and increase
                            profitability.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col border text-center">
                        <span className="fl-icon">
                            <i className="fas fa-user-tie" aria-hidden="true"></i>
                        </span>
                        <h2 className="fl-heading">
                            <span className="fl-heading-text">Staffing & Recruiting</span>
                        </h2>
                    </div>
                    <div className="col border text-center">
                        <span className="fl-icon">
                            <i className="fas fa-handshake" aria-hidden="true"></i>
                        </span>
                        <h2 className="fl-heading">
                            <span className="fl-heading-text">Workforce Solutions</span>
                        </h2>
                    </div>
                    <div className="col border text-center">
                        <span className="fl-icon">
                            <i className="fas fa-cubes" aria-hidden="true"></i>
                        </span>
                        <h2 className="fl-heading">
                            <span className="fl-heading-text">Staffing Technology</span>
                        </h2>
                    </div>
                    <div className="col border text-center">
                        <span className="fl-icon">
                            <i className="fas fa-user-friends" aria-hidden="true"></i>
                        </span>
                        <h2 className="fl-heading">
                            <span className="fl-heading-text">Employee Insurance</span>
                        </h2>
                    </div>
                    <div className="col border text-center">
                        <span className="fl-icon">
                            <i className="fas fa-dollar-sign" aria-hidden="true"></i>
                        </span>
                        <h2 className="fl-heading">
                            <span className="fl-heading-text">Payroll Funding</span>
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col border text-center">
                        <span className="fl-icon">
                            <i className="fas fa-money-bill-alt" aria-hidden="true"></i>
                        </span>
                        <h2 className="fl-heading">
                            <span className="fl-heading-text">Payroll Administration</span>
                        </h2>
                    </div>
                    <div className="col border text-center">
                        <span className="fl-icon">
                            <i className="fas fa-id-card-alt" aria-hidden="true"></i>
                        </span>
                        <h2 className="fl-heading">
                            <span className="fl-heading-text">Worker's Compensation</span>
                        </h2>
                    </div>
                    <div className="col border text-center">
                        <span className="fl-icon">
                            <i className="fas fa-clipboard-check" aria-hidden="true"></i>
                        </span>
                        <h2 className="fl-heading">
                            <span className="fl-heading-text">Compliance</span>
                        </h2>
                    </div>
                    <div className="col border text-center">
                        <span className="fl-icon">
                            <i className="fas fa-user-check" aria-hidden="true"></i>
                        </span>
                        <h2 className="fl-heading">
                            <span className="fl-heading-text">Risk Management</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-5">
                <div className="row py-5 text-white row-content-wrap">
                    <div className="col-md-12">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="progress-circle" id="progress-circle-1">
                                        <svg viewBox="0 0 200 200" version="1.1" preserveAspectRatio="xMinYMin meet">
                                            <circle className="fl-bar-bg" r="90" cx="100" cy="100" fill="transparent"></circle>
                                            <circle className="fl-bar" r="90" cx="100" cy="100" fill="transparent" transform="rotate(-90 100 100)"></circle>
                                        </svg>
                                        <div className="fl-number-text">
                                            <div className="fl-number-string"><span className="fl-number-int">25</span></div>
                                        </div>
                                    </div>
                                    <h4 className="text-center mt-3">Industries Served</h4>
                                </div>
                                <div className="col-md-4">
                                    <div className="progress-circle" id="progress-circle-2">
                                        <svg viewBox="0 0 200 200" version="1.1" preserveAspectRatio="xMinYMin meet">
                                            <circle className="fl-bar-bg" r="90" cx="100" cy="100" fill="transparent"></circle>
                                            <circle className="fl-bar" r="90" cx="100" cy="100" fill="transparent" transform="rotate(-90 100 100)"></circle>
                                        </svg>
                                        <div className="fl-number-text">
                                            <div className="fl-number-string"><span className="fl-number-int">18</span></div>
                                        </div>
                                    </div>
                                    <h4 className="text-center mt-3">Nationwide Licensed Partners</h4>
                                </div>
                                <div className="col-md-4">
                                    <div className="progress-circle" id="progress-circle-3">
                                        <svg viewBox="0 0 200 200" version="1.1" preserveAspectRatio="xMinYMin meet">
                                            <circle className="fl-bar-bg" r="90" cx="100" cy="100" fill="transparent"></circle>
                                            <circle className="fl-bar" r="90" cx="100" cy="100" fill="transparent" transform="rotate(-90 100 100)"></circle>
                                        </svg>
                                        <div className="fl-number-text">
                                            <div className="fl-number-string"><span className="fl-number-int">250</span></div>
                                        </div>
                                    </div>
                                    <h4 className="text-center mt-3">Serviced Companies in 2023</h4>
                                </div>
                            </div>
                            <div className="row text-center my-1">
                                <div className="col-md-12 p-5">
                                    <h1> Your Workforce Solutions Partner.</h1>
                                    <p className='text-center'>
                                        We help businesses like yours find and hire the best talent for seasonal peaks, expansions, and individual business-critical roles. Whether you need temporary staffing, direct hire services, or something in between, we have a customized solution for you.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-5">
                <div className="row text-center">
                    <div className="col-md-12">
                        <img decoding="async"
                            class="fl-photo-img wp-image-409 size-full"
                            src="./images/red-dots.png"
                            alt="red-dots" itemprop="image"
                            height="10" width="70"
                            title="red-dots" />
                    </div>
                </div>
            </div>
            <div className="container pb-5">
                <div className="row mb-3">
                    <div className="col-md-12 text-center">
                        <h3 className='text-dark'>Staffing Solutions Built For:</h3>
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col col-md-4 border">
                        <span class="fl-icon">
                            <i class="fas fa-truck"
                                aria-hidden="true"></i>
                        </span>

                        <h2 class="fl-heading">
                            <span class="fl-heading-text">Logistics
                                Companies</span>
                        </h2>
                    </div>
                    <div className="col col-md-4 border">
                        <span class="fl-icon">
                            <i class="fas fa-chart-line"
                                aria-hidden="true"></i>
                        </span>
                        <h2 class="fl-heading">
                            <span class="fl-heading-text">Start-Up
                                Ventures</span>
                        </h2>
                    </div>
                    <div className="col col-md-4 border">
                        <span class="fl-icon">
                            <i class="fas fa-id-card-alt"
                                aria-hidden="true"></i>
                        </span>
                        <h2 class="fl-heading">
                            <span class="fl-heading-text">Publicly Traded
                                Companies</span>
                        </h2>
                    </div>

                    <div className="col col-md-4 border">
                        <span class="fl-icon">
                            <i class="fas fa-hospital"
                                aria-hidden="true"></i>
                        </span>
                        <h2 class="fl-heading">
                            <span class="fl-heading-text">Hospitals</span>
                        </h2>
                    </div>
                    <div className="col col-md-4 border">
                        <span class="fl-icon">
                            <i class="fas fa-truck-loading"
                                aria-hidden="true"></i>
                        </span>
                        <h2 class="fl-heading">
                            <span class="fl-heading-text">Warehouses</span>
                        </h2>
                    </div>
                    <div className="col col-md-4 border">
                        <span class="fl-icon">
                            <i class="fas fa-phone-alt"
                                aria-hidden="true"></i>
                        </span>

                        <h2 class="fl-heading">
                            <span class="fl-heading-text">Call
                                Centers</span>
                        </h2>

                    </div>
                </div>
            </div>
            <div className="container-fluid py-5 row-content-wrap-2">
                <div className="row">
                    <div className="col-md-12 text-white text-center">
                        <h1>
                               Add the Back Office experts to your
                                business.
                        </h1>
                        <p className='text-center my-5'>
                            Your opportunity to grow is just
                            one call away.
                        </p>
                        <span class="bg-info text-white p-lg-2 border h3">
                            Contact Us
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UsaStaffing;