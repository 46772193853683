import { React, Link } from "react";
import "./gct.css";
import GCTRightSideBar from "./GCTRightSideBar";

const PublicSectoreCyberSecurity = () => {
    return (
        <>
            {/* /////////////////Banner start//////////////////// */}
            <div className="container-fluid public-sector-cyber-security-imghere position-relative">
                <div
                    className="position-absolute"
                    style={{ top: "50%", transform: "translateY(-50%)", left: "8%" }}
                >
                    <h4 className="text-white">Public Sector Cyber Security Services</h4>
                    <p className="text-white my-4 pe-5 p-md-0">
                        Cyber resilience solutions for preventing and mitigating risks
                    </p>
                    <a href="/cyber-security">
                        <div className="btn btn-primary border-0 px-4 py-3 fw-bold rounded-0 w-50">
                            Learn More
                        </div>
                    </a>
                </div>
            </div>

            {/* //////////////////Banner End///////////////////// */}

            <div className="container-fluid text-dark mx-auto ">
                <div className="row my-5">
                    <div className="col-1"></div>
                    <div className="col-lg-6">
                        <h1 className="fw-normal">Overview</h1>
                        <p className="fs-5">
                            At iClimbs India, our team of cyber security experts help clients deal with cyber security problems and solutions and offer enabling services to help them build resilience and meet the needs of the evolving landscape safely and securely.
                        </p>

                    </div>
                    <GCTRightSideBar />
                </div>
            </div>
            {/* Data Anaylsis Emerging start  */}
            <div className="container my-5">
                <div className="row py-3">
                    <div className="col-lg-12">
                        <h1 className="fw-normal text-primary" data-aos="fade-up" data-aos-duration="1000">How iClimbs India Can Help</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 mx-2 my-5 m-auto ms-auto">
                        <h1 className="text-dark" data-aos="fade-left" data-aos-duration="1000">Related Insights</h1>
                        <ul className="p-0 m-0 HowGCT" data-aos="fade-up" data-aos-duration="1000">
                            <li>Cybersecurity Strategy Policy and Program Design</li>
                            <li>Information Governance and Data Privacy</li>
                            <li>System Audit and Review</li>
                            <li>Managed Information Security Services</li>
                            <li>SOC Design and Implementation</li>
                            <li>Security Solution Implementation </li>
                            <li>Third Party Audits (Data Centre, Network, Applications)    </li>
                        </ul>
                        <a href="/cyber-security">
                            <div className="btn btn-lg btn-primary fs-6 rounded-0">
                                Learn More
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-5 mx-auto pt-5">
                        <img className="w-100" src="./images/data-analysis.jpg" alt="" />
                    </div>
                </div>
            </div>

            {/* ///////Insight////////// */}

            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-5 mx-auto">
                        <img
                            className="w-100"
                            src="./images/Insights.jpg" alt="" />
                    </div>
                    <div className="col-lg-6 mx-2 my-5 m-auto ms-auto">
                        <h1 className="text-dark">Related Insights</h1>
                        <p className="text-dark my-4">
                            Explore our recent thought leadership for updates and expert views
                        </p>
                        <a href="/cyber-security">
                            <div className="btn btn-lg btn-primary fs-6 rounded-0">
                                Learn More
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PublicSectoreCyberSecurity;