const TeamMember = () => {
    return (
        <div className="reveal">
            {/* <div className="container-fluid py-5">
                <div className="container py-5">
                    <div className="text-center mb-5 wow fadeInUp" data-wow-delay=".3s">
                        <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-info">Our Team
                        </h5>
                        <h1 className="display-5 w-50 mx-auto text-primary">Our Team Members</h1>
                    </div>
                    <div className="row g-5">
                        <div className="col-xxl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay=".3s">
                            <div className="rounded team-item">
                                <img src="img/abhinav1.jpg" className="img-fluid w-100 rounded-top border border-bottom-0" alt="Team-1" />
                                <div className="team-content bg-primary text-center py-3">
                                    <span className="fs-4 fw-bold text-light">Mr. Abhinav Kalra, CEO and MD</span>
                                    <p className="text-muted mb-0 p-2 text-light">Mr. Kalra's entrepreneurial drive is fueled by an unyielding thirst for innovation. He sees challenges as gateways to pioneering opportunities, fearlessly pursuing transformation. His visionary leadership has propelled our organization and significantly impacted the IT and consulting industry. Under his guidance, we are trailblazers, shaping the future of technology and business consulting.</p>
                                </div>
                                <div className="team-icon d-flex flex-column ">
                                    <a href="#" className="btn btn-info border-0 mb-2">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a href="#" className="btn btn-info border-0 mb-2">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a href="#" className="btn btn-info border-0 mb-2">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                    <a href="#" className="btn btn-info border-0">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay=".5s">
                            <div className="rounded team-item">
                                <img src="img/Shanky.jpeg" className="img-fluid w-100 rounded-top border border-bottom-0" alt="Team-2" />
                                <div className="team-content bg-primary text-dark text-center py-3">
                                    <span className="fs-4 fw-bold text-light">Mr. Shankey Jain, Chief Operating Officer -COO</span>
                                    <p className="text-muted mb-0 p-2 text-light">Mr. Shankey Jain, COO at IClimbs, steers the company's leadership with expertise garnered over 11 years. Renowned globally for his innovations in IT and Urban sectors, particularly in Smart Cities and WASH domains, he focuses on enhancing IT Solutions and addressing WASH challenges. His leadership, honed at TCS, KPMG, and others, drives organizational success through strategic partnerships.</p>
                                </div>
                                <div className="team-icon d-flex flex-column ">
                                    <a href="#" className="btn btn-info border-0 mb-2">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a href="#" className="btn btn-info border-0 mb-2">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a href="#" className="btn btn-info border-0 mb-2">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                    <a href="#" className="btn btn-info border-0">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay=".7s">
                            <div className="rounded team-item">
                                <img src="img/Rishi-Raj1.jpg" className="img-fluid w-100 rounded-top border border-bottom-0" alt="Team-3" />
                                <div className="team-content bg-primary text-dark text-center py-3">
                                    <span className="fs-4 fw-bold text-light">Mr. Rishi Raj Director</span>
                                    <p className="text-muted mb-0 p-2 text-light">
                                    With a deep understanding of technology and a keen eye for business opportunities, Rishi transitioned into entrepreneurship, leveraging his wealth of experience to embark on new ventures. As an entrepreneur, he brings a unique blend of technical acumen, strategic vision, and a relentless drive for success. Armed with a strong foundation built on years of hands-on experience, Rishi is poised to navigate the challenges of entrepreneurship and make significant contributions to the ever-evolving landscape of business and technology.
                                    </p>
                                </div>
                                <div className="team-icon d-flex flex-column ">
                                    <a href="#" className="btn btn-info border-0 mb-2">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a href="#" className="btn btn-info border-0 mb-2">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a href="#" className="btn btn-info border-0 mb-2">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                    <a href="#" className="btn btn-info border-0">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay=".9s">
                            <div className="rounded team-item">
                                <img src="img/Geetanjali.jpg" className="img-fluid w-100 rounded-top border border-bottom-0" alt="Team-4" />
                                <div className="team-content bg-primary text-dark text-center py-3">
                                    <span className="fs-4 fw-bold text-light">Ms. Geetanjali Rana-HR Head</span>
                                    <p className="text-muted mb-0 p-2 text-light">With years of HR experience, I prioritize fostering a positive work environment and aligning personnel with organizational goals. I aim to ensure the right people are in the right roles, equipped with necessary support for success. Committed to diversity, equity, and inclusion, I believe in their role in driving innovation. Moreover, I advocate for continuous learning, seeing it as vital for individual and organizational success.</p>
                                </div>
                                <div className="team-icon d-flex flex-column ">
                                    <a href="#" className="btn btn-info border-0 mb-2">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a href="#" className="btn btn-info border-0 mb-2">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a href="#" className="btn btn-info border-0 mb-2">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                    <a href="#" className="btn btn-info border-0">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
           <section class="team-section py-5">
    <div class="container">
        <div class="text-center mb-5">
            <h2 class="section-title mb-2">Meet Our Team</h2>
            <p class="section-subtitle">Discover the talented individuals driving our success</p>
        </div>
        <div class="row row-cols-1 row-cols-md-2 g-4">
            <div class="col">
                <div class="card h-100 border-0 shadow">
                    <img src="img/abhinav1.jpg" class="card-img-top" alt="Team Member"/>
                    <div class="card-body">
                        <h5 class="card-title">Mr. Abhinav Kalra</h5>
                        <p class="card-text">CEO and MD</p>
                        <p class="card-text word-break-keep-all">Mr. Kalra's entrepreneurial drive is fueled by an unyielding thirst for innovation. He sees challenges as gateways to pioneering opportunities, fearlessly pursuing transfor-mation. His visionary leadership has propelled our organization and significantly impacted the IT and consulting industry. Under his guidance, we are trailblazers, shaping the future of technology and business consulting.</p>
                    </div>
                    <div class="card-footer bg-primary d-flex justify-content-center">
                        <a href="#" class="btn btn-outline-light me-2"><i class="fab fa-facebook-f"></i></a>
                        <a href="#" class="btn btn-outline-light me-2"><i class="fab fa-twitter"></i></a>
                        <a href="#" class="btn btn-outline-light me-2"><i class="fab fa-instagram"></i></a>
                        <a href="#" class="btn btn-outline-light"><i class="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card h-100 border-0 shadow">
                    <img src="img/abhinav1.jpg" class="card-img-top" alt="Team Member"/>
                    <div class="card-body">
                        <h5 class="card-title">Mr. Abhinav Kalra</h5>
                        <p class="card-text">CEO and MD</p>
                        <p class="card-text word-break-keep-all">Mr. Kalra's entrepreneurial drive is fueled by an unyielding thirst for innovation. He sees challenges as gateways to pioneering opportunities, fearlessly pursuing transfor-mation. His visionary leadership has propelled our organization and significantly impacted the IT and consulting industry. Under his guidance, we are trailblazers, shaping the future of technology and business consulting.</p>
                    </div>
                    <div class="card-footer bg-primary d-flex justify-content-center">
                        <a href="#" class="btn btn-outline-light me-2"><i class="fab fa-facebook-f"></i></a>
                        <a href="#" class="btn btn-outline-light me-2"><i class="fab fa-twitter"></i></a>
                        <a href="#" class="btn btn-outline-light me-2"><i class="fab fa-instagram"></i></a>
                        <a href="#" class="btn btn-outline-light"><i class="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card h-100 border-0 shadow">
                    <img src="img/abhinav1.jpg" class="card-img-top" alt="Team Member"/>
                    <div class="card-body">
                        <h5 class="card-title">Mr. Abhinav Kalra</h5>
                        <p class="card-text">CEO and MD</p>
                        <p class="card-text word-break-keep-all">Mr. Kalra's entrepreneurial drive is fueled by an unyielding thirst for innovation. He sees challenges as gateways to pioneering opportunities, fearlessly pursuing transfor-mation. His visionary leadership has propelled our organization and significantly impacted the IT and consulting industry. Under his guidance, we are trailblazers, shaping the future of technology and business consulting.</p>
                    </div>
                    <div class="card-footer bg-primary d-flex justify-content-center">
                        <a href="#" class="btn btn-outline-light me-2"><i class="fab fa-facebook-f"></i></a>
                        <a href="#" class="btn btn-outline-light me-2"><i class="fab fa-twitter"></i></a>
                        <a href="#" class="btn btn-outline-light me-2"><i class="fab fa-instagram"></i></a>
                        <a href="#" class="btn btn-outline-light"><i class="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card h-100 border-0 shadow">
                    <img src="img/abhinav1.jpg" class="card-img-top" alt="Team Member"/>
                    <div class="card-body">
                        <h5 class="card-title">Mr. Abhinav Kalra</h5>
                        <p class="card-text">CEO and MD</p>
                        <p class="card-text word-break-keep-all">Mr. Kalra's entrepreneurial drive is fueled by an unyielding thirst for innovation. He sees challenges as gateways to pioneering opportunities, fearlessly pursuing transfor-mation. His visionary leadership has propelled our organization and significantly impacted the IT and consulting industry. Under his guidance, we are trailblazers, shaping the future of technology and business consulting.</p>
                    </div>
                    <div class="card-footer bg-primary d-flex justify-content-center">
                        <a href="#" class="btn btn-outline-light me-2"><i class="fab fa-facebook-f"></i></a>
                        <a href="#" class="btn btn-outline-light me-2"><i class="fab fa-twitter"></i></a>
                        <a href="#" class="btn btn-outline-light me-2"><i class="fab fa-instagram"></i></a>
                        <a href="#" class="btn btn-outline-light"><i class="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
            </div>
            {/* <!-- Repeat the above structure for each team member --> */}
        </div>
    </div>
</section>


        </div>
    )
}
export default TeamMember;