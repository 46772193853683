import { Link } from "react-router-dom";
import './Itrecrutment.css'
const ItRecruitmentStaffingService = () => {
    return (
        <>
            <div class="container-fluid me-0">
                <div class="row">
                    <div class="col-md-12 p-0">
                        <img
                            src="./images/IT-Recurutemnt-Banner-2.jpg"
                            class="d-block w-100 img-fluid"
                            style={{height: '70vh'}}
                            alt="IT Recruitment Staffing Services"
                        />
                    </div>
                </div>
            </div>
            {/* <!-- main section start --> */}
            <div class="container pt-5 pb-5">
                <div class="row pt-5">
                    <div class="col-md-6">
                        <h2 class="text-dark">IT Recruitment And Staffing Service</h2>
                        <p class="fs-5">
                            For startups or large enterprises, our extensive RPO services
                            cater to diverse recruitment needs. These solutions, crafted for
                            enhanced business productivity and cost-effectiveness, offer
                            flexibility tailored to your requirements, ensuring the
                            acquisition of top talent. Leveraging I Climb's proven search
                            methodology, coupled with profound IT expertise and extensive
                            offshore staffing experience, we deliver scalable and
                            cost-effective solutions. From CV sourcing to advertisement
                            placement, screening, and management reporting, our comprehensive
                            RPO services cover every aspect. With over 6 years of talent
                            acquisition experience, recruiting over 3,000 individuals, trust
                            us for the best talent in the IT/Telecom industry.
                        </p>
                    </div>
                    <div class="col-md-6 d-flex mx-auto">
                        <img src="./images/It-recurtment.png" alt="" />
                    </div>
                </div>
                <div className="recrument-staff">
                <h1 className="text-primary">Our Services</h1>
                      <ul className="recrutment-center">
                        <li><a>General Staffing</a></li>
                        <li><a>Professional Staffing</a></li>
                        <li><a>Permanent Placement</a></li>
                        <li><a>Outsourcing Solutions</a></li>
                        <li><a>Compliance and Payroll Management</a></li>
                        <li><a>Offshore Recruitment</a></li>
                        <li><a>USA IT Staffing</a></li>
                      </ul>
                </div>
                {/* <div class="d-flex">
                    <div class="col-md-12">
                        <h5 class="text-dark">Our Services –</h5>
                        <ul class="server-type d-flex w-75 flex-wrap-wrap">
                            <li className="w-50 p-3 bg-primary h-100">
                                <a class="nav-link text-white fs-5 " href="#">General Staffing</a>
                            </li>
                            <li className="w-50 p-3 bg-primary h-100">
                                <a class="nav-link text-white" href="#">Professional Staffing</a>
                            </li>
                            <li className="w-50 p-3 bg-primary h-100">
                                <a class="nav-link text-white" href="#">Permanent Placement</a>
                            </li>
                            <li className="w-50 p-3 bg-primary h-100">
                                <a class="nav-link text-white" href="#">Outsourcing Solutions</a>
                            </li>
                            <li className="w-50 p-0 bg-primary h-100">
                                <a class="nav-link text-white" href="./payroll-management.html"
                                >Compliance and Payroll Management</a
                                >
                            </li>
                            <li className="w-50 p-3 bg-primary h-100">
                                <a class="nav-link text-white" href="#">Offshore Recruitment</a>
                            </li>
                            <li className="w-50 p-3 bg-primary h-100">
                                <Link class="nav-link text-white" to="/usa-staffing"
                                >USA IT Staffing</Link>
                            </li>
                        </ul>
                    </div>
                </div> */}
            </div>
            {/* <!-- main section end  --> */}
        </>
    )
}
export default ItRecruitmentStaffingService;