import OurClients from '../include/our-clients';
import { Link } from 'react-router-dom';
function Footer3() {
    return (
        <div className="container-fluid bg-primary">
            <div className='row'>
                <div className='text-center'>
                    <OurClients />
                </div>
            </div>
            <div className='container'>
                <div className='row footer-content mt-3'>
                    <div className="col-lg-4 col-12 text-center text-md-start">
                        <img src="./img/iclimbs-logo.png" alt="logo" className='mb-2' />
                        <p className='fs-7 text-white'>In the Indian market, our company specializes in paramount paramedical services. Our trained professionals collaborate seamlessly with medical practitioners, playing a pivotal role in patient care, diagnostics, and healthcare delivery. Proficient in diverse disciplines such as Emergency Medical Services, Laboratory technology, radiography, and physiotherapy, our team contributes significantly to India's healthcare system</p>
                    </div>
                    <div className="col-lg-4 col-12 text-center text-md-start">
                        <div className="row">
                            <div className="col-md-6"></div>
                            <div className="col-md-6">
                                <h5 className='text-white'>Quick-Links</h5>
                                <ul className='p-0'>
                                    <li className='mb-3'><Link to='/about' className='text-white'>About</Link></li>
                                    <li className='mb-3'><Link to='/' className='text-white'>Career</Link></li>
                                    <li className='mb-3'><Link to='/' className='text-white'>Blog</Link></li>
                                    <li className='mb-3'><Link to='/contact' className='text-white'>Contact Us</Link></li>
                                    <li className='mb-3'><Link to='/skill-development' className='text-white'>Skill & Development</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 text-center text-md-start">
                        <div className="row">
                            <div className="col-md-6"></div>
                            <div className="col-md-6">
                                <h5 className='text-white'>Policy</h5>
                                <ul className='p-0'>
                                    <li className="mb-3">
                                        <Link to='https://iclimbs.com/sitemap.xml' target='_blank' className='text-white' >Sitemap</Link>
                                    </li>
                                    <li className="mb-3"><Link to='/privacy-policy' className='text-white' >Privacy Policy</Link></li>
                                    <li className="mb-3"><Link to='' className='text-white'>Terms & conditions</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className='d-flex justify-content-center'>
                            <div className='m-2 text-white fs-3'>
                                <Link target='_blank' to='https://www.facebook.com/people/Iclimbs/61555634143153/' className='text-white'><i className="icn fa-brands fa-facebook"></i></Link>
                            </div>
                            <div className='m-2 text-white fs-3'>
                                <Link target='_blank' to='mailto:contact@iclimbs.com' className='text-white'><i className="icn fa-regular fa-envelope"></i></Link>
                            </div>
                            <div className='m-2 text-white fs-3'>
                                <Link target='_blank' to='https://twitter.com/IClimbs1' className='text-white'><i className="icn fa-brands fa-twitter"></i></Link>
                            </div>
                            <div className='m-2 text-white fs-3'>
                                <Link target='_blank' to='https://www.linkedin.com/company/limbs/?viewAsMember=true' className='text-white'><i className="icn fa-brands fa-linkedin"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer3;