import React from "react";
const Cybersecurity = () => {
  return (
    <>
      <div id="full_slider_1" className="avia-fullwidth-slider main_color avia-shadow avia-builder-el-0 el_before_av_slideshow_full_custom_mobile avia-builder-el-first container-fluid no-padding">
        <div data-size="no scaling" data-lightbox_size="large" data-animation="slide" data-ids="138879" data-video_counter="0" data-autoplay="false"
          data-bg_slider="false" data-slide_height="" data-handle="av_slideshow_full_custom" data-interval="5000" data-class=""
          data-css_id="" data-scroll_down="" data-control_layout="av-control-default" data-custom_markup="" data-perma_caption=""
          data-autoplay_stopper="" data-image_attachment="" data-alttext="" data-custom_id="" data-src="" data-position="top left"
          data-repeat="no-repeat" data-attach="scroll" data-stretch="" className="avia-slideshow avia-slideshow-1 av-control-default av-default-height-applied avia-slideshow-no-scaling av_slideshow_full_custom avia-slide-slider">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="./img/Cyber-Defense-Resiliency-Service.png" className="d-block w-100 img-fluid" alt="Cyber Defense Resiliency Service" />
            </div>
          </div>
        </div>
      </div>
      <div id="breadcrumb" className="container-fluid bg-light py-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/home" title="iClimb" rel="home" className="text-primary">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" title="Services" className="text-dark">Services</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Cyber Defense Resiliency Service
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div id="content-head" className="container-fluid bg-light py-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 mb-3 mb-md-0">
              <div className="av-special-heading av-special-heading-h1">
                <h1 className="av-special-heading-tag text-primary">Cyber Defense Resiliency Service</h1>
                <div className="special-heading-border">
                  <div className="special-heading-inner-border"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="av_section_4" className="avia-section main_color avia-section-default avia-no-border-styling avia-bg-style-scroll avia-builder-el-15 el_after_av_section el_before_av_section container_wrap fullsize">
        <div className="container">
          <div className="template-page content av-content-full alpha units">
            <div className="post-entry post-entry-type-page post-entry-12220">
              <div className="entry-content-wrapper clearfix">
                <section className="av_textblock_section">
                  <div className="avia_textblock">
                    <p className="p1 text-dark" style={{ fontSize: '16px', fontWeight: 'bold' }}>Next-Gen Cybersecurity Solutions: Empowering Cyber Defense Resiliency</p>
                  </div>
                </section>
                <div className="flex_column av_three_fifth flex_column_div av-zero-column-padding first avia-builder-el-17 el_after_av_textblock el_before_av_two_fifth column-top-margin"
                  style={{ borderRadius: '0px' }}>
                  <p>
                    <section className="av_textblock_section">
                      <div className="avia_textblock">
                        <p>You cannot compromise on protecting your digital assets. Our proactive approach
                          to threat detection, risk management, and compliance is reflected in our
                          cybersecurity solutions. We strengthen your defenses with ongoing monitoring
                          and cutting-edge threat intelligence so you can concentrate on your main
                          business without worrying about security.</p>
                      </div>
                    </section>
                    <br />
                    <div style={{ paddingBottom: '10px', color: '#232323' }} className="av-special-heading av-special-heading-h4 custom-color-heading avia-builder-el-19 el_after_av_textblock el_before_av_codeblock">
                      <h4 className="av-special-heading-tag">Our state-of-the-art enterprise cybersecurity solutions are powered by:</h4>
                      <div className="special-heading-border">
                        <div className="special-heading-inner-border" style={{ borderColor: '#232323' }}></div>
                      </div>
                    </div>
                    <br />
                    <section className="avia_codeblock_section avia_code_block_1">
                      <div className="avia_codeblock">
                        <div className="row">
                          <div className="col-lg-3 col-md-6 text-center d-flex align-items-center justify-content-center">
                            <div>
                              <h2>
                                <strong>15+ </strong>
                              </h2>
                              <p style={{ color: '#00008c' }}>Centers of Excellence (CoEs)</p>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 text-center d-flex align-items-center justify-content-center">
                            <div>
                              <h2>
                                <strong>8 </strong>
                              </h2>
                              <p style={{ color: '#00008c' }}>Cyber Defense Resiliency Centers</p>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 text-center d-flex align-items-center justify-content-center">
                            <div>
                              <h2>
                                <strong>25+ </strong>
                              </h2>
                              <p style={{ color: '#00008c' }}>Delivery accelerators</p>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 text-center d-flex align-items-center justify-content-center">
                            <div>
                              <h2>
                                <strong>25+ </strong>
                              </h2>
                              <p style={{ color: '#00008c' }}>Industry-leading Partnerships</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                  </p>
                </div>

                <div style={{ height: '50px' }} className="hr hr-invisible avia-builder-el-24 el_after_av_two_fifth avia-builder-el-last">
                  <span className="hr-inner">
                    <span className="hr-inner-style"></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div id="offering" className="avia-section main_color avia-section-default avia-no-border-styling avia-bg-style-scroll avia-builder-el-25 el_after_av_section el_before_av_section container_wrap fullsize"
        style={{ backgroundColor: '#2b2e8b' }}>
        <div className="container">
          <div className="template-page content av-content-full alpha units">
            <div className="post-entry post-entry-type-page post-entry-12220">
              <div className="entry-content-wrapper clearfix">
                <div style={{ paddingBottom: '30px', color: '#ffffff', fontSize: '32px' }} className="av-special-heading av-special-heading-h2 custom-color-heading blockquote modern-quote avia-builder-el-26 el_before_av_one_third avia-builder-el-first av-inherit-size text-center">
                  <h2 className="special-heading" style={{ padding: '15px', margin: '0' }}>Our Offerings
                  </h2>
                  <div className="special-heading-border">
                    <div className="special-heading-inner-border" style={{ borderColor: '#ffffff' }}></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <div className="card mx-auto" style={{ borderWidth: '1px', borderColor: '#ffffff', borderStyle: 'solid', borderRadius: '0px', backgroundColor: 'transparent' }}>
                      <img src="./img/Managed-Security-Services.svg" className="card-img-top" alt="Managed Security Services" style={{ width: '60px', height: '60px', marginLeft: '20px', marginTop: '20px' }} />
                      <div className="card-body" style={{ color: '#ffffff', padding: '20px' }}>
                        <h3 className="card-title">Managed Security Services</h3>
                        <p className="card-text  ">Comprehensive cyber security framework with ambitious SLAs and measurable KPIs
                          to ensure your security framework remains resilient to the latest cyber threats.</p>
                        <div style={{ height: '10px' }}></div>
                        <a href="#" className="btn btn-light">Read More</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <div className="card mx-auto" style={{ borderWidth: '1px', borderColor: '#ffffff', borderStyle: 'solid', borderRadius: '0px', backgroundColor: 'transparent' }}>
                      <img src="./img/Identity-and-Access-Management.svg" className="card-img-top" alt="Identity and Access Management" style={{ width: '60px', height: '60px', marginLeft: '20px', marginTop: '20px' }} />
                      <div className="card-body" style={{ color: '#ffffff', padding: '20px' }}>
                        <h3 className="card-title">Identity and Access Management</h3>
                        <p className="card-text  ">Enabling Trusted Digital Enterprises with Digital Identity Assurance & Compliance.</p>
                        <div style={{ height: '10px' }}></div>
                        <a href="#" className="btn btn-light">Read More</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <div className="card mx-auto" style={{ borderWidth: '1px', borderColor: '#ffffff', borderStyle: 'solid', borderRadius: '0px', backgroundColor: 'transparent' }}>
                      <img src="./img/Applications-Security.svg" className="card-img-top" alt="Applications Security" style={{ width: '60px', height: '60px', marginLeft: '20px', marginTop: '20px' }} />
                      <div className="card-body" style={{ color: '#ffffff', padding: '20px' }}>
                        <h3 className="card-title">Applications Security</h3>
                        <p className="card-text  ">New-age design, testing, implementation, and configuration solutions to defend
                          against application security breaches.</p>
                        <div style={{ height: '10px' }}></div>
                        <a href="#" className="btn btn-light">Read More</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <div
                      className="card mx-auto"
                      style={{
                        borderWidth: 1,
                        borderColor: "#ffffff",
                        borderStyle: "solid",
                        borderRadius: 0,
                        backgroundColor: "transparent"
                      }}
                    >
                      <img
                        src="./img/Advance-Threat-Vulnerability-Management.svg"
                        className="card-img-top"
                        alt="Advance Threat & Vulnerability Management"
                        style={{
                          width: 60,
                          height: 60,
                          marginLeft: 20,
                          marginTop: 20
                        }}
                      />
                      <div className="card-body" style={{ color: '#ffffff', padding: '20px' }}>
                        <h3 className="card-title">
                          Advance Threat
                          <span className="special_amp">&amp;</span>
                          Vulnerability Management
                        </h3>
                        <p className="card-text">
                          Closing the vulnerability gap with orchestration
                          framework, CoE-driven services, and ATVM-as-a-service
                          approach.
                        </p>
                        <div class="pt-3" />
                        <a href="#" className="btn btn-light">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <div
                      className="card mx-auto"
                      style={{
                        borderWidth: 1,
                        borderColor: "#ffffff",
                        borderStyle: "solid",
                        borderRadius: 0,
                        backgroundColor: "transparent"
                      }}
                    >
                      <img
                        src="./img/Cloud-Threat-Defense.svg"
                        className="card-img-top"
                        alt="Cloud Threat Defense"
                        style={{
                          width: 60,
                          height: 60,
                          marginLeft: 20,
                          marginTop: 20
                        }}
                      />
                      <div className="card-body" style={{ color: '#ffffff', padding: '20px' }}>
                        <h3 className="card-title">Cloud Security Services</h3>
                        <p className="card-text  ">
                          Secure and govern the multi-cloud environment against
                          evolving new-age cyber threats.
                        </p>
                        <div class="pt-3" />
                        <a href="#" className="btn btn-light">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <div
                      className="card mx-auto"
                      style={{
                        borderWidth: 1,
                        borderColor: "#ffffff",
                        borderStyle: "solid",
                        borderRadius: 0,
                        backgroundColor: "transparent"
                      }}
                    >
                      <img
                        src="./img/Governance-Risk-and-Compliance.svg"
                        className="card-img-top"
                        alt="Governance, Risk and Compliance"
                        style={{
                          width: 60,
                          height: 60,
                          marginLeft: 20,
                          marginTop: 20
                        }}
                      />
                      <div
                        className="card-body" style={{ color: '#ffffff', padding: '20px' }}
                      >
                        <h3 className="card-title">
                          Governance, Risk and Compliance
                        </h3>
                        <p className="card-text  ">
                          Manage risks, stay compliant with evolving regulatory
                          mandates.
                        </p>
                        <div class="pt-3" />
                        <a href="#" className="btn btn-light">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <div
                      className="card mx-auto"
                      style={{
                        borderWidth: 1,
                        borderColor: "#ffffff",
                        borderStyle: "solid",
                        borderRadius: 0,
                        backgroundColor: "transparent"
                      }}
                    >
                      <img
                        src="./img/Data-Security-.svg"
                        className="card-img-top"
                        alt="Data Security"
                        style={{
                          width: 60,
                          height: 60,
                          marginLeft: 20,
                          marginTop: 20
                        }}
                      />
                      <div
                        className="card-body" style={{ color: '#ffffff', padding: '20px' }}
                      >
                        <h3 className="card-title"> Data Security </h3>
                        <p className="card-text  ">
                          Digitize the core and enhance the data-driven experience
                          with a focus on data privacy.
                        </p>
                        <div class="pt-3" />
                        <a href="#" className="btn btn-light">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <div
                      className="card mx-auto"
                      style={{
                        borderWidth: 1,
                        borderColor: "#ffffff",
                        borderStyle: "solid",
                        borderRadius: 0,
                        backgroundColor: "transparent"
                      }}
                    >
                      <img
                        src="./img/Digital-Defense.svg"
                        className="card-img-top"
                        alt="Digital Defense"
                        style={{
                          width: 60,
                          height: 60,
                          marginLeft: 20,
                          marginTop: 20
                        }}
                      />
                      <div
                        className="card-body" style={{ color: '#ffffff', padding: '20px' }}
                      >
                        <h3 className="card-title">IoT- OT Security.</h3>
                        <p className="card-text  ">
                          Manage the complexities of IoT/OT security risk with the
                          right experience, solutions, and frameworks.
                        </p>
                        <div class="pt-3" />
                        <a href="#" className="btn btn-light">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <div
                      className="card mx-auto"
                      style={{
                        borderWidth: 1,
                        borderColor: "#ffffff",
                        borderStyle: "solid",
                        borderRadius: 0,
                        backgroundColor: "transparent"
                      }}
                    >
                      <img
                        src="./img/Icon_-SASE-Secure-access-services-edge-1.svg"
                        className="card-img-top"
                        alt="Icon_ SASE (Secure access services edge)-1"
                        style={{
                          width: 60,
                          height: 60,
                          marginLeft: 20,
                          marginTop: 20
                        }}
                      />
                      <div
                        className="card-body" style={{ color: '#ffffff', padding: '20px' }}
                      >
                        <h3 className="card-title">
                          Secure access service edge
                        </h3>
                        <p className="card-text  ">
                          Transform your business with next generation SASE services
                          to protect sensitive data and network from cyber threats .
                        </p>
                        <div class="pt-3" />
                        <a href="#" className="btn btn-light">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Cybersecurity;