import './digital-marketing.css';
const DigitalMarketing=()=>{
    return(
        <>
             <div id="full_slider_1" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="./img/Platform-and-Services-Pages-Desktop-Digital-Marketing-and-E-Commerce.png" class="d-block w-100" alt="Digital Marketing and E-commerce banner"/>
                </div>
                <div class="carousel-item">
                    <img src="./img/Experience-Design-and-Innovation-Driving-enterprise-wide-intelligent-digital-conversations-Spotlight-Desktop.png"
                        class="d-block w-100" alt="Experience Design and Innovation Driving enterprise-wide intelligent digital conversations Spotlight Desktop"/>
                    <div class="carousel-caption d-none d-md-block">
                        <h2 style={{color: "#ffffff"}} class="avia-caption-title">How A Global Timeshare Company Accelerated Its Digital Marketing Engine To Acquire New Owners And
                            Boost The Topline</h2>
                        <br/>
                        <a href="#" target="_blank" class="btn btn-primary"
                            data-duration="800" data-easing="easeInOutQuad">Read More
                        </a>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#full_slider_1" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#full_slider_1" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
            <ol class="carousel-indicators">
                <li data-target="#full_slider_1" data-slide-to="0" class="active"></li>
                <li data-target="#full_slider_1" data-slide-to="1"></li>
            </ol>
        </div>

        <div id="breadcrumb" class="container-fluid bg-light py-2">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item fs-6">
                                    <a href="/home" title="iClimb" rel="home">Home</a>
                                </li>
                                <li class="breadcrumb-item fs-6">
                                    <a href="#" title="Services">Services</a>
                                </li>
                                <li class="breadcrumb-item active fs-6" aria-current="page">Digital Marketing</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>

        <div id="content-head" class="container-fluid bg-light py-3">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-5 mb-3 mb-md-0">
                        <div class="av-special-heading av-special-heading-h1">
                            <h1 class="av-special-heading-tag text-dark">Digital Marketing</h1>
                            <div class="special-heading-border">
                                <div class="special-heading-inner-border"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="avia-icon-list-container">
                            <ul style={{fontSize:'10px'}} class="list-unstyled avia-icon-list avia-icon-list-left av-innerpagelink-big avia_animate_when_almost_visible d-flex flex-wrap">
                                <li class="mr-2 mb-2 pe-2" style={{fontSize:'16px'}} >
                                    <a href="#overview">Overview</a>
                                </li>
                                <li class="mr-2 mb-2 pe-2" style={{fontSize:'16px'}} >
                                    <a href="#our-offerings">Our Offerings</a>
                                </li>
                                <li class="mr-2 mb-2 pe-2" style={{fontSize:'16px'}} >
                                    <a href="#custom-slider">Resources</a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="overview" class="avia-section main_color avia-section-default avia-no-shadow avia-bg-style-scroll avia-builder-el-11 el_after_av_section el_before_av_section container-fluid">
            <div class="container">
                <div class="row reveal">
                    <div class="col-md-4">
                        <div class="flex_column av_one_fourth flex_column_div av-zero-column-padding first avia-builder-el-12 el_before_av_one_fourth avia-builder-el-first"
                            style={{borderRadius: "0px"}}>
                            <section class="av_textblock_section">
                                <div class="avia_textblock av_inherit_color" style={{fontSize: "15px", color: "#494949"}}>
                                    <p class="content">It's critical to have an online presence in the digital age. Our team of digital marketing
                                        specialists develops customized plans to boost your company's reputation, raise awareness,
                                        and encourage deep interaction. With our blockchain solutions, you can embrace the
                                        future of conferences and guarantee traceability, security, and transparency for
                                        your online gatherings.</p>
                                    <p className="content fs-5" style={{fontWeight:'bold'}}>Our consumer engagement services include:</p>
                                    <ul>
                                        <li className='fs-6'>Marketing and commerce platforms</li>
                                        <li className='fs-6'>Direct to consumer commerce</li>
                                        <li className='fs-6'>
                                            <a href="#">Mobile app development services</a>
                                        </li>
                                        <li className='fs-6'>Emerging channels</li>
                                        <li className='fs-6'>Digital asset management</li>
                                        <li className='fs-6'>Intelligent marketing, commerce and IT operations</li>
                                        <li className='fs-6'>Measurement, visualization and consumer analytics</li>
                                    </ul>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="flex_column av_one_fourth flex_column_div av-zero-column-padding avia-builder-el-14 el_after_av_one_fourth el_before_av_one_fourth"
                            style={{borderRadius: "0px"}}>
                            <section class="av_textblock_section">
                                <div class="avia_textblock av_inherit_color" style={{fontSize: "15px", color: "#494949"}}>
                                    <p class="content">We help organizations break down complexities to engage with consistent information based
                                        on customer data and insights. We help deliver an optimal experience for each moment
                                        and touchpoint that is fast, free, transparent and customizable across all channels.</p>
                                    <p class="content fs-5 " style={{fontWeight:'bold'}}>Our customer and partner engagement services include:</p>
                                    <ul>
                                        <li className='fs-6'>Account-based marketing</li>
                                        <li className='fs-6'>Digital assets and content management</li>
                                        <li className='fs-6'>B2B marketing and commerce</li>
                                        <li className='fs-6'>Direct to professional, D2C, and retailer commerce</li>
                                        <li className='fs-6'>AR/VR for evaluation, collaboration and service</li>
                                        <li className='fs-6'>Customer data hubs</li>
                                        <li className='fs-6'>CRM and loyalty</li>
                                        <li className='fs-6'>
                                            <a href="#">Intelligent marketing, commerce and IT operation automation</a>
                                        </li>
                                        <li className='fs-6'>Measurement, visualization and product analytics</li>
                                    </ul>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="flex_column av_one_fourth flex_column_div av-zero-column-padding avia-builder-el-16 el_after_av_one_fourth el_before_av_one_fourth"
                            style={{borderRadius: "0px"}}>
                            <section class="av_textblock_section">
                                <div class="avia_textblock av_inherit_color" style={{fontSize: "15px", color: "#494949"}}>
                                    <p class="content">Our differentiated approach encompasses journey-based experience strategy and realization,
                                        data and insights for targeting, personalization and conversion, efficiency through
                                        AI and automation, centralized operations and shared services model, cost optimization
                                        due to economies of scale with scale on demand, and matured delivery approach with
                                        product IT model.</p>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="offering" class="avia-section main_color avia-section-default avia-no-border-styling avia-full-stretch av-parallax-section av-section-color-overlay-active avia-bg-style-parallax  avia-builder-el-20  el_after_av_section  el_before_av_section  container_wrap fullsize"
            style={{backgroundColor: "#ffffff"}} data-section-bg-repeat="stretch">
            <div class="av-parallax" data-avia-parallax-ratio="0.3">
                <div class="av-parallax-inner main_color  avia-full-stretch" style={{backgroundColor: "#ffffff", backgroundRepeat: "no-repeat", backgroundImage: `url('./img/white_polygon.jpg')`, backgroundAttachment: "scroll", backgroundPosition: "top center"}}>
                </div>
            </div>
            <div class="av-section-color-overlay-wrap">
                <div class="av-section-color-overlay" style={{opacity:0.5, backgroundColor: "#ffffff"}}></div>
                <div class="container">
                    <div class="template-page content  av-content-full alpha units">
                        <div class="post-entry post-entry-type-page post-entry-38285">
                            <div class="entry-content-wrapper clearfix">
                                <div id class="offering-icon col-centered offerings custom-block reveal">
                                    <div class="avia-smallarrow-slider-heading ">
                                        <div class="new-special-heading text-center">
                                            <h2 class="special-heading" style={{ padding: "15px",color:"black"}}>Our Offerings
                                            </h2>
                                        </div>
                                    </div>
                                    <div id class=' col-centered offerings custom-block' style={{color:"#494949"}}>
                                        <div class='avia-smallarrow-slider-heading  no-content-slider-heading '>
                                            <div class="new-special-heading">&nbsp;</div>
                                        </div>
                                        <div class="row avia-content-slider-inner" id="offer-content">
                                            <div class="col-12 col-md-6">
                                                <section class="flex_column av_one_third col-centered post-entry slide-entry-overview slide-loop-1 slide-parity-odd  av_one_fifth first text-center">
                                                    {/* <!-- Content for the first section goes here --> */}

                                                    <div class="avia_image_container">
                                                        <img src="./img/Experience-Driven-Commerce.png" alt='Experience Driven Commerce'/>
                                                    </div>

                                                    <h3 class='smaller-heading'>
                                                        <a href="#" title='Cloud Ops'>Experience Driven Commerce</a>
                                                    </h3>
                                                    <div class='slide-entry-excerpt entry-content'>
                                                        <p class="para" className='text-dark fs-6'>We take an outcome and data-driven approach to marketing, sales and
                                                            services to deliver seamless commerce experience and frictionless
                                                            commerce for consumers, customers and partners.
                                                        </p>
                                                    </div>
                                                </section>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <section class="flex_column av_one_third col-centered post-entry slide-entry-overview slide-loop-2 slide-parity-even  av_one_fifth text-center">
                                                    {/* <!-- Content for the second section goes here --> */}

                                                    <div class="avia_image_container">
                                                        <img src="./img/Conversion-Optimization.png" alt='Conversion Optimization'/>
                                                    </div>

                                                    <h3 class='smaller-heading'>
                                                        <a href="#" title='Digital Workplace Services'>Conversion Optimization
                                                        </a>
                                                    </h3>
                                                    <div class='slide-entry-excerpt entry-content'>
                                                        <p class="para" className='text-dark fs-6'>Our conversion optimization services include reach maximization,
                                                            behavior analytics, personalization, coversion path optimization,
                                                            personalization, and KPI measurements.
                                                        </p>
                                                    </div>
                                                </section>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <section class="flex_column av_one_third col-centered post-entry slide-entry-overview slide-loop-3 slide-parity-odd  av_one_fifth text-center">

                                                    <div class="avia_image_container">
                                                        <img src="./img/Digital-Commerce-Experience-Transformation.png" alt='Digital Commerce Experience Transformation'/>
                                                    </div>

                                                    <h3 class='smaller-heading'>
                                                        <a href="#" title="ServiceNow">Digital Commerce Experience Transformation
                                                        </a>
                                                    </h3>
                                                    <div class='slide-entry-excerpt entry-content'>
                                                        <p class="para" className='text-dark fs-6'>Accelerate your growth with our digital commerce services.
                                                        </p>
                                                    </div>
                                                </section>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <section class="flex_column av_one_third col-centered post-entry slide-entry-overview slide-loop-3 slide-parity-odd  av_one_fifth text-center">
                                                    {/* <!-- Content for the third section goes here --> */}
                                                    <div class="avia_image_container">
                                                        <img src="./img/Integrated-Go-To-Market-Solutions.png" alt='Integrated Go-To-Market Solutions'/>
                                                    </div>
                                                    <h3 class='smaller-heading'>Integrated Go-To-Market Solutions
                                                    </h3>
                                                    <div class='slide-entry-excerpt entry-content'>
                                                        <p class="para" className='text-dark fs-6'>Our integrated offerings help organizations bridge the divide between
                                                            marketing, sales and service, and thus realize meaningful interactions
                                                            with consumers and customers.</p>
                                                    </div>
                                                </section>
                                            </div>

                                            <div id="last-content">
                                                <div class="col-12 col-md-6">
                                                    <section class="flex_column av_one_third col-centered post-entry slide-entry-overview slide-loop-3 slide-parity-odd  av_one_fifth text-center">
                                                        {/* <!-- Content for the third section goes here --> */}

                                                        <div class="avia_image_container">
                                                            <img src="./img/Intelligent-Support.png" alt='Intelligent Support'/>
                                                        </div>

                                                        <h3 class='smaller-heading'>Intelligent Support</h3>
                                                        <div class='slide-entry-excerpt entry-content'>
                                                            <p className='text-dark fs-6'>We provide deep monitoring &#038;SRE approach for support, and
                                                                intelligent operations based on AI/ML to deliver quality
                                                                and scale at non-linear cost.</p>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                            {/* <!-- Add more sections as needed --> */}
                                        </div>
                                    </div>
                                </div>
                                <section class="avia_codeblock_section avia_code_block_3">
                                    <div class='avia_codeblock '>
                                        
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default DigitalMarketing;