import React from "react";
import './infrastructure-state.css'
import GetYourQuote from "../include/get-your-qutes";
import AsideNavMenu from "../include/aside-nav-menu";
import { Link } from "react-router-dom";

const ItManagementService = () => {
    return (
        <>
            <div class="ttm-page-title-row">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="title-box ttm-textcolor-white">
                                <div class="page-title-heading">
                                    <h1 class="title text-white">Information Technology Management Service</h1>
                                </div>
                                <div class="breadcrumb-wrapper text-white">
                                    <span>
                                        <Link title="Homepage" to="/home">
                                            <i class="fa-solid fa-house"></i>&nbsp;&nbsp;Home
                                        </Link>
                                    </span>
                                    <span class="ttm-bread-sep">&nbsp; | &nbsp;</span>
                                    <span> Information Technology Management  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div className="container">
                    <div className="row mt-5">
                        <div class="col-lg-9">
                            <div class="container-fluid text-dark">
                                <div class="row">
                                    <div class="col-md-12">
                                        <img class="img-fluid" src="./img/ser-img1.jpg" alt="Information Technology Service" />
                                    </div>
                                </div>
                                <div className="row py-5">
                                    <div class="col-md-12 mb-30 wow fadeInUp reveal" data-wow-delay=".3s">
                                        <h1>Information Technology Management Service</h1>
                                        <p className="fs-5">
                                            Information technology management is the basic function of management, which operates
                                            with staffing, organizing, control, and budgeting. It also works as a function specifically
                                            to the IT industry such as software development, network planning, tech support,
                                            and change management. Individuals to support and complement their business operations
                                            generally use IT organizations.
                                        </p>
                                        <p className="fs-5">
                                            The focus of information technology management is the creation of a technology that wants
                                            business strategies. For an organization, creation of values involves a network of
                                            relationships between the external and internal environment. Technology performs
                                            an influential role in developing the overall value chain of an association.
                                        </p>
                                        <p className="fs-5">
                                            Choosing Information technology as a career is very demanding. Weather innovation in
                                            big data, cloud computing, and cybersecurity aspiring IT professionals have many
                                            branches to grow and push further. IT professionals never stop learning as it stays
                                            on the top of the latest technology trends to be successful in a particular field.
                                            So if you want to become an aspiring IT professional you must have committed to learning
                                            and adapting to new technology and once made to stay competitive. Therefore, if you
                                            want to be competitive and successful then you just come to the right place.
                                        </p>
                                        <p className="fs-5">
                                            We have provided you with the world’s best IT staff service and management programs that
                                            will be helpful to you when you work in an IT firm. We not only provide you with
                                            the best certification programs but also help you to magnify your skills in the related
                                            field.
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 pb-15 wow fadeInUp reveal" data-wow-delay=".3s">
                                        <h3>Why Should you as an Aspiring Candidate Select Us?</h3>
                                        <p className="fs-5">
                                            All our registered candidates are trained in IT staff service and management. We offer
                                            our candidates with IT staff management and services that are designed to meet the
                                            needs of current industries. You can take our BPO staff management and IT security
                                            staff services class to learn proper management database technology. We also teach
                                            our enrolled candidates the importance of the IT management framework and corporate
                                            strategy to enhance their skills. You can also choose from the numerous other training
                                            courses like BPO staff services and BPO staff management that teaches practical skills
                                            in IT-related project management. We also render our candidates a concept of IT management,
                                            software database, and other communication frameworks.
                                        </p>
                                        <p className="fs-5">We provide our candidate: </p>
                                        <ul className="text-li">
                                            <li>To learn from top instructors from the preeminent universities and companies </li>
                                            <li>To gain skills through hands-on learning in data science project management BPO staff
                                                services, IT security staff services, and more. </li>
                                            <li>Into trained candidates into qualified managers and business leaders.</li>
                                            <li>With 100% placement in domestic and foreign firms</li>
                                            <li>Not only do we provide professional courses to our equipped candidates but also help
                                                them to intensify their body language and proficiency in their conversation So
                                                that they can easily converse throughout the world without any barriers.
                                            </li>
                                        </ul>
                                        <p className="fs-5">
                                            Candidates who are willing to join our institution can easily contact us via our websites.
                                            Not only do they learn the technical skills for their future but also have the advantage
                                            of working in a world-class environment for IT professionals.
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 pb-15 wow fadeInUp reveal" data-wow-delay=".3s">
                                        <h3>What we provide to Our Clients</h3>
                                        <p className="fs-5">
                                            Whether you are a part of a large enterprise or a small business your network in IT management
                                            serves as a backbone to your entire business operations. It is also important as
                                            the well-being and Health of your business strategies for growth and success. That
                                            is why managed IT services are one of the most important factors for the development
                                            of any business.
                                        </p>
                                        <p className="fs-5">
                                            We offer you the world’s best candidates for your company. We are dealing with excellent
                                            trained candidates from our IT staff and BPO staff services. We are equipped with
                                            certified and trained candidates that are knowledgeable and experienced in their
                                            corresponding fields.
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 pb-15 wow fadeInUp reveal" data-wow-delay=".3s">
                                        <h3>Why do clients choose us? </h3>
                                        <ul className="text-li">
                                            <li>
                                                We are perfect for your business as we design A unique solution that accurately fits
                                                your business process and requirements
                                            </li>
                                            <li>
                                                We also provide you with dedicated assistance. We take care of our clients' technical
                                                and non-technical aspects of their business environment. With a 24/7 support
                                                center, you will receive a team of highly experienced certified candidates, subject
                                                matter experts, and specialists that will help you throughout your business ventures.
                                            </li>
                                            <li>
                                                IT staff service: Our IT staff service is the world’s best Staff service that provides
                                                our clients with trained candidates who can manage their work efficiently
                                            </li>
                                            <li>
                                                BPO staff services: Our BPO staff services are well trained to manage any organization
                                                and contact the external service provider to perform your essential business
                                                tasks without any difficulties. This process is necessary for any business to
                                                increase its value in the market. Our staff had a good understanding of this
                                                process within the organization and built a strong business process management.
                                            </li>
                                            <li>
                                                IT security staff services :Security Risk leaders are increasingly outsourcing their
                                                security program to manage Security Service providers to improve security impact
                                                and value. With our managed IT security staff service and trusted global partners,
                                                you are able to focus on strategic security priorities. As a provider, we manage
                                                day to day management data protection and ongoing compliance requirements.
                                            </li>
                                        </ul>
                                        <p className="fs-5">
                                            Finally, we look into managing your IT requirements while concentrating on managing business
                                            infrastructure. We are always focused on placing well-behaved and qualified staff
                                            into your sector. We promised you to serve you at our best with excellent facilities.
                                            If you want to contact our services, we are happy to give our services to you in
                                            the best possible way.
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h1>Service includes</h1>
                                        <ul class="nav widget-menu">
                                            <li class="nav-item border">
                                                <Link class="nav-link" to="#">Housekeeping Staff</Link>
                                            </li>
                                            <li class="nav-item border">
                                                <Link class="nav-link" to="#">Housekeeping Management</Link>
                                            </li>
                                            <li class="nav-item border">
                                                <Link class="nav-link" to="#">Facility Maintenance Staff</Link>
                                            </li>
                                            <li class="nav-item border">
                                                <Link class="nav-link" to="#">Building Support</Link>
                                            </li>
                                            <li class="nav-item border">
                                                <Link class="nav-link" to="#">Building Staff</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div className="row mb-5">
                                <div className="col">
                                    <AsideNavMenu />
                                </div>
                            </div>
                            {/* <!-- form --> */}
                            <div className="row">
                                <div className="col">
                                    <GetYourQuote />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ItManagementService;