import React from "react";
import "./BDO.css";
import GCTRightSideBar from "../gov-cunsulting-transaction-advisery/GCTRightSideBar";

function BDO() {
  return (
    <>
      {/* ///////////////////////////////////// */}
      <div className="container-fluid imghere position-relative">
        <div
          className="position-absolute"
          style={{ top: "50%", transform: "translateY(-50%)", left: "8%" }}
        >
          <h4 className="text-white">Consulting Services</h4>
          <p className="text-white my-4 pe-5 p-md-0">
            Helping institutions realise their goals through our expert
            consulting
          </p>
          <div className="btn btn-primary border-0 px-4 py-3 fw-bold rounded-0 w-50">
            Learn More
          </div>
        </div>
      </div>

      {/* /////////////////////////////////////// */}

      <div className="container-fluid text-dark mx-auto ">
        <div className="row my-5">
          <div className="col-1"></div>
          <div className="col-lg-6">
            <h1 className="fw-normal">How iClimbs India Can Help</h1>
            <p>
              At iClimbs India, our team of advisory experts assist and guide
              organisations on:
            </p>
            <ul className="p-0 m-0 HowBDO">
              <li>
                e-Governance Consultancy (BPR, DPR, RFP, Bid Process,
                Transaction Advisory)
              </li>
              <li>Digital/ IT Strategy & Transformation</li>
              <li>Program & Project Management</li>
              <li>Smart City Solutions Consulting</li>
              <li>Process Design, Automation and Transformation</li>
              <li>
                Impact Assessment, Feasibility Study, Public Private Partnership
              </li>
              <li>
                Policy Advisory & SOP Preparation Services – CSR Policy,
                Procurement Policy
              </li>
            </ul>
          </div>
          <GCTRightSideBar />
        </div>
      </div>

      {/* ///////Insight////////// */}

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-5 mx-auto">
            <img
              className="w-100"
              src="./images/Insights.jpg" alt="" />
          </div>
          <div className="col-lg-6 mx-2 my-5 m-auto ms-auto">
            <h1 className="text-dark">Related Insights</h1>
            <p className="text-dark my-4">
              Explore our recent thought leadership for updates and expert views
            </p>
            <div className="btn btn-lg btn-primary fs-6 rounded-0">
              Learn More
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BDO;
