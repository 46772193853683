const PayRollManagement = () => {
    return (
        <>
            {/* <!-- /////////Banner start here/////// --> */}
            <div class="container-fluid me-0">
                <div class="row">
                    <div class="col-md-12 p-0">
                        <img src="./images/Payrollmanagement-service-banner.png" class="d-block w-100 img-fluid"
                            style={{ width: "100%", aspectRatio: 16 / 4, objectFit: "cover" }} alt="Payroll management Services" />
                    </div>
                </div>
            </div>
            {/* <!-- /////////// Banner End here //////// --> */}
            {/* <!-- main section start --> */}
            <div class="container pt-5 pb-5 bg-white">
                <div class="row pt-5 pb-5">
                    <div class="col-lg-6">
                        <h2 class="text-primary">Payroll Management</h2>
                        <p class="fs-5 text-dark">
                            The Payroll Management system is basically used to build an
                            application program, that a company uses to manage the records of
                            the employees working in the Company. We have been serving several
                            leading clients across various sectors in payroll processing and
                            flexibility in managing complex payrolls for our clients. Our
                            support team brings in knowledge from multiple competencies,
                            including human resources, compliance, statutory, legal services,
                            and taxation to address critical issues. We have standard
                            operating procedures to deliver high-quality, value-added
                            services. and information. Our Services Caters- BFSI,FMCG, Pharma,
                            Hospitality, Retails, Manufacturing, Logistics, IT &ITES, FMCD,
                            E-Commerce.
                        </p>
                    </div>
                    <div class="col-lg-6 overflow-hidden">
                        <h2 class="text-primary">Our Services in Payroll Management</h2>
                        <img src="images/payrollEdited.jpg" class="img-fluid hover" alt="" />
                    </div>
                </div>
            </div>
            <div class="container-fluid bg-primary px-0">
                <div class="container pb-5">
                    <div class="row py-5">
                        <div class="col-md-12">
                            <h1 class="text-white text-center">The Importance Of Scalability</h1>
                            <h3 class="text-white text-center mt-3">The Lack Of Scalability Is The Topmost Reason Why Businesses Fail
                            </h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 overflow-hidden" data-aos="fade-in" data-aos-duration="1000" data-aos-delay="500">
                            <img src="./images/scalability.jpg" alt="The Importance Of Scalability" class="w-100 hover" />
                        </div>
                        <div class="col-md-6" data-aos="fade-in" data-aos-duration="1000" data-aos-delay="500">
                            <p class="text-white fs-5">
                                It is, in fact, the lack of scalability which is the topmost reason why businesses fail and a collective
                                approach to these seven factors that can help entrepreneurs spur sustainable growth in their emerging
                                businesses.
                                Scalability is an exciting and challenging journey which calls for patience, wisdom, and commitment. It is
                                not a theatrical display of one’s financial muscle or ability to make acquisitions. I encourage all
                                entrepreneurs to make their journey worthwhile.
                                The first step here is to redefine failure and view it as learning.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="container">
                <div class="row py-5">
                    <div class="col-md-12">
                        <h1 class="text-center text-primary">Two-Tier Architectur</h1>
                        <h3 class="text-center mt-3 text-primary">Streamlining Efficiency, Elevating Performance: Two-Tier
                            Architecture.</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6" data-aos="fade-in" data-aos-duration="1000" data-aos-delay="500">
                        <h3 class="text-primary">Exploring the Efficiency and Simplicity of Two-Tier Architecture</h3>
                        <p class="fs-5 text-dark">
                            In the realm of software and database management, architectures play a pivotal role in determining system
                            performance, scalability, and maintenance. One such architecture that has stood the test of time for its
                            simplicity and efficiency is the Two-Tier Architecture.
                        </p>
                        <h3 class="text-primary">What is Two-Tier Architecture</h3>
                        <p class="fs-5 text-dark">
                            Two-Tier Architecture, also known as Client-Server Architecture, is a software architecture model that
                            divides an application into two logical tiers or layers: the client tier and the server tier. This
                            architectural style is characterized by its straightforward structure, consisting of a client-side interface
                            for user interaction and a server-side component responsible for data storage and processing.
                        </p>
                    </div>
                    <div class="col-md-6 overflow-hidden" data-aos="fade-in" data-aos-duration="1000" data-aos-delay="500">
                        <img src="./images/2-Teir-and-3-Tier.webp" class="w-100 hover" alt=""
                            style={{ width: "100%", aspectRatio: 19 / 16, objectFit: "cover" }} />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
                        <h3 class="text-primary">1. Client Tier:</h3>
                        <p class="fs-5 text-dark">
                            At the forefront of the architecture lies the client tier, which encompasses the user interface and
                            application logic. The client tier is typically deployed on end-user devices such as desktop computers,
                            laptops, or mobile devices. It is responsible for presenting information to users in a user-friendly manner
                            and capturing user inputs. In a web-based scenario, the client tier often includes web browsers or mobile
                            applications that interact with the server tier via HTTP requests.
                        </p>
                    </div>
                    <div class="col-md-6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
                        <h3 class="text-primary">2. Server Tier:</h3>
                        <p class="fs-5 text-dark">
                            The server tier constitutes the backend of the application, responsible for data storage, retrieval, and
                            processing. It hosts a database management system (DBMS) where data is stored and managed. The server tier
                            processes client requests, executes business logic, and retrieves data from the database. This tier is often
                            deployed on a centralized server or a cluster of servers to handle concurrent client requests efficiently.
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <h3 class="text-primary">What makes iClimbs different</h3>
                        <p class="fs-5 text-dark">
                            iClimbs provides, Two-Tier Architecture offers a pragmatic approach to designing software systems,
                            emphasizing simplicity, performance, and scalability. While it may not be suitable for every use case, its
                            inherent strengths make it a compelling choice for many applications, particularly those with moderate user
                            loads and straightforward data processing requirements.
                        </p>
                    </div>
                </div>
            </div>

            {/* <!-- main section end  --> */}

        </>
    )
}
export default PayRollManagement;
