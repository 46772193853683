const GetYourQuote = () => {
    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your form submission logic here
    };

    return (
        <div className="mb-4 p-3 text-dark" style={{ boxShadow: "2px 3px 2px 2px gray", marginBottom: "100px" }}>
            <h3>Get Your Free Quote!</h3>
            <form onSubmit={handleSubmit}>
                <div className="form-group my-3">
                    <input type="text" name="name" className="form-control" placeholder="Name" required />
                </div>
                <div className="form-group">
                    <input type="email" name="email" className="form-control" placeholder="Email Address" required />
                </div>
                <div className="form-group my-3">
                    <input type="text" name="phone" className="form-control" placeholder="Phone Number" required />
                </div>
                <div className="form-group my-3">
                    <select name="service" className="form-control" required>
                        <option>Para Medical Staff Services</option>
                        <option>Infrastructure and State Management</option>
                        <option>IT Management Services</option>
                        <option>Facility Management</option>
                    </select>
                </div>
                <div className="form-group">
                    <textarea placeholder="Message" name="msg" className="form-control"></textarea>
                </div>
                <div className="form-group my-3">
                    <input type="submit" name="home-submit" value="Send Enquiry" className="btn btn-primary" />
                </div>
            </form>
        </div>
    )
}
export default GetYourQuote;