/* globals jQuery, $ */
import { useEffect } from "react";
const OurClients = () => {
    useEffect(() => {
        ////////////////////// our client /////////////////////
        $('.owl-carousel.client').owlCarousel({
            loop: true,
            margin:30,
            nav: false,
            dots: true,
            autoplay: true,
            autoplayTimeout: 2000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    nav: false,
                    items: 2
                },
                576: {
                    nav: false,
                    items: 4
                },
            }
        });
        // Pause on mouse enter
        var clientCarousel = jQuery('.owl-carousel.client');
        clientCarousel.mouseenter(function () {
            clientCarousel.trigger('stop.owl.autoplay');
        });

        // Resume on mouse leave
        clientCarousel.mouseleave(function () {
            clientCarousel.trigger('play.owl.autoplay');
        });
        //////////////////////our client end /////////////////


    }, [])
    return (
        // <!-- Clients slider start -->
        <div class="container pb-4 my-3">
            <div class="row mb-2 py-5">
                <div class="col-md-12">
                    <h1 class="text-center text-white"> Our Clients</h1>
                </div>
            </div>
            <div class="row">
                <div className="col-md-2"></div>
                <div className="col-md-8 text-center">
                    <div class="owl-carousel client">
                        <div class="item">
                            <img src="./img/ourclient/1.png" alt=" img 1" />
                        </div>
                        <div class="item">
                            <img src="./img/ourclient/2.jpg" alt=" img 2" />
                        </div>
                        <div class="item">
                            <img src="./img/ourclient/3.png" alt=" img 3" />
                        </div>
                        <div class="item">
                            <img src="./img/ourclient/govt-seal.jpg" alt="Goverment of india ministy" />
                        </div>
                        <div class="item">
                            <img src="./img/ourclient/smrt-city.jpg" alt="smart city" />
                        </div>
                        <div class="item">
                            <img src="./img/ourclient/10.png" alt="client 10" />
                        </div>
                        <div class="item">
                            <img src="./img/ourclient/11.jpg" alt="client 11" />
                        </div>
                        <div class="item">
                            <img src="./img/ourclient/12.png" alt="client 12" />
                        </div>
                        <div class="item">
                            <img src="./img/ourclient/13.png" alt="client 13" />
                        </div>
                        <div class="item">
                            <img src="./img/ourclient/npci.jpg" alt="NPCI" />
                        </div>
                        <div class="item">
                            <img src="./img/ourclient/smart-city-1.jpg" alt="Smart city" />
                        </div>
                        <div class="item">
                            <img src="./img/ourclient/16.png" alt="client 16" />
                        </div>
                    </div>
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
        //   {/* <!-- our clients sldier end//// --> */ }
    )
}
export default OurClients;