/* globals $*/
import React from 'react'
import { useEffect } from 'react'

export const Sevices = () => {
    useEffect(() => {
        $(".owl-carousel.service").owlCarousel({
            autoplay: false,
            smartSpeed: 1000,
            center: false,
            dots: false,
            loop: false,
            margin: 50,
            nav: true,
            navText: [
                '<i class="bi bi-arrow-left"></i>',
                '<i class="bi bi-arrow-right"></i>'
            ],
            responsiveClass: true,
            responsive: {
                0: {
                    autoplay: true,
                    loop: true,
                    items: 2
                },
                768: {
                    items: 3
                },
                992: {
                    items: 4
                },
                1200: {
                    items: 7
                }
            }
        });

    })
    return (
        // <!-- service start -->
        <div className="container text-center">
            <h1 className='text-center text-primary my-5'>Our Services</h1>
            <div className="row my-lg-5 ">
                <div className="contentbox text-center py-4 px-2 my-3 my-lg-0 col-lg mx-lg-2 d-flex shadow-lg rounded justify-content-evenly align-items-center">
                    <div className='' style={{ width: '20%' }}>
                        <img src="./images/services/information.png" alt="information" style={{ width: '100%' }} /></div>
                    <div className='text-center font-style text-dark hover'>
                        <h6>Information <br /> Communication <br /> Technology</h6>
                    </div>
                </div>
                <div className=" contentbox text-center py-4 px-2 my-3 my-lg-0 col-lg mx-lg-2 d-flex shadow-lg rounded justify-content-evenly align-items-center">
                    <div className='' style={{ width: '20%' }}>
                        <img src="./images/services/human-capital.png" alt="" style={{ width: '100%' }} /></div>
                    <div className='text-center font-style text-dark'>
                        <h6>Human<br /> Capital <br /> Management</h6>
                    </div>
                </div>
                <div className="contentbox text-center py-4 px-2 my-3 my-lg-0 col-lg mx-lg-2 d-flex shadow-lg rounded justify-content-evenly align-items-center">
                    <div className='' style={{ width: '20%' }}>
                        <img src="./images/services/consulting.png" alt="consulting" style={{ width: '100%' }} /></div>
                    <div className='text-center font-style text-dark'>
                        <h6>Consulting<br /> & <br /> Advisory</h6>
                    </div>
                </div>
                <div className=" contentbox py-4 my-3 my-lg-0 px-2 col-lg mx-lg-2 d-flex shadow-lg rounded justify-content-evenly align-items-center">
                    <div className='' style={{ width: '20%' }}>
                        <img src="./images/services/cybersecurity.png" alt="" style={{ width: '100%' }} /></div>
                    <div className='text-center font-style text-dark'>
                        <h6>Cyber <br /> Security </h6>
                    </div>
                </div>
            </div>
            {/* /////////////////////////////// */}
            <div className="row my-lg-5" >
                
                <div className="contentbox py-4 my-3 my-lg-0 px-2 col-lg  mx-lg-2 d-flex shadow-lg rounded justify-content-evenly align-items-center">
                    <div className='' style={{ width: '30%' }}>
                        <img src="./images/services/machine.png" alt="" style={{ width: '100%' }} /></div>
                    <div className='text-center font-style text-dark'>
                        <h6>AI-Machine<br />Learning <br/>and DL</h6>
                    </div>
                </div>
                <div className="contentbox py-4 my-3 my-lg-0 px-2 col-lg  mx-lg-2 d-flex shadow-lg rounded justify-content-evenly align-items-center">
                    <div className='' style={{ width: '30%' }}>
                        <img src="./images/services/paramedical.png" alt="" style={{ width: '100%' }} /></div>
                    <div className='text-center font-style text-dark'>
                        <h6>Paramedical<br /> Healthcare </h6>
                    </div>
                </div>
                 <div className="contentbox py-4 px-2 my-3 my-lg-0 col-lg mx-lg-2 d-flex shadow-lg rounded justify-content-evenly align-items-center">
                    <div className='' style={{ width: '30%' }}>
                        <img src="./images/services/reseach.png" alt="Research" style={{ width: '100%' }} /></div>
                    <div className='text-center font-style text-dark'>
                    <h6>Research <br /> & <br /> Development</h6></div>
                </div>
                <div className="contentbox py-4 my-3 my-lg-0 px-2 col-lg  mx-lg-2 d-flex shadow-lg rounded justify-content-evenly align-items-center">
                    <div className='' style={{ width: '30%' }}>
                        <img src="./images/services/marketing.png" alt="" style={{ width: '100%' }} /></div>
                    <div className='text-center font-style text-dark'>
                        <h6>Marketing</h6>
                    </div>
                </div>
            </div>
        </div>
        //   {/* <!-- services End  --> */ }
    )
}


