import React, { useState } from "react";
import './infrastructure-state.css'
import GetYourQuote from "../include/get-your-qutes";
import AsideNavMenu from "../include/aside-nav-menu";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const ParamedicalStaffing = () => {
      const [activePanel, setActivePanel] = useState(null);

      const handleToggle = (panel) => {
            setActivePanel(activePanel === panel ? null : panel);
      };
      return (
            <>
                  <div class="ttm-page-title-row">
                        <div class="container">
                              <div class="row">
                                    <div class="col-md-12">
                                          <div class="title-box ttm-textcolor-white" id="fixedHeader">
                                                <div class="page-title-heading">
                                                      <h1 class="title text-white"> Para Medical Staffing Service</h1>
                                                </div>
                                                <div class="breadcrumb-wrapper text-white">
                                                      <span>
                                                            <Link title="Homepage" to="/home">
                                                                  <i class="fa-solid fa-house"></i>&nbsp;&nbsp;Home
                                                            </Link>
                                                      </span>
                                                      <span class="ttm-bread-sep">&nbsp; | &nbsp;</span>
                                                      <span class="text-white"> Para Medical Staffing </span>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
                  <div className="container-fluid">
                        <div className="container">
                              <div className="row mt-5">
                                    <div className="col-lg-9">
                                          <div className="container-fluid text-dark">
                                                <div className="row">
                                                      <div className="col-md-12">
                                                            <img className="img-fluid" src="./img/ser-img4.jpg" alt="HealthCare Paramedical Staffing Services" />
                                                      </div>
                                                </div>
                                                <div className="row py-5">
                                                      <div className="col-md-12 mb-30 wow fadeInUp reveal" data-wow-delay=".3s">
                                                            <h1>Para Medical Staffing Service</h1>
                                                            <p className="fs-5">
                                                                  Paramedical staffs are the staffs that work under a doctor and provide
                                                                  medical services to the patients. The category includes nurses,
                                                                  emergency medical technicians, and doctor’s assistance. If you
                                                                  have chosen paramedical staff services as your career, then you
                                                                  have just come to the right place. We have provided the world’s
                                                                  best service for our candidates registered under paramedical
                                                                  and health-related services. This service is one of the most
                                                                  exciting and challenging but satisfying careers.
                                                            </p>
                                                            <p className="fs-5">
                                                                  We provide training through our skill development program to enhance
                                                                  the skills of our candidates and place them globally as per their
                                                                  aspiration and academic qualification both overseas and domestic.
                                                            </p>
                                                            <p className="fs-5">
                                                                  We have total staffing solution enterprises with the presence of pan
                                                                  India leader in the service industry and provide a comprehensive
                                                                  range of services to the entire medical organization.
                                                            </p>
                                                      </div>
                                                </div>
                                                <div className="row">
                                                      <div className="col-md-12 pb-15 wow fadeInUp reveal" data-wow-delay=".3s">
                                                            <h3>What do we generally provide two of our registered candidates?</h3>
                                                            <p className="fs-5">
                                                                  We provide a service in commercial offices, malls, residential, and hotels. The candidates
                                                                  who want to be professionally unique can approach our institution, which will help
                                                                  you to be the best of its kind. All of our registered candidates were trained so
                                                                  that they provide the best professional support for any institution where they are
                                                                  hired. They are prepared to the extent that if something happens during any working
                                                                  environment, they will try to overcome the problem with the presence of mind.
                                                            </p>
                                                            <p className="fs-5"><strong>We provide to our candidates:</strong> </p>
                                                            <ul className="text-li">
                                                                  <li>
                                                                        Excellent facility with improvement in professional skills related to their sectors.
                                                                  </li>
                                                                  <li>
                                                                        Language proficiency training: it is very important to communicate with the pupil
                                                                        when you are working in staff service management. Therefore, a proper proficiency
                                                                        in speaking is essential. It is required to communicate with your clients or
                                                                        your customers when working in malls and hotels. This language proficiency training
                                                                        will also help our candidate to get foreign countries to the job also.
                                                                  </li>
                                                                  <li>
                                                                        Placement: We also provide 100% placement to our successful candidates.
                                                                  </li>
                                                                  <li>
                                                                        Karv certification: Our candidates have been awarded a certificate on particular
                                                                        services for which they have been trained by our institution. You can easily
                                                                        use that certificate for your future job prosperity.
                                                                  </li>
                                                            </ul>
                                                      </div>
                                                </div>
                                                <div className="row accordian">
                                                      <div className="col-md-12 pb-15 wow fadeInUp reveal" data-wow-delay=".3s">
                                                            <div className={`card ${activePanel === 1 ? 'active' : ''}`}>
                                                                  <div className="wow fadeInUp reveal" data-wow-delay=".3s">
                                                                        <div className="toggle-title d-flex align-items-center p-3" onClick={() => handleToggle(1)}>
                                                                              <h5 data-toggle="collapse" className="my-auto" data-parent="#accordion" href="#collapseOne"
                                                                              >
                                                                                    What we generally provide to our registered candidates:&nbsp;&nbsp;
                                                                              </h5>
                                                                              <FontAwesomeIcon className="h4 my-auto ms-auto" icon={activePanel === 1 ? faChevronUp : faChevronDown} />
                                                                        </div>
                                                                        <div id="collapseOne" className={`toggle-content collapse ${activePanel === 1 ? 'show' : ''}`}>
                                                                              <p className="fs-5">
                                                                                    All our registered candidates are trained in a way that
                                                                                    they can provide emergency care and professional
                                                                                    support. They are prepared for a task of the rapid
                                                                                    medical response to emergencies like road traffic
                                                                                    accidents or accidents at home or even a major incident
                                                                                    such as train or airplane crashes. All candidates
                                                                                    are trained to work under onsite assessment of patients
                                                                                    and perform immediate action if necessary.
                                                                              </p>
                                                                              <p className="fs-5">
                                                                                    We trained our lab technicians' staff service in such a
                                                                                    way that they have full knowledge of all types of
                                                                                    instruments and machines available in a laboratory
                                                                                    or a lab. Not only do we provide technical services
                                                                                    to our candidates we also ensure that their behavior
                                                                                    towards the patient or any professional team is fairly
                                                                                    good. Not only a good skill can help a candidate
                                                                                    to achieve success in the future, but a well-behaved
                                                                                    manner is also important. Therefore, we provide language
                                                                                    proficiency training and conversational skills to
                                                                                    our candidates. So that they can converse easily
                                                                                    or can understand the problem of patience. Our lab
                                                                                    technician staff service provides you with the most
                                                                                    accurate result of your blood and urine samples.
                                                                              </p>
                                                                              <ul className="text-li">
                                                                                    <li>World-class improvement of technical skills</li>
                                                                                    <li>
                                                                                          Language proficiency training for our medical Staff:
                                                                                          It is a very important aspect of the job. Language
                                                                                          proficiency is very important, as you have
                                                                                          to interact with other team members or any
                                                                                          doctor. As our candidates are from different
                                                                                          regional backgrounds, therefore, a common language
                                                                                          is to be used in proficiency to interact with
                                                                                          other members of our institution. Language
                                                                                          proficiency is also important during a placement
                                                                                          in domestic as well as in foreign countries.
                                                                                    </li>
                                                                                    <li>100% employment opportunities</li>
                                                                                    <li>Placement in both national and international</li>
                                                                                    <li>Immigration and travel support</li>
                                                                                    <li>
                                                                                          Placement based on educational qualification and aspiration
                                                                                    </li>
                                                                                    <li>
                                                                                          Karve certification: All our candidates have been
                                                                                          provided with a certification after completing
                                                                                          paramedical staff service from our institution.
                                                                                          You can easily use this certificate for applying
                                                                                          for jobs. This certificate will enhance your
                                                                                          biodata Ann helps you in finding a job without
                                                                                          any difficulty.
                                                                                    </li>
                                                                              </ul>
                                                                              <p className="fs-5">
                                                                                    Not only have we provided paramedical staff service but
                                                                                    also lab technician staff services. The candidates
                                                                                    who want to join our institution have the advantage
                                                                                    of working with the world's best service.
                                                                              </p>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                                <div className="row accordian">
                                                      <div className="col-md-12 my-2 pb-15 wow fadeInUp reveal" data-wow-delay=".3s">
                                                            <div className={`card ${activePanel === 2 ? 'active' : ''}`}>
                                                                  <div className="wow fadeInUp reveal" data-wow-delay=".3s">
                                                                        <div className="d-flex p-3 align-items-center toggle-title" onClick={() => handleToggle(2)}>
                                                                              <h5 data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" className="my-auto"
                                                                              >
                                                                                    What we offer to our clients:&nbsp;&nbsp;
                                                                              </h5>
                                                                              <FontAwesomeIcon className="h4 ms-auto my-auto" icon={activePanel === 1 ? faChevronUp : faChevronDown} />
                                                                        </div>
                                                                        <div id="collapseTwo" className={`toggle-content collapse ${activePanel === 2 ? 'show' : ''}`}>
                                                                              <p className="fs-5">
                                                                                    The clients who are interested to have the best services
                                                                                    to their medical institution then they can up for
                                                                                    our services. All our paramedical lab technicians
                                                                                    and laboratories staff services were equipped with
                                                                                    the best candidates for their institution. Our institution
                                                                                    is equipped with the world's best paramedical Staff.
                                                                                    All our staffs are well behaved and have good technical
                                                                                    skills. All our staff can take control in a situation
                                                                                    where it is important to work in emergency care.
                                                                                    Sometimes paramedical Staff must have direct recovery
                                                                                    efforts in a team that is made up during any emergency
                                                                                    services. All our candidates have and quality of
                                                                                    leadership that is needed to control any situation
                                                                                    and to ensure that the resources that are allocated
                                                                                    when needed can be used without any problems.
                                                                              </p>
                                                                              <p className="fs-5">
                                                                                    As we know that only technical skill is not enough for
                                                                                    staff to be appointed by you. It is also important
                                                                                    for paramedical staff to be good team players and
                                                                                    trustworthy. Our candidates have these qualities
                                                                                    so that they can easily work within an environment,
                                                                                    which are mainly not safe for them. To work in a
                                                                                    team, it is very important when you are appointed
                                                                                    in a place well you need the help of other technicians
                                                                                    all any emergency doctors. Therefore, it is very
                                                                                    important to trust each other and work well together
                                                                                    within a situation where a team may be the first
                                                                                    to rely largely possible for their well-being on
                                                                                    their team members.
                                                                              </p>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                                <div className="row accordian mb-3">
                                                      <div className="col-md-12">
                                                            <div className={`card ${activePanel === 3 ? 'active' : ''}`}>
                                                                  <div className="wow fadeInUp reveal" data-wow-delay=".3s">
                                                                        <div className="toggle-title d-flex align-items-center p-3" onClick={() => handleToggle(3)}>
                                                                              <h5 data-toggle="collapse" data-parent="#accordion" className="my-auto" href="#collapseThree" >
                                                                                    We provide :&nbsp;&nbsp;
                                                                              </h5>
                                                                              <FontAwesomeIcon className="h4 my-auto ms-auto" icon={activePanel === 1 ? faChevronUp : faChevronDown} />
                                                                        </div>
                                                                        <div id="collapseThree" className={`toggle-content collapse ${activePanel === 3 ? 'show' : ''}`}>
                                                                              <ul className="text-li">
                                                                                    <li>
                                                                                          Medical professionals for both long and short-term
                                                                                          positions: You can appoint our candidates based
                                                                                          on your need. This may be permanent is on a
                                                                                          contract basis. All our paramedical staff is
                                                                                          well behaved and highly qualified that will
                                                                                          suit your institution.
                                                                                    </li>
                                                                                    <li>
                                                                                          A full bio-data is shared with the company if a candidate
                                                                                          is chosen from our institution. Although this
                                                                                          information is only provided when they were
                                                                                          finally selected for the position, they have
                                                                                          applied for in any institution.
                                                                                    </li>
                                                                                    <li>
                                                                                          We also arrange Skype interviews for our candidates
                                                                                          and clients for which they interact with each
                                                                                          other. It will help our planes to get an overview
                                                                                          of our candidates before appointing them to
                                                                                          their institution.
                                                                                    </li>
                                                                                    <li>
                                                                                          Finally, yet importantly, we have extended our arms
                                                                                          to provide you with the best medical staff
                                                                                          for your institution. We ensure that all our
                                                                                          paramedical Staff will be the best of their
                                                                                          kind and give you full support in any emergency.
                                                                                    </li>
                                                                              </ul>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    <div className="col-lg-3">
                                          <div className="row mb-5">
                                                <div className="col">
                                                      <AsideNavMenu />
                                                </div>
                                          </div>
                                          {/* <!-- form --> */}
                                          <div className="row">
                                                <div className="col">
                                                      <GetYourQuote />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div >
                  </div >
            </>

      )
}
export default ParamedicalStaffing;