import React from "react";
import './infrastructure-state.css'
import GetYourQuote from "../include/get-your-qutes";
import AsideNavMenu from "../include/aside-nav-menu";
import { Link } from "react-router-dom";
const InfrastructureStateManagementServices = () => {
    return (
        <>
            <div className="ttm-page-title-row">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-box ttm-textcolor-white">
                                <div className="page-title-heading">
                                    <h1 className="title text-white"> Infrastructure and State Management Service</h1>
                                </div>
                                <div className="breadcrumb-wrapper text-white">
                                    <span>
                                        <Link title="Homepage" to="/home">
                                            <i className="fa-solid fa-house"></i>&nbsp;&nbsp;Home
                                        </Link>
                                    </span>
                                    <span className="ttm-bread-sep">&nbsp; | &nbsp;</span>
                                    <span> Infrastructure and State Management </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-lg-9">
                            <div className="container-fluid text-dark">
                                <div className="row">
                                    <div className="col-md-12">
                                        <img className="img-fluid" src="./img/ser-img3.jpg" alt="Infrastructure and State Management Service" />
                                    </div>
                                </div>
                                <div className="row py-5">
                                    <div class="col-md-12 mb-30 wow fadeInUp reveal" data-wow-delay=".3s">
                                        <h1>Infrastructure and State Management</h1>
                                        <p className="fs-5">
                                            Infrastructure is a type of foundation that connects everything manufacturing, Supply
                                            chain, clients, communities, and employees. With the modern digital workplace infrastructure
                                            and state management, it is very important to make the employees happier and more
                                            productive. We empower our candidates with our teamwork and the right process, governance,
                                            and automation infrastructure. We also help them to enhance their capabilities and
                                            may build a solid foundation that will help them in the near future.
                                        </p>
                                        <p className="fs-5">
                                            We also guarantee our candidates who are admitted to our institution access to everything
                                            they need to become updated in the world of digitalization and client satisfaction.
                                        </p>
                                        <p className="fs-5">
                                            We provide our qualified candidates with a technical skill improvement program that will
                                            enhance their abilities in a particular place so that they can be easily placed globally
                                            according to their performance and aspirations.
                                        </p>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 pb-15 wow fadeInUp reveal" data-wow-delay=".3s">
                                        <h3>What do we generally provide two of our registered candidates?</h3>
                                        <p className="fs-5">
                                            We provide a service in commercial offices, malls, residential, and hotels. The candidates
                                            who want to be professionally unique can approach our institution, which will help
                                            you to be the best of its kind. All of our registered candidates were trained so
                                            that they provide the best professional support for any institution where they are
                                            hired. They are prepared to the extent that if something happens during any working
                                            environment, they will try to overcome the problem with the presence of mind.
                                        </p>
                                        <p className="fs-5"><strong>We provide to our candidates:</strong> </p>
                                        <ul className="text-li">
                                            <li>
                                                Excellent facility with improvement in professional skills related to their sectors.
                                            </li>
                                            <li>
                                                Language proficiency training: it is very important to communicate with the pupil
                                                when you are working in staff service management. Therefore, a proper proficiency
                                                in speaking is essential. It is required to communicate with your clients or
                                                your customers when working in malls and hotels. This language proficiency training
                                                will also help our candidate to get foreign countries to the job also.
                                            </li>
                                            <li>
                                                Placement: We also provide 100% placement to our successful candidates.
                                            </li>
                                            <li>
                                                Karv certification: Our candidates have been awarded a certificate on particular
                                                services for which they have been trained by our institution. You can easily
                                                use that certificate for your future job prosperity.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 pb-15 wow fadeInUp reveal" data-wow-delay=".3s">
                                        <h3>What  do we offer to our clients? </h3>
                                        <p className="fs-5">
                                            We offer you a smarter way to recruit commercial office staff service in your sector.
                                            Before we start looking for the clients, we always look for the business or services
                                            they want from our side. Only then do we begin with the shortlisting of our candidates
                                            that can fit your purpose. With this in mind, we build a trust relationship and honor
                                            with our clients. Whether you want our candidate to contact you by telephone or email
                                            or by face to face we are always happy to adapt your service according to your suit.
                                            We always assure you that our residential staff service will provide you the best
                                            service as we provide our candidates with the best possible knowledge in respect
                                            to the job they were hired for. Our hotel's staff services use the latest social
                                            networking technology alongside traditional recruitment tools to find the best candidate
                                            for your hotel service. We always think of a good working relationship with our clients
                                            and with our candidates to ensure that they were placed in a good working environment
                                            with all necessary facilities.
                                        </p>
                                        <p className="fs-5"><strong>We provide our clients with:</strong></p>
                                        <ul className="text-li">
                                            <li>
                                                <b>Commercial Office Staff Service</b> for both long and short-term positions. you
                                                can appoint our Staffs for both permanent as well as a temporary position. All
                                                our commercial office Staffs are highly qualified and well behaved which will
                                                suit your office.
                                            </li>
                                            <li>
                                                <b>Malls staff service</b> we provide you with the best mall staff services. All
                                                of our candidates are highly qualified in managing all the duties in malls. As
                                                they are highly professional and get a certified degree from our institution,
                                                you can rely on them fully.
                                            </li>
                                            <li>
                                                <b>Residential Stuff services</b> this type of service was mainly taken by the adult
                                                generation for their older parents. Residential staff was generally trained to
                                                handle older pupils, especially those who are suffering from a disability. Our
                                                residential staff service was trained in such a way that if any emergency takes
                                                place they can easily manage it.
                                            </li>
                                            <li>
                                                <b>Hotel staff services</b> This service includes housekeeping, waste management,
                                                landscaping, horticulture, and many more related to hotel service. We prepare
                                                our candidates according to the needs of our clients.
                                            </li>
                                        </ul>
                                        <p className="fs-5">
                                            We also arrange a video call interview for our clients and candidates so that they can
                                            interact with each other and know their responsibility in the sector.
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 pb-15 wow fadeInUp reveal" data-wow-delay=".3s">
                                        <p className="fs-5">
                                            Finally yet importantly, we take control of managing all our equipment needs while concentrating
                                            on managing the business as well as our infrastructure and state management services.
                                            We will always focus on placing well-behaved and quality staff into your sector.
                                            If you want to contact our services either permanently or temporarily, we are happy
                                            to discuss our assistance with you.
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h1>Service includes</h1>
                                        <ul className="nav widget-menu">
                                            <li className="nav-item border">
                                                <Link className="nav-link" to="#">Housekeeping Staff</Link>
                                            </li>
                                            <li className="nav-item border">
                                                <Link className="nav-link" to="#">Housekeeping Management</Link>
                                            </li>
                                            <li className="nav-item border">
                                                <Link className="nav-link" to="#">Facility Maintenance Staff</Link>
                                            </li>
                                            <li className="nav-item border">
                                                <Link className="nav-link" to="#">Building Support</Link>
                                            </li>
                                            <li className="nav-item border">
                                                <Link className="nav-link" to="#">Building Staff</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="row mb-5">
                                <div className="col">
                                    <AsideNavMenu />
                                </div>
                            </div>
                            {/* <!-- form --> */}
                            <div className="row">
                                <div className="col">
                                    <GetYourQuote />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default InfrastructureStateManagementServices;