const ReachUs = () => {
    return (
<div className="mb-4 p-3 bg-info text-dark" style={{boxShadow:"2px 3px 2px 2px gray", marginBottom:"100px"}}>
                            <h5 class="text-center">Reach Us</h5>
                            <form method="post" role="form">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group mb-2">
                                            <label for="form_name">First name
                                                <span class="text-danger">*</span>
                                            </label>
                                            <input id="form_name" type="text" name="name" class="form-control" required="" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group mb-2">
                                            <label for="form_lastname">Companyname
                                                <span class="text-danger">*</span>
                                            </label>
                                            <input id="form_lastname" type="text" name="companyName" class="form-control" required="" />
                                        </div>
                                    </div>
                                </div>
                              
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group mb-2">
                                            <label for="form_message">Message
                                                <span class="text-danger">*</span>
                                            </label>
                                            <textarea id="form_message" name="message" class="form-control" placeholder="Message" rows="4" required="required"></textarea>
                                        </div>
                                    </div>
                                    <select className="select-tag w-75 p-2 m-3 ">
                                    <option value="Aruba">Aruba</option>
                                <option value="Afghanistan">Afghanistan</option>
                                <option value="Angola">Angola</option>
                                <option value="Albania">Albania</option>
                                <option value="Andorra">Andorra</option>
                                <option value="United Arab Emirates">United Arab Emirates</option>
                                <option value="Argentina">Argentina</option>
                                <option value="Armenia">Armenia</option>
                                <option value="American Samoa">American Samoa</option>
                                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                <option value="Australia">Australia</option>
                                <option value="Austria">Austria</option>
                                <option value="Azerbaijan">Azerbaijan</option>
                                <option value="Burundi">Burundi</option>
                                <option value="Belgium">Belgium</option>
                                <option value="Benin">Benin</option>
                                <option value="Burkina Faso">Burkina Faso</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Bulgaria">Bulgaria</option>
                                <option value="Bahrain">Bahrain</option>
                                <option value="Bahamas">Bahamas</option>
                                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                <option value="Belarus">Belarus</option>
                                <option value="Belize">Belize</option>
                                <option value="Bermuda">Bermuda</option>
                                <option value="Bolivia, Plurinational State of">Bolivia, Plurinational State of
                                </option>
                                <option value="Lebanon">Lebanon</option>
                                <option value="Liberia">Liberia</option>
                                <option value="Libya">Libya</option>
                                <option value="Saint Lucia">Saint Lucia</option>
                                <option value="Liechtenstein">Liechtenstein</option>
                                <option value="Sri Lanka">Sri Lanka</option>
                                <option value="Lesotho">Lesotho</option>
                                <option value="Lithuania">Lithuania</option>
                                <option value="Luxembourg">Luxembourg</option>
                                <option value="Latvia">Latvia</option>
                                <option value="Morocco">Morocco</option>
                                <option value="Monaco">Monaco</option>
                                <option value="Moldova, Republic of">Moldova, Republic of</option>
                                <option value="Madagascar">Madagascar</option>
                                <option value="Maldives">Maldives</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Marshall Islands">Marshall Islands</option>
                            </select>
                            <div className="d-flex ">
                                <input type="checkbox" ></input>
                                <p className="p-1 mt-3">thank you for connecting us. we will contact u soon..</p>
                            </div>
                            
                                    <div class="col-md-12 mt-2">
                                        <input type="submit" class="btn btn-primary btn-send" value="Send message" />
                                    </div>
                                </div>
                            </form>
                            </div>
        // <form action="" method="get">
        //     <label for="">FastName</label>
        //     <br />
        //     <input type="text" class="w-100" id="name" placeholder="enter your name" />
        //     <label for="">Company Name</label>
        //     <br />
        //     <input type="text" class="w-100" id="c" placeholder="enter your Companyname" />
        //     <br />
        //     <label for="">Message</label>
        //     <br />
        //     <input type="text" id="m" class="w-100" />
        //     <label for="">Country</label>
        //     <br />
        //     <select name="" class="w-100">
        //         <option value="Aruba">Aruba</option>
        //         <option value="Afghanistan">Afghanistan</option>
        //         <option value="Angola">Angola</option>
        //         <option value="Albania">Albania</option>
        //         <option value="Andorra">Andorra</option>
        //         <option value="United Arab Emirates">United Arab Emirates</option>
        //         <option value="Argentina">Argentina</option>
        //         <option value="Armenia">Armenia</option>
        //         <option value="American Samoa">American Samoa</option>
        //         <option value="Antigua and Barbuda">Antigua and Barbuda</option>
        //         <option value="Australia">Australia</option>
        //         <option value="Austria">Austria</option>
        //         <option value="Azerbaijan">Azerbaijan</option>
        //         <option value="Burundi">Burundi</option>
        //         <option value="Belgium">Belgium</option>
        //         <option value="Benin">Benin</option>
        //         <option value="Burkina Faso">Burkina Faso</option>
        //         <option value="Bangladesh">Bangladesh</option>
        //         <option value="Bulgaria">Bulgaria</option>
        //         <option value="Bahrain">Bahrain</option>
        //         <option value="Bahamas">Bahamas</option>
        //         <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
        //         <option value="Belarus">Belarus</option>
        //         <option value="Belize">Belize</option>
        //         <option value="Bermuda">Bermuda</option>
        //         <option value="Bolivia, Plurinational State of">Bolivia, Plurinational State of
        //         </option>
        //         <option value="Lebanon">Lebanon</option>
        //         <option value="Liberia">Liberia</option>
        //         <option value="Libya">Libya</option>
        //         <option value="Saint Lucia">Saint Lucia</option>
        //         <option value="Liechtenstein">Liechtenstein</option>
        //         <option value="Sri Lanka">Sri Lanka</option>
        //         <option value="Lesotho">Lesotho</option>
        //         <option value="Lithuania">Lithuania</option>
        //         <option value="Luxembourg">Luxembourg</option>
        //         <option value="Latvia">Latvia</option>
        //         <option value="Morocco">Morocco</option>
        //         <option value="Monaco">Monaco</option>
        //         <option value="Moldova, Republic of">Moldova, Republic of</option>
        //         <option value="Madagascar">Madagascar</option>
        //         <option value="Maldives">Maldives</option>
        //         <option value="Mexico">Mexico</option>
        //         <option value="Marshall Islands">Marshall Islands</option>



        //     </select>
        //     <p class="text-dark">
        //         <input type="checkbox" id="box" /> I agree to receive marketing communication Travler</p>
        //     <button type="submit" id="Sumbit" class="text-center bg-primary text-white">Sumbit</button>

        // </form>

    )
}
export default ReachUs;