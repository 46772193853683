
import React from 'react';
import ReachUs from '../include/reachus';

import './traveler.css'; // Import your local stylesheet

const Traveler = () => {
    return (
        <>
            {/* <!----coursel sratrt--> */}
            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators" style={{ height: "300px" }}>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>

                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="./img/Travel.png" className="d-block w-100" alt="..." style={{ height: "400px" }} />
                    </div>
                    <div className="carousel-item">
                        <img src="./img/travel-2.png" className="d-block w-100" alt="..." style={{ height: "400px" }} />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            {/* <!---coursel end--> */}
            <div className="container-fluid">
                <div className="row">
                    <p className="h5 my-3 fs-4 text-dark">Home<span><i className="fa-solid fa-greater-than "></i></span> Industries <span><i className="fa-solid fa-greater-than"></i></span> Travel Transport and hostpality</p>
                    <div className="row mt-5">
                        <div className="col-md-12 fs-4 text-dark ml-2">
                            <h2 className="">Travel, Transport and Hospitality</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <p className="text-justify text-dark">Many industries are moving past the disruption brought about by the pandemic. The Travel, Transport
                            & Hospitality industries, perhaps the hardest hit, are rapidly adapting to the new demands. They
                            aim to offer better customer experience and safety, low contact interactions, meet changing regulations,
                            optimize resources, and improve resilience. Simultaneously, with growing competition from non-traditional
                            players, they are renewing their strategies to acquire new customers and retain old ones.</p>

                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <p className="text-justify text-dark">The solution is to build new business models and replace the patchwork of technology with end-to-end
                            digital systems. company is moving the needle for clients in the TTH space, helping them turn
                            “steady revival” to “fast-tracked progress.” Airlines, freight carriers, logistics companies,
                            cruise liners, hotels, and restaurant chains have successfully used our intelligent technology
                            to deliver seamless customer experiences, loyalty management, improve freight planning, create
                            resilient supply chains, and build dependable demand and capacity management systems.
                        </p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <p className="text-justify text-dark">Travler is exceptionally well-placed to bring about this change. It leverages decades of experience
                            and expertise in integrating industry-specific platforms with ERP, partner systems, virtual technologies,
                            automation, IoT, multi-cloud environments, and data and analytics to stitch cluttered and disjointed
                            applications and infrastructure into a unified digital system.
                        </p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <ReachUs />
                    </div>
                </div>
            </div>
            {/* <!----Industry segment start--> */}
            < div className="container-fluid" >
                <div className="row py-5">
                    <div className="Industries col-md-12 ">
                        {/* <!--first row--> */}
                        <div className="container">
                            <h1 className="text-center mb-5 text-dark fs-2">Industries Segment</h1>
                            <div className="row text-center">
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./images1/Logistics.jpg" alt="" className="w-25 border border-3 rounded-pill mt-3" />
                                    <h6 className="text-center text-dark mt-3">Logistics</h6>
                                    <p className="text-center text-dark">Shift focus, reset, and recover by increasing resiliency, flexibility, end-to-end visibility,
                                        and better value from analytics.</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./images1/freight.jpg" alt="" className="w-25 border border-3 rounded-pill mt-5" />
                                    <h6 className="text-center text-dark mt-3">Freight</h6>
                                    <p className="text-center text-dark">The road to a connected freight and fleet starts he</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./images1/hospital.jpg" alt="" className="w-25 border border-3 rounded-pill" />
                                    <h6 className="text-center text-dark mt-4">Hospitality</h6>
                                    <p className="text-center text-dark">Bring the customers back, prepare for business recovery with the right strategies for processes,
                                        technology, and people</p>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4 py-3">
                                    <img src="./img/travel3.jpg" alt="" className="w-25 border border-3 rounded-pill" />
                                    <h6 className="text-center text-dark mt-3">Travel</h6>
                                    <p className="text-center text-dark">Emerge stronger and smarter: Re-imagine your business strategies to herald a healthy, new, resilient
                                        travel industry.</p>
                                </div>
                                {/* <!--second row--> */}
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./img/realsate.jpg" alt="" className="w-25 border border-3 rounded-pill" />
                                    <h6 className="text-center text-dark mt-3">Real Estate
                                    </h6>
                                    <p className="text-center text-dark">Real estate enterprises need to predict and plan smart and leverage digital, data, and technology
                                        to survive and thrive.</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./img/CFS-icon.png" alt="" className="w-25 border border-3 rounded-pill" />
                                    <h6 className="text-center text-dark mt-3">Food and Bevarge</h6>
                                    <p className="text-center text-dark">Technology solutions to deliver connected experiences and seamless operations for the F&B industry
                                    </p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./img/rail.jpg" alt="" className="w-25 border border-3 rounded-pill mt-3" />
                                    <h6 className="text-center text-dark mt-3">Rail</h6>
                                    <p className="text-center text-dark">Driving digital transformation with better customer connections and communication leveraging
                                        innovative technology & industry 4.0 experience</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./img/products.jpg" alt="" className="w-25 border border-3 rounded-pill mt-3" />
                                    <h6 className="text-center text-dark mt-3">Airlines</h6>
                                    <p className="text-center text-dark">Seamless customer experience for the airline industry</p>
                                </div>

                                {/* <!----third row--> */}

                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./img/Products.jpg" alt="" className="w-25 border border-3 rounded-pill mt-3" />
                                    <h6 className="text-center text-dark mt-3">Transportation</h6>
                                    <p className="text-center text-dark">Scale the disruption with the power of digital transformation.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!----Industry segment end--> */}

            {/* <!--solution start--> */}
            <div className="container-fluid" >
                <div className="row">
                    <div className="col-md-12 bg-primary py-5">
                        <h1 className="text-center text-white">Solutions</h1>
                        <h5 className="text-center text-white">Our offerings combined with strategic partnerships deliver the best solutions for our clients. We believe
                            that our core proposition is to help Travel, Transport and Hospitality enterprises mitigate the impact
                            and maximize growth in the new normal and recover healthily.

                        </h5>
                        {/* ?<!---first row--> */}
                        <div className="container">
                            <div className="row text-center">
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./images1/iot.jpg" alt="" className="w-25 border border-3 rounded-pill mt-5" />
                                    <h5 className="text-center text-white mt-4">Internet of Things (IoT) Enabled Connected Operations</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./images1/block.jpg" alt="" className="w-25 border border-3 rounded-pill mt-5" />
                                    <h5 className="text-center text-white mt-4">Block-Chain Powered Loyalty Platform: Hospitality Industry</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./images1/colud.jpg" alt="" className="w-25 border border-3 rounded-pill mt-5" />
                                    <h5 className="text-center text-white mt-3">Cut Your Cloud Migration Time In Half To Accelerate Your Benefits</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./images1/indanservillance.jpg" alt="" className="w-25 border border-3 rounded-pill mt-5" />
                                    <h5 className="text-center text-white mt-4">Intelligent Video Surveillance</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./images1/mobile.jpg" alt="" className="w-25 border border-3 rounded-pill mt-5" />
                                    <h5 className="text-center text-white mt-3">Genie on the Go: Purchase, loyalty redemption, and delivery, on the go!</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./images1/marketing.jpg" alt="" className="w-25 border border-3 rounded-pill mt-5" />
                                    <h5 className="text-center text-white mt-3"> iClimb: NFC-based marketing solution for F&B</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./images1/passport.jpg" alt="" className="w-25 border border-3 rounded-pill mt-5" />
                                    <h5 className="text-center text-white mt-3">iclimb Digital Health Passport for Travel</h5>
                                </div>

                                {/* <!--seond row--> */}

                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./images1/coustomer.webp" alt="" className="w-25 border border-3 rounded-pill mt-5" />
                                    <h5 className="text-center text-white mt-3">Ancillary Merchandising: Consumer Personalization Solution</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./images1/travelimge.jpg" alt="" className="w-25 border border-3 rounded-pill mt-5 " />
                                    <h5 className="text-center text-white mt-4">ION: Contextual Search Solution For Travel Booking Websites</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./images1/serviceassistant.jpg" alt="" className="w-25 border border-3 rounded-pill mt-5" />
                                    <h5 className="text-center text-white mt-4">MIRA : iClimb Cognitive Service Assistant</h5>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./images1/memories.jpg" alt="" className="w-25 border border-3 rounded-pill mt-5" />
                                    <h5 className="text-center text-white mt-3">Crafting Memories For The Connected Traveler</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./images1/remote.jpg" alt="" className="w-25 border border-3 rounded-pill mt-5" />
                                    <h5 className="text-center text-white mt-4">iClimb ASSET+ for Monitoring and Management of Remote Assets</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--solution end--> */}
            {/* <!---platform sratrt--> */}
            <div className="container-fluid" >
                <div className="row ">
                    <div className="bg-secondary col-md-12 py-5 ">
                        <h3 className="text-center text-white ">Platforms</h3>
                        <p className="text-center text-white h5 mt-5">Our mission is to provide cutting-edge personalized experiences to customers through innovative platforms
                            by simplifying searching, sharing, and consumption of rich content via diverse devices and platforms.
                        </p>
                        <div className="container">
                            <div className="row text-center">
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./images1/Atm.jpg" alt="" className="w-25 border border-3 rounded-pill mt-5"/>
                                    <h5 className="text-white mt-5">CAPE – Composable Automated Platform for Enterprises</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./images1/data.jpg" alt="" className="w-25 border border-3 rounded-pill mt-5"/>
                                    <h5 className="text-white mt-5">Decision Moments – Data Analytics Platform</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <img src="./images1/cloud2.jpg" alt="" className="w-25 border border-3 rounded-pill mt-5"/>
                                    <h5 className="text-white mt-4">CloudXperienz</h5>
                                    <p className="text-white mt-3">Shift to predictive Zero Ops with a standard, operational, and business-contextualized data model</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!---platform end--> */}

            {/* <!---speak start--> */}
            <div className="container-fluid px-0  " >
                <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner bg-info " role="listbox" style={{ height: "400px" }}>
                        {/* <!---first row--> */}
                        <div className="container-fluid py-5">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="carousel-item active">
                                        <div className="d-flex  min-vh-100">
                                            <div className="embed-responsive embed-responsive-21by9">
                                                <video controls muted autoplay className="embed-responsive-item w-100 mt-3" src={process.env.PUBLIC_URL + '/video/traveler.mp4'}></video>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h5 className="text-center text-white mt-5">Client Speak: GENCORP, J.P. MORGAN</h5>
                                    <p className="text-center text-white h6">Leading through the pandemic and on finding a trustworthy partner in iClimb</p>
                                </div>
                            </div>
                        </div>

                        {/* <!---second row--> */}
                        <div className="carousel-item">
                            <div className="d-flex justify-content-center min-vh-100">
                                <div className="embed-responsive embed-responsive-21by9">
                                    <video muted autoplay className="embed-responsive-item" src="https://www.w3schools.com/howto/rain.mp4"></video>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon bg-dark rounded-circle p-2 shadow d-block" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon bg-dark rounded-circle p-2 shadow d-block" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
            </div>


            {/* <!---speak end--> */}
        </>
    )
}
export default Traveler;