/* global $, WOW */

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "../include/slider";
// import "./cloudinfo.css"
const CloudAndInrastrctureManagement = () => {
    useEffect(() => {
        ////////////////////// our client /////////////////////
        $('.owl-carousel.client').owlCarousel({
            loop: true,
            margin: 32,
            nav: false,
            dots: true,
            autoplay: true,
            autoplayTimeout: 10000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    nav: false,
                    items: 2
                },
                576: {
                    nav: false,
                    items: 3
                },
                768: {
                    nav: false,
                    items: 4
                },
                992: {
                    nav: false,
                    items: 6
                },
                1200: {
                    nav: false,
                    items: 8
                }

            }
        });

        //////////////////////our client end /////////////////

    }, [])
    return (<>
        <Slider />
        <div id="breadcrumb" className="container-fluid bg-light py-2">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item fs-6">
                                    <Link to="/home" title="iClimb" rel="home" className="text-primary">Home</Link>
                                </li>
                                <li className="breadcrumb-item fs-6">
                                    <a href="#" title="Services" className="text-dark">Services</a>
                                </li>
                                <li className="breadcrumb-item active fs-6" aria-current="page">Cloud &amp; Infrastructure Services (CIS)</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <div id="content-head" className="container-fluid bg-light py-3">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 mb-3 mb-md-0">
                        <div className="av-special-heading av-special-heading-h1">
                            <h1 className="av-special-heading-tag text-primary">Cloud and Infrastructure Services (CIS)</h1>
                            <div className="special-heading-border">
                                <div className="special-heading-inner-border"></div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

        <div id="av_section_3" className="avia-section main_color avia-section-default avia-no-border-styling avia-bg-style-scroll avia-builder-el-10 el_after_av_section el_before_av_section container_wrap fullsize">
            <div className="container">
                <div className="template-page content av-content-full alpha units">
                    <div className="post-entry post-entry-type-page post-entry-38285">
                        <div className="entry-content-wrapper clearfix reveal">
                            <div className="flex_column av_one_full flex_column_div av-zero-column-padding first avia-builder-el-11 avia-builder-el-no-sibling"
                                style={{ borderRadius: "0px" }}>
                                <p>
                                    <section className="av_textblock_section">
                                        <div className="avia_textblock">
                                            <p className="content">We provide state-of-the-art cloud and infrastructure management solutions, helping
                                                organizations optimize their IT resources, improve scalability, and enhance
                                                overall operational efficiency.</p>
                                            <p className="content">Embrace efficiency and agility by utilizing our cloud solutions. Whether you
                                                want to create a cloud-native architecture or move your current systems,
                                                our team guarantees solid security, maximum performance, and seamless integration.
                                                Savour the liberty of smoothly expanding your business while cutting expenses
                                                and improving cooperation.</p>
                                        </div>
                                    </section>
                                    <br />
                                    <div style={{ height: "30px" }} className="hr hr-invisible avia-builder-el-13 el_after_av_textblock avia-builder-el-last">
                                        <span className="hr-inner">
                                            <span className="hr-inner-style"></span>
                                        </span>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="features" className='avia-section main_color avia-section-default avia-no-border-styling avia-bg-style-scroll  avia-builder-el-14  el_after_av_section  el_before_av_section  container_wrap fullsize'>
            <div className="container-fluid" id="our-client">
                <div className='template-page content  av-content-full alpha units'>
                    <div className='post-entry post-entry-type-page post-entry-38285'>
                        <div className='entry-content-wrapper clearfix reveal'>
                            <div style={{ paddingBottom: "10px", color: "#ffffff", margin: "20px", }} className="av-special-heading av-special-heading-h2 custom-color-heading blockquote modern-quote modern-centered avia-builder-el-15 el_before_av_textblock avia-builder-el-first text-center">
                                <h2 className="special-heading" style={{ padding: "15px", margin: "0" }}>Why iClimb?</h2>
                                <div className="special-heading-border">
                                    <div className="special-heading-inner-border" style={{ borderColor: "#ffffff" }}></div>
                                </div>
                            </div>


                            <section className="av_textblock_section">
                                <div className='avia_textblock  av_inherit_color' style={{ color: "#ffffff" }}>
                                    <p>In the current era, it is not about ‘What’ &#038;‘When’ for enterprises but ‘How’ we
                                        specifically manage diversities in technologies and way of working to deliver and
                                        realize full value of digital transformation. iClimb’s Cloud &#038;Infrastructure
                                        services builds a value runway for today’s enterprises to navigate through the multitude
                                        vectors of disruption and stay resilient, agile, and adaptive.</p>
                                    <p>iClimb’s Cloud &#038;Infrastructure adopts the following four tenets in all its offerings
                                        &#038;solutions.
                                    </p>
                                    <ul>
                                        <li>How we better align the operational metrices to user expectations?</li>
                                        <li>How we continuously discover value across value system?</li>
                                        <li>How we leverage technology to its full potential?</li>
                                        <li>How we build processes that support innovation &#038;continuous improvement?
                                        </li>
                                    </ul>
                                </div>
                            </section>
                            <div data-autoplay="false" data-interval="5" data-animation="slide" data-show_slide_delay="30" className='avia-content-slider-element-container avia-content-slider-element-slider avia-content-slider avia-smallarrow-slider avia-content-slider-active avia-content-slider1 avia-content-slider-even  avia-builder-el-17  el_after_av_textblock  el_before_av_codeblock  av_inherit_color'
                                style={{ color: "#ffffff" }}>
                                <div className='avia-smallarrow-slider-heading  no-content-slider-navigation  no-content-slider-heading '>
                                    <div className="new-special-heading">&nbsp;</div>
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <section className='slide-entry flex_column post-entry slide-entry-overview slide-loop-1 slide-parity-odd av_one_fourth first text-center'>
                                                {/* <!-- Content for the first section goes here --> */}
                                                <div className='test avia_image_container'>
                                                    <img src="./img/Platformize-Ops-80x80-1.png" alt='Platformize Ops' />
                                                </div>
                                                <div className="av_textblock_section">
                                                    <h3 className='small-heading'>Platformize Ops</h3>
                                                    <div className='slide-entry-excerpt entry-content'>
                                                        <p className="text-sm-center">Convergence of data to discover value</p>
                                                    </div>
                                                    <div></div>
                                                </div>
                                            </section>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <section className='slide-entry flex_column post-entry slide-entry-overview slide-loop-2 slide-parity-even av_one_fourth text-center'>
                                                {/* <!-- Content for the second section goes here --> */}
                                                <div className='test avia_image_container'>
                                                    <img src="./img/Synergize-Ways-to-Operate-80x80-1.png" alt='Synergize Ways to Operate' />
                                                </div>
                                                <div className="av_textblock_section">
                                                    <h3 className='small-heading'>Synergize Ways to Operate
                                                    </h3>
                                                    <div className='slide-entry-excerpt entry-content'>
                                                        <p className="text-sm-center">Symbiosis of People, Process, and Tech to deliver value</p>
                                                    </div>
                                                    <div></div>
                                                </div>
                                            </section>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <section className='slide-entry flex_column post-entry slide-entry-overview slide-loop-3 slide-parity-odd av_one_fourth text-center'>
                                                {/* <!-- Content for the third section goes here --> */}
                                                <div className='test avia_image_container'>
                                                    <img src="./img/Harmonize-Tech-80x80-1.png" alt="Harmonize-Tech" />
                                                </div>
                                                <div className="av_textblock_section">
                                                    <h3 className='small-heading'>Harmonize-Tech</h3>
                                                    <div className='slide-entry-excerpt entry-content'>
                                                        <p className="text-sm-center">Convergence of tech to amplify value</p>
                                                    </div>
                                                    <div></div>
                                                </div>
                                            </section>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <section className='slide-entry flex_column post-entry slide-entry-overview slide-loop-4 slide-parity-even post-entry-last av_one_fourth text-center'>
                                                {/* <!-- Content for the fourth section goes here --> */}
                                                <div className='test avia_image_container'>
                                                    <img src="./img/Productize-IT-80x80-1.png" alt='Productize IT' />
                                                </div>
                                                <div className="av_textblock_section">
                                                    <h3 className='small-heading'>Productize IT</h3>
                                                    <div className='slide-entry-excerpt entry-content'>
                                                        <p className="text-sm-center">Align to Business to realize full value</p>
                                                    </div>
                                                    <div></div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="offering" className="avia-section main_color avia-section-default avia-no-border-styling avia-full-stretch av-parallax-section av-section-color-overlay-active avia-bg-style-parallax  avia-builder-el-20  el_after_av_section  el_before_av_section  container_wrap fullsize"
            style={{ backgroundColor: "#ffffff" }} data-section-bg-repeat="stretch">
            <div className="av-parallax" data-avia-parallax-ratio="0.3">
                <div className="av-parallax-inner main_color  avia-full-stretch" style={{ backgroundColor: "#ffffff", backgroundRepeat: "no-repeat", backgroundImage: "url(./img/white_polygon.jpg)", backgroundAttachment: "scroll", backgroundPosition: "top center" }}>
                </div>
            </div>
            {/* <!-- //////////////// Offer Services start ///////////////////////// --> */}
            <div id="offering" className="avia-section main_color avia-section-default avia-no-border-styling avia-bg-style-scroll avia-builder-el-25 el_after_av_section el_before_av_section container_wrap fullsize"
                style={{ backgroundColor: "#2b2e8b", paddingBottom: "50px" }}>
                <div className="container reveal">
                    <div className="template-page content av-content-full alpha units">
                        <div className="post-entry post-entry-type-page post-entry-12220">
                            <div className="entry-content-wrapper clearfix">
                                <div style={{ paddingBottom: "30px", color: "#ffffff", fontSize: "32px" }} className="av-special-heading av-special-heading-h2 custom-color-heading blockquote modern-quote avia-builder-el-26 el_before_av_one_third avia-builder-el-first av-inherit-size text-center">
                                    <h2 className="special-heading" style={{ padding: "15px", margin: 0 }}>Our Offerings
                                    </h2>
                                    <div className="special-heading-border">
                                        <div className="special-heading-inner-border" style={{ borderColor: "#ffffff" }}></div>
                                    </div>
                                </div>
                                <div className="row text-white">
                                    <div className="col-lg-4 col-md-6 col-sm-12 ">
                                        <div className="card mx-auto " style={{ borderWidth: "1px", borderColor: "#ffffff", borderStyle: "solid", borderRadius: "0px", backgroundColor: "transparent" }}>
                                            <img src="./img/Enterprise-IT-infrastructure-Services.png" className="card-img-top" alt="Colud Ops" style={{ width: "40px", height: "50px", marginLeft: "20px", marginTop: "10px" }} />
                                            <div className="card-body p-2">
                                                <h3 className="card-title">Cloud Ops</h3>
                                                <p className="card-text para">Leverage Multi-Modal Technologies to Realize Full Potential of Digital Transformation.
                                                </p>
                                                <div className="pt-3"></div>
                                                <a href="#" className="btn btn-light">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                        <div className="card mx-auto" style={{ borderWidth: "1px", borderColor: "#ffffff", borderStyle: "solid", borderRadius: "0px", backgroundColor: "transparent" }}>
                                            <img src="./img/Digital-Workplace-Services-.png" alt='Digital Workplace Services' className="card-img-top" style={{ width: "60px", height: "60px", marginLeft: "20px", marginTop: "20px" }} />
                                            <div className="card-body p-2">
                                                <h3 className="card-title">Digital Workplace Services</h3>
                                                <p className="card-text para">Enable employees to use sophisticated technologies, helping enhance employee
                                                    experience, thus increasing productivity and business.</p>
                                                <div className="pt-3"></div>
                                                <a href="#" className="btn btn-light">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                        <div className="card mx-auto" style={{ borderWidth: "1px", borderColor: "#ffffff", borderStyle: "solid", borderRadius: "0px", backgroundColor: "transparent" }}>
                                            <img src="./img/serviceNow-icon-new.png" alt="ServiceNow" className="card-img-top" style={{ width: "60px", height: "60px", marginLeft: "20px", marginTop: "20px" }} />
                                            <div className="card-body p-2">
                                                <h3 className="card-title">ServiceNow</h3>
                                                <p className="card-text para">Maximize your ServiceNow investment with Automation-led Innovative Solutions</p>
                                                <div className="pt-3"></div>
                                                <a href="#" className="btn btn-light">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                        <div className="card mx-auto" style={{ borderWidth: "1px", borderColor: "#ffffff", borderStyle: "solid", borderRadius: "0px", backgroundColor: "transparent" }} >
                                            <img src="./img/Webpage_Creatives_Infrastructure-Consulting-.svg" alt='Infrastructure Consulting' className="card-img-top" style={{ width: "60px", height: "60px", marginLeft: "20px", marginTop: "20px" }} />
                                            <div className="card-body p-2">
                                                <h3 className="card-title"> Infrastructure Consulting</h3>
                                                <p className="card-text para">Building a value runway to enable enterprises stay resilient, agile &#038;sustainable</p>
                                                <div className="pt-3"></div>
                                                <a href="#" className="btn btn-light">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                        <div className="card mx-auto" style={{ borderWidth: "1px", borderColor: "#ffffff", borderStyle: "solid", borderRadius: "0px", backgroundColor: "transparent" }}>
                                            <img src="./img/CFS-icon.png" alt='Cross Functional Services' className="card-img-top" style={{ width: "60px", height: "60px", marginLeft: "20px", marginTop: "20px" }} />
                                            <div className="card-body p-2">
                                                <h3 className="card-title">Cross Functional Services</h3>
                                                <p className="card-text para">Reinvent the service value chain with NextGen Service Management and equip
                                                    your organization for the Industrial Revolution 4.0
                                                </p>
                                                <div className="pt-3"></div>
                                                <a href="#" className="btn btn-light">Read More</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                        <div className="card mx-auto" style={{ borderWidth: "1px", borderColor: "#ffffff", borderStyle: "solid", borderRadius: "0px", backgroundColor: "transparent" }}>
                                            <img src="./img/Cloudxperienz-icon.png" alt="CloudXperienz" className="card-img-top" style={{ width: "60px", height: "60px", marginLeft: "20px", marginTop: "20px" }} />
                                            <div className="card-body p-2">
                                                <h3 className="card-title">CloudXperienz</h3>
                                                <p className="card-text para">Automated, composable, secure, uniﬁed multi-cloud management platform to
                                                    help enterprises migrate, monitor &#038;manage hybrid &#038;multi-cloud
                                                    ecosystems
                                                </p>
                                                <div className="pt-3"><div>
                                                    <a href="#" className="btn btn-light">Read More</a>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                        <div className="card mx-auto" style={{ borderWidth: "1px", borderColor: "#ffffff", borderStyle: "solid", borderRadius: "0px", backgroundColor: "transparent" }}>
                                            <img src="./img/Webpage_Creatives_CX-Services.svg" alt='CX Services' className="card-img-top" style={{ width: "60px", height: "60px", marginLeft: "20px", marginTop: "20px" }} />
                                            <div className="card-body p-2">
                                                <h3 className="card-title"> CX Services</h3>
                                                <p className="card-text para">Reinvent “Experience” to steer customer experience focused service delivery
                                                    for your enterprise.</p>
                                                <div className="pt-3"></div>
                                                <a href="#" className="btn btn-light">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <div className="card mx-auto" style={{ borderWidth: "1px", borderColor: "#ffffff", borderStyle: "solid", borderRadius: "0px", backgroundColor: "transparent" }}>
                                            <img src="./img/Digital-Defense.svg" className="card-img-top" alt="Digital Defense" style={{ width: "60px", height: "60px", marginLeft: "20px", marginTop: "20px" }} />
                                            <div className="card-body p-2">
                                                <h3 className="card-title">IoT- OT Security.</h3>
                                                <p className="card-text para">Manage the complexities of IoT/OT security risk with the right experience, solutions,
                                                    and frameworks.</p>
                                                <div className="pt-3"></div>
                                                <a href="#" className="btn btn-light">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <div className="card mx-auto" style={{ borderWidth: "1px", borderColor: "#ffffff", borderStyle: "solid", borderRadius: "0px", backgroundColor: "transparent" }}>
                                            <img src="./img/Icon_-SASE-Secure-access-services-edge-1.svg" className="card-img-top" alt="Icon_ SASE (Secure access services edge)-1"
                                                style={{ width: "60px", height: "60px", marginLeft: "20px", marginTop: "20px" }} />
                                            <div className="card-body p-2">
                                                <h3 className="card-title">Secure access service edge</h3>
                                                <p className="card-text para">Transform your business with next generation SASE services to protect sensitive
                                                    data and network from cyber threats in the ever-evolving digital landscape.</p>
                                                <div className="pt-3"></div>
                                                <a href="#" className="btn btn-light">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Repeat the above structure for additional rows of offerings --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- //////  Offer Services End //////// --> */}
        {/* // <!-- Clients slider start --> */}
        <div className="container-fluid reveal pb-5" id="our-client">
            <div className="row mb-2 py-5">
                <div className="col-lg-3 col-md-12">
                    <h1 className="text-white"> Our Clients</h1>
                </div>
            </div>
            <div className="row">
                <div className="owl-carousel client">
                    <div className="item">
                        <img src="./img/ourclient/1.png" alt=" img 1"/>
                    </div>
                    <div className="item">
                        <img src="./img/ourclient/2.jpg" alt=" img 2"/>
                    </div>
                    <div className="item">
                        <img src="./img/ourclient/3.png" alt=" img 3"/>
                    </div>
                    <div className="item">
                        <img src="./img/ourclient/4.jpg" alt="cimg 4"/>
                    </div>
                    <div className="item">
                        <img src="./img/ourclient/our-client-6.jpg" alt="client img 6"/>
                    </div>
                    <div className="item">
                        <img src="./img/ourclient/10.png" alt="client 10"/>
                    </div>
                    <div className="item">
                        <img src="./img/ourclient/11.jpg" alt="client 11"/>
                    </div>
                    <div className="item">
                        <img src="./img/ourclient/12.png" alt="client 12"/>
                    </div>
                    <div className="item">
                        <img src="./img/ourclient/13.png" alt="client 13"/>
                    </div>
                    <div className="item">
                        <img src="./img/ourclient/14.jpg" alt="client 14"/>
                    </div>
                    <div className="item">
                        <img src="./img/ourclient/15.jpg" alt="client 15"/>
                    </div>
                    <div className="item">
                        <img src="./img/ourclient/16.png" alt="client 16"/>
                    </div>
                </div>
            </div>
        </div>
        {/* <!--  ///////// our clients end//////////// --> */}
    </>
    )
}
export default CloudAndInrastrctureManagement;