import React from "react";
import "./gct.css";
import GCTRightSideBar from "./GCTRightSideBar";

const SoftwareDevelopmentSystemIntegration = () => {
    return (
        <>
            {/* /////////////////Banner Data Analysis//////////////////// */}
            <div className="container-fluid software-development-system-integration-imghere position-relative">
                <div
                    className="position-absolute"
                    style={{ top: "50%", transform: "translateY(-50%)", left: "8%" }}
                >
                    <h4 className="text-white">Software Development and System Integration</h4>
                    <p className="text-white my-4 pe-5 p-md-0">
                    Improving public service deliveries with experience-led services
                    </p>
                    <a href="/it-software-hardware">
                        <div className="btn btn-primary border-0 px-4 py-3 fw-bold rounded-0 w-50">
                            Learn More
                        </div>
                    </a>
                </div>
            </div>

            {/* //////////////////Banner End///////////////////// */}

            <div className="container-fluid text-dark mx-auto ">
                <div className="row my-5">
                    <div className="col-1"></div>
                    <div className="col-lg-6">
                        <h1 className="text-dark" data-aos="fade-up" data-aos-duration="1000">How iClimbs India Can Help</h1>
                        <p className="font-weight-bold">With a team of experienced in-house tech experts, we are able to provide well-rounded guidance and assistance on:</p>
                        <ul className="p-0 m-0 HowGCT"  data-aos="fade-up" data-aos-duration="1000">
                            <li>System Integration</li>
                            <li>Solution Designing & Implementation and Operations</li>
                            <li>Technology Transformation</li>
                            <li>ERP Implementation & Support</li>
                            <li>Smart Cities</li>
                            <li>Bespoke Development</li>
                            <li>University Management System</li>
                            <li>Portal Development</li>
                            <li>Management Information Systems</li>
                            <li>Hospital Management Information System</li>
                        </ul>

                    </div>
                    <GCTRightSideBar />
                </div>
            </div>
       
            {/* ///////Insight////////// */}

            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-5 mx-auto">
                        <img
                            className="w-100"
                            src="./images/Insights.jpg" alt="" />
                    </div>
                    <div className="col-lg-6 mx-2 my-5 m-auto ms-auto">
                        <h1 className="text-dark">Related Insights</h1>
                        <p className="text-dark my-4">
                            Explore our recent thought leadership for updates and expert views
                        </p>
                        <a href="/it-software-hardware">
                            <div className="btn btn-lg btn-primary fs-6 rounded-0">
                                Learn More
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SoftwareDevelopmentSystemIntegration;