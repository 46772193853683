import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Contact.css";
const Contact = () => {
  function prevent(e) {
    e.preventDefault();
    alert("Message Sent Sucessfully");
    document.querySelector("form").reset();
    // alert("Message Sent Sucessfully");
  }
  function prevent2(e) {
    e.preventDefault()
    alert('please correct your information')
  }
  const [sendmsg, setsendmsg] = useState(false)
  function validate() {
    let input = document.querySelectorAll("input[type=text]");
    let textarea = document.querySelector("textarea");
    let email = document.querySelector("input[type=email");
    if (input[0].value == "") {
      setsendmsg(false)
      document.getElementById("text1").innerText = `*This field is Required`;
    } else {
      document.getElementById("text1").innerText = ``;
      setsendmsg(true)
      if (email.value == "") {
        setsendmsg(false)
        document.getElementById("email").innerText = `*This field is Required`;
      } else {
        document.getElementById("email").innerText = ``;
        setsendmsg(true)
        if (input[1].value == "") {
          setsendmsg(false)
          document.getElementById(
            "text2"
          ).innerText = `*This field is Required`;
        } else {
          document.getElementById("text2").innerText = ``;
          setsendmsg(true)
          if (textarea.value == "") {
            setsendmsg(false)
            document.getElementById(
              "textarea"
            ).innerText = `*This field is Required`;
          } else {
            document.getElementById("textarea").innerText = ``;
            setsendmsg(true)
          }
        }
      }
    }
  }
  return (
    <>
      {/* <!-- Page Header Start --> */}
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            Contact
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center mb-0 animated slideInDown">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/home">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item text-white" aria-current="page">
                Contact
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- Contact Start --> */}
      <div className="container-fluid">
        <div className="container py-5">
          <div className="text-center mb-5 wow fadeInUp" data-wow-delay=".3s">
            <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
              Get In Touch
            </h5>
            <h1 className="display-5 w-50 text-dark mx-auto">
              Contact for any query
            </h1>
          </div>
          <div className="row g-5 mb-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
              <div className="h-100">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14025.07054877449!2d77.06788697649769!3d28.501594411265565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d194154c22cf7%3A0x409982753fe0a0d4!2sPhase%20V%2C%20Udyog%20Vihar%2C%20Sector%2019%2C%20Gurugram%2C%20Haryana%20122022!5e0!3m2!1sen!2sin!4v1702027887087!5m2!1sen!2sin"
                  className="border-0 rounded w-100 h-100"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay=".5s">
              <p className="mb-4 text-dark">
                We would love to respond to your queries and help you succeed
                feel free to get in touch with us.
              </p>

              <form
                action=""
                onSubmit={(e) => {
                  sendmsg ? prevent(e) : prevent2(e);
                }}
              >
                <div className="rounded contact-form">
                  <div className="mb-4">
                    <input
                      type="text"
                      className="form-control p-3"
                      placeholder="Your Name"
                      onBlur={validate}

                    />
                    <span id="text1" className="text-danger p-3 mt-2"></span>
                  </div>
                  <div className="mb-4">
                    <input
                      type="email"
                      className="form-control p-3"
                      placeholder="Your Email"
                      onBlur={validate}
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      required
                    />
                    <span id="email" className="text-danger p-3 mt-2"></span>
                  </div>
                  <div className="mb-4">
                    <input
                      type="text"
                      className="form-control p-3"
                      placeholder="Subject"
                      onBlur={validate}

                    />
                    <span id="text2" className="text-danger p-3 mt-2"></span>
                  </div>
                  <div className="mb-4">
                    <textarea
                      className="w-100 form-control p-3"
                      rows="6"
                      cols="10"
                      placeholder="Message"
                      onBlur={validate}

                    ></textarea>
                    <span id="textarea" className="text-danger p-3 mt-2"></span>
                  </div>
                  <input
                    className="btn btn-primary border-0 py-3 px-4 rounded-pill"
                    type="submit"
                    value="Send Message"
                    onClick={validate}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row py-5 py-5">
            <div className="col">
              <h2 className="text-primary text-center">Our Offices in India</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-10">
                  <div className="d-flex bg-light p-3 rounded contact-btn-link">
                    <div
                      className="d-flex align-items-center justify-content-center bg-primary rounded-circle p-4 ms-3"
                      style={{ width: "50px", height: "50px" }}
                    >
                      <i className="fas fa-map-marker-alt text-light"></i>
                    </div>
                    <div className="ms-3 contact-link">
                      <h4 className="text-dark">Gurgaon</h4>
                      <a href="#">
                        <h5 className="text-dark d-inline">
                          <strong>Address-</strong>
                          <span className="fs-6">
                            D-65 Industrial Shed, Udyog Vihar Phase-5, Dlf Ph-2,
                            Gurgaon, Haryana-122008
                          </span>
                        </h5>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-9">
                  <div className="d-flex bg-light p-3 rounded contact-btn-link">
                    <div
                      className="d-flex align-items-center justify-content-center bg-primary rounded-circle p-4 ms-3"
                      style={{ width: "50px", height: "50px" }}
                    >
                      <i className="fa fa-phone text-light"></i>
                    </div>
                    <div className="ms-3 contact-link">
                      <h4 className="text-dark">Call Us</h4>
                      <a className="h5 text-dark fs-6" href="tel:+91 9899697667">
                        +91-9953926020, <br />
                        +91-7879350068, <br />
                        +91-8220888848
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-10">
                  <div className="d-flex bg-light p-3 rounded contact-btn-link">
                    <div
                      className="d-flex align-items-center justify-content-center bg-primary rounded-circle p-4 ms-3"
                      style={{ width: "50px", height: "50px" }}
                    >
                      <i className="fas fa-map-marker-alt text-light"></i>
                    </div>
                    <div className="ms-3 contact-link">
                      <h4 className="text-dark">Bangalore</h4>
                      <a href="#">
                        <h5 className="text-dark d-inline">
                          <strong>Address-</strong>
                          <span className="fs-6">
                            Office No 05A116,43,Residency Road, Shanthala
                            Nagar,Ashok Nagar, Bengaluru,Karnataka-560025
                          </span>
                        </h5>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-9">
                  <div className="d-flex bg-light p-3 rounded contact-btn-link">
                    <div
                      className="d-flex align-items-center justify-content-center bg-primary rounded-circle p-4 ms-3"
                      style={{ width: "50px", height: "50px" }}
                    >
                      <i className="fa fa-envelope text-light"></i>
                    </div>
                    <div className="ms-3 contact-link">
                      <h4 className="text-dark">Email Us</h4>
                      <a
                        className="h5 text-dark fs-6"
                        href="mailto:contact@iclimbs.com"
                      >
                        contact@iclimbs.com
                      </a>
                      <br />
                      <a
                        className="h5 text-dark fs-6"
                        href="https://www.iclimbs.com"
                      >
                        https://www.iclimbs.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Contact End --> */}
    </>
  );
};
export default Contact;
