const ParamedicalHealthServices = () => {
    return (
        <>
            <div className="container-fluid me-0">
                <div className="row">
                    <div className="col-md-12 p-0">
                        <img
                            src="./images/paramedical-banner.jpg"
                            className="d-block w-100 img-fluid"
                            style={{ width: "100%", aspectRatio: 16 / 5, objectFit: "cover" }}
                            alt="Paramedical health Services"
                        />
                    </div>
                </div>
            </div>
            {/* <!-- main section start --> */}
            <div className="container pt-5 pb-5 bg-white">
                <div className="row pt-5 pb-5">
                    <div className="col-lg-6">
                        <h2 className="text-primary">Paramedical And Health Services</h2>
                        <p className="fs-5">
                            In the Indian market, our company specializes in paramount
                            paramedical services. Our trained professionals collaborate
                            seamlessly with medical practitioners, playing a pivotal role in
                            patient care, diagnostics, and healthcare delivery. Proficient in
                            diverse disciplines such as Emergency Medical Services, Laboratory
                            technology, radiography, and physiotherapy, our team contributes
                            significantly to India's healthcare system. We prioritize
                            enhancing overall service quality by aiding in diagnosis,
                            treatment, and rehabilitation, ensuring comprehensive and
                            efficient healthcare solutions. Our services -Manpower Outsourcing
                            ,Lab technicians ,Phlebotomist Services, Mobile Phlebotomist
                            ,Hospital Staff, Home Care Services and Logistics.
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <img src="img/ourclient/doactor.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
            {/* <!-- main section end  --> */}
        </>
    )
}
export default ParamedicalHealthServices;