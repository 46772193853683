import { gsap } from "gsap/dist/gsap";
import { useEffect } from "react";

function ErpVerticals(params) {
  //hooks
  useEffect(() => {
   // gsap.to("#erp", { rotation: 27, x: 100, duration: 2 });
  },[])
  return (
      <>
          <div className="container d-flex ">
              <div className="row justify-content-center">
                  <div className="col-lg-5 col-12 mt-5 align-items-center text-dark">
                      <h3 className="mt-5">Industry Specific ERP Solutions</h3>
                      <h3>Specialized ERP solutions catering to diverse
                    business verticals</h3>
                  </div>
                  <div className="col-lg-7 col-12">
                      <img src="./images1/banner.png" alt="pic" className="w-100 h-75"/>
                  </div>
              </div>
          </div>

          <div className="container d-flex p-5" style={{borderRadius:'20px',backgroundColor:'white',boxShadow:'-5px 0 10px rgba(0, 0, 0, 0.1),5px 0 10px rgba(0, 0, 0, 0.1)'}}>
              <div className="row justify-content-center">
                  <div className="col-lg-3 col-md-6 col-12 text-center">
                      <img src="./images1/r1.png" className='w-25'/>
                      <p className="text-center text-dark">Retail management</p>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12 text-center">
                      <img src="./images1/manufacturing.png" className='w-25'/>
                      <p className="text-center text-dark">Manufacturing</p>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12 text-center">
                      <img src="./images1/warehouse.png" className='w-25'/>
                      <p className="text-center text-dark">Warehouse</p>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12 text-center">
                      <img src="./images1/building.png" className='w-25'/>
                      <p className="text-center text-dark">Building&construction</p>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12 text-center">
                      <img src="./images1/wholesale.png" className='w-25'/>
                      <p className="text-center text-dark">Wholesale Distribution</p>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12 text-center">
                      <img src="./images1/automotive.png" className='w-25'/>
                      <p className="text-center text-dark">Retail management</p>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12 text-center">
                      <img src="./images1/edu.png" className='w-25'/>
                      <p className="text-center text-dark">Education Management trading software</p>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12 text-center">
                      <img src="./images1/trade-services.png" className='w-25'/>
                      <p className="text-center text-dark">Trade & Services</p>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12 text-center">
                      <img src="./images1/laundry-management.png" className='w-25'/>
                      <p className="text-center text-dark">Laundry Management</p>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12 text-center">
                      <img src="./images1/food-beverage.png" className='w-25'/>
                      <p className="text-center text-dark">Food & Beverages</p>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12 text-center">
                      <img src="./images1/rental-services.png" className='w-25'/>
                      <p className="text-center text-dark">Rental & Service</p>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12  text-center">
                      <img src="./images1/field-service.png" className='w-25'/>
                      <p className="text-center text-dark">Field Service</p>
                  </div>
                 
              </div>
          </div>
          <div className="container d-flex  mt-5 text-center">
              <div className="row justify-content-center">
                  <h1 className="fw-bold fs-2 w-75 text-primary">Vertical-Specific ERP Solutions With Ready-To-Deploy Templates</h1>
                  <p className="w-100 mt-5 text-dark">
 iClimbs provides tailored industry-specific solutions leveraging automation and intelligent technologies, enabling seamless adoption of optimal business practices and streamlined process management within a unified system. Our ERP solutions are meticulously crafted to meet the diverse needs of different sectors, effectively tackling their distinct challenges. Delve into the specific industry sectors catered to by our ERP software systems in the subsequent sections.</p>
              </div>
          </div>
          <div className="container d-flex mt-5">
              <div className="row justify-content-center">
                  <div className="col-lg-4 col-12 text-center">
                      <img src="./images1/retail-industry.webp" className="w-75"/>
                  </div>
                  <div className="col-lg-6 col-12 mt-3">
                      <h3 className="fs-1 text-primary">Retail</h3>
                      <p className="text-dark">Our versatile retail software empowers you to conquer business hurdles while enhancing the shopping journey for your customers. Meticulously designed, these systems seamlessly merge traditional POS procedures with cutting-edge tools to expedite billing, ensure accurate inventory management, and elevate service consistency, thereby fostering greater customer loyalty.</p>
                      <button className="p-2 d-lg-block d-none  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>

{/* ///for mobile */}
<button className="p-2 d-lg-none d-block w-100  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>
                  </div>
              </div>
          </div>
          <div className="container d-flex w-100 mt-5" style={{backgroundColor:'white',boxShadow:'-5px 0 10px rgba(0, 0, 0, 0.1),5px 0 10px rgba(0, 0, 0, 0.1)'}}>
              <div className="row justify-content-center align-items-center">
                  <div className="col-lg-6 col-12 p-2">
                      <h2 className="text-primary">Manufacturing</h2>
                      <p className="text-dark">We provide tailored manufacturing ERP software solutions to enable swift and precise responses to evolving customer demands, efficient management of materials and supply chains, meticulous tracking and control of the entire production cycle, intelligent procurement planning, proactive schedule adjustments, and comprehensive oversight of all processes influencing your business growth and profitability..</p>
                      <button className="p-2 d-lg-block d-none  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>

                      {/* ///for mobile */}
                      <button className="p-2 d-lg-none d-block w-100  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>
                  </div>
                  <div className="col-lg-4 col-12 mt-4"> 
                          <img src="./images1/m1.webp" className="w-100"/>
                  </div>
              </div>
          </div>

          <div className="container d-flex mt-5">
              <div className="row justify-content-center">
                  <div className="col-lg-4 col-12 text-center">
                      <img src="./images1/w1.webp" className="w-75 d-lg-block d-none"/>
                  </div>
                  <div className="col-lg-6 col-12 mt-3">
                      <h3 className="fs-1 text-primary" style={{fontWeight:'300'}}>Warehousing & Logistics</h3>
                      <p className="text-dark">Our adaptable warehousing and logistics management software solutions deliver live control over inventory movements, offering customizable and scalable systems tailored to meet the needs of businesses across all sizes. Whether managing a mid-sized operation with fewer orders or overseeing a larger enterprise with intricate processes, our ERP systems provide streamlined tools to effectively handle all operations.</p>
                      <button className="p-2 d-lg-block d-none  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>

{/* ///for mobile */}
<button className="p-2 d-lg-none d-block w-100  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>
                  </div>
                  {/* ///for mobile */}
                  <div className="col-lg-4 col-12 text-center">
                      <img src="./images1/w1.webp" className="w-75 d-lg-none d-block m-auto mt-4"/>
                  </div>
              </div>
          </div>

          <div className="container d-flex w-100 mt-5" style={{backgroundColor:'white',boxShadow:'-5px 0 10px rgba(0, 0, 0, 0.1),5px 0 10px rgba(0, 0, 0, 0.1)'}}>
              <div className="row justify-content-center align-items-center">
                  <div className="col-lg-6 col-12 p-2">
                      <h2 className="text-primary">Real Estate & Construction</h2>
                      <p className="text-dark">Our comprehensive cloud-based ERP and CRM solutions for real estate and construction integrate modules that oversee and regulate all facets of your business operations. Encompassing lead generation, sales conversion, finance, and customer service, these modules span the entirety of the real estate project lifecycle. Enhanced with intuitive dashboards and simplified UI, they facilitate seamless navigation and elevate user experience..</p>
                      <button className="p-2  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>
                  </div>
                  <div className="col-lg-4 col-12 mt-4"> 
                          <img src="./images1/c1.webp" className="w-100"/>
                  </div>
              </div>
          </div>


          <div className="container d-flex mt-5">
              <div className="row justify-content-center">
                  <div className="col-lg-4 col-12 text-center">
                      <img src="./images1/d1.webp" className="w-75 d-lg-block d-none"/>
                  </div>
                  <div className="col-lg-6 col-12 mt-3">
                      <h3 className="fs-1 text-primary" style={{fontWeight:'300'}}>Distribution</h3>
                      <p className="text-dark">Cutting-edge distribution ERP software solutions empower you to monitor inventory movement in real-time, gauge product demand, and maintain timely deliveries while effectively managing shipping costs. These ERP systems facilitate the establishment and optimization of supplier-vendor partnerships, offering complete visibility across the supply chain through their comprehensive modules.</p>
                      <button className="p-2 d-lg-block d-none  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>

                      {/* ///for mobile */}
                      <button className="p-2 d-lg-none d-block w-100  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>
                  </div>
                  {/* ///for mobile */}
                  <div className="col-lg-4 col-12 text-center">
                      <img src="./images1/d1.webp" className="w-75 d-lg-none d-block m-auto mt-4"/>
                  </div>
              </div>
          </div>

          <div className="container d-flex w-100 mt-5" style={{backgroundColor:'white',boxShadow:'-5px 0 10px rgba(0, 0, 0, 0.1),5px 0 10px rgba(0, 0, 0, 0.1)'}}>
              <div className="row justify-content-center align-items-center">
                  <div className="col-lg-6 col-12 p-2">
                      <h2 className="text-primary">Automotive</h2>
                      <p className="text-dark">
Dynamic and responsive auto dealership management software solutions cater to the needs of dealers and workshop owners, ensuring comprehensive traceability and transparency throughout all business operations. These web-based systems seamlessly integrate internal and external processes to efficiently manage material and human resources, monitor work progress, and optimize outcomes. Ideal for auto sales, rentals, leasing, and garage management, they provide a robust platform to streamline operations and drive success..</p>
                      <button className="p-2 d-lg-block d-none  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>

                      {/* ///for mobile */}
                      <button className="p-2 d-lg-none d-block w-100  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>
                  </div>
                  <div className="col-lg-4 col-12 mt-4"> 
                          <img src="./images1/a1.webp" className="w-100"/>
                  </div>
              </div>
          </div>
          <div className="text-center  mt-4" style={{color:"skyblue",fontWeight:'400'}}>
              <h2 className="fs-2 text-primary">Initiate your business transformation today</h2>
              <button className="m-auto p-2 mt-4 fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Get Expert Advice</button>
          </div>

          

          <div className="container d-flex mt-5">
              <div className="row justify-content-center">
                  <div className="col-lg-4 col-12 text-center">
                      <img src="./images1/e1.webp" className="w-75 d-lg-block d-none"/>
                  </div>
                  <div className="col-lg-6 col-12 mt-3">
                      <h3 className="fs-1 text-primary" style={{fontWeight:'300'}}>Education</h3>
                      <p className="text-dark">Streamline administrative tasks across your educational institution, from admissions to ongoing operations, with our academic ERP solutions. These systems offer real-time access to comprehensive data, facilitating accurate reporting and insightful analytics to support informed decision-making. Additionally, they provide effective tools for planning, budgeting, and forecasting, alongside automated attendance management and time sheet maintenance, empowering your institution with efficiency and effectiveness..</p>
                      <button className="p-2 d-lg-block d-none  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>

                      {/* ///for mobile */}
                      <button className="p-2 d-lg-none d-block w-100  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>
                  </div>
                  {/* ///for mobile */}
                  <div className="col-lg-4 col-12 text-center">
                      <img src="./images1/e1.webp" className="w-75 d-lg-none d-block m-auto mt-4"/>
                  </div>
              </div>
          </div>

          <div className="container d-flex w-100 mt-5" style={{backgroundColor:'white',boxShadow:'-5px 0 10px rgba(0, 0, 0, 0.1),5px 0 10px rgba(0, 0, 0, 0.1)'}}>
              <div className="row justify-content-center align-items-center">
                  <div className="col-lg-6 col-12 p-2">
                      <h2 className="text-primary">Trade & Services</h2>
                      <p className="text-dark">Our ERP solutions for trading and service businesses alleviate the complexities of project management. By consolidating operations into a single platform, they streamline resource allocation, enhance accuracy in price quoting, facilitate revenue recognition, pinpoint opportunities for margin enhancement, foster improved client collaboration, and enable swift adaptation to changes.</p>
                      <button className="p-2 d-lg-block d-none  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>

{/* ///for mobile */}
<button className="p-2 d-lg-none d-block w-100  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>
                  </div>
                  <div className="col-lg-4 col-12 mt-4"> 
                          <img src="./images1/t1.webp" className="w-100"/>
                  </div>
              </div>
          </div>


          <div className="container d-flex mt-5">
              <div className="row justify-content-center">
                  <div className="col-lg-4 col-12 text-center">
                      <img src="./images1/m2.webp" className="w-75 d-lg-block d-none"/>
                  </div>
                  <div className="col-lg-6 col-12 mt-3">
                      <h3 className="fs-1 text-primary" style={{fontWeight:'300'}}>Marine Engineering</h3>
                      <p className="text-dark"> iClimbs's cutting-edge ERP applications are tailored to support the management of high-stakes projects within the marine industry. Equipped with robust tools and features, these solutions ensure alignment with commercial objectives, budgets, and project timelines. Gain clear visibility and complete control over assets through real-time reporting, while maximizing revenue and minimizing risks through automated workflows and digital processes..</p>
                      <button className="p-2 d-lg-block d-none  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>

                      {/* ///for mobile */}
                      <button className="p-2 d-lg-none d-block w-100  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>
                  </div>
                  {/* ///for mobile */}
                  <div className="col-lg-4 col-12 text-center">
                      <img src="./images1/m2.webp" className="w-75 d-lg-none d-block m-auto mt-4"/>
                  </div>
              </div>
          </div>

          <div className="container d-flex w-100 mt-5" style={{backgroundColor:'white',boxShadow:'-5px 0 10px rgba(0, 0, 0, 0.1),5px 0 10px rgba(0, 0, 0, 0.1)'}}>
              <div className="row justify-content-center align-items-center">
                  <div className="col-lg-6 col-12 p-2">
                      <h2 className="text-primary">Cleaning Solutions</h2>
                      <p className="text-dark">
Eliminate the uncertainties inherent in manual operations by implementing our advanced ERP software. With automated modules and digital tools at its core, our ERP systems provide you with a centralized platform to oversee daily operations, conduct thorough workforce audits, and boost productivity. Embrace efficiency by transitioning from manual processes to digitally automated and dependable structures..</p>
                      <button className="p-2 d-lg-block d-none  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>

{/* ///for mobile */}
<button className="p-2 d-lg-none d-block w-100  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>
                  </div>
                  <div className="col-lg-4 col-12 mt-4"> 
                          <img src="./images1/c2.webp" className="w-100"/>
                  </div>
              </div>
          </div>


          <div className="container d-flex mt-5">
              <div className="row justify-content-center">
                  <div className="col-lg-4 col-12 text-center">
                      <img src="./images1/l1.webp" className="w-75 d-lg-block d-none"/>
                  </div>
                  <div className="col-lg-6 col-12 mt-3">
                      <h3 className="fs-1 text-primary" style={{fontWeight:'300'}}>Laundry Management</h3>
                      <p className="text-dark">Elevate your laundry management operations to unparalleled levels of efficiency and cleanliness with our AI-powered ERP solution. Say goodbye to the inconsistencies of manual tasks as you handle operations seamlessly from anywhere. Ensure your staff maintains the quality standards you aspire to with ease. Utilize our tailor-made modules to optimize daily processes and minimize errors. Enhance customer satisfaction by delivering a more reliable and efficient service..</p>
                      <button className="p-2 d-lg-block d-none  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>

                      {/* ///for mobile */}
                      <button className="p-2 d-lg-none d-block w-100  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>
                  </div>
                  {/* ///for mobile */}
                  <div className="col-lg-4 col-12 text-center">
                      <img src="./images1/l1.webp" className="w-75 d-lg-none d-block m-auto mt-4"/>
                  </div>
              </div>
          </div>

          <div className="container d-flex w-100 mt-5" style={{backgroundColor:'white',boxShadow:'-5px 0 10px rgba(0, 0, 0, 0.1),5px 0 10px rgba(0, 0, 0, 0.1)'}}>
              <div className="row justify-content-center align-items-center">
                  <div className="col-lg-6 col-12 p-2">
                      <h2 className="text-primary">Food & Beverage</h2>
                      <p className="text-dark">Empower your organization and invigorate your team with  iClimbs's tailored ERP software, designed specifically for the food and beverage industry. Streamline all operations, from procurement to distribution, on a unified platform. Automate critical aspects of product management to drive cost savings. Ensure adherence to regulatory standards with our comprehensive ERP tools and surpass competitors by maintaining superior quality standards..</p>
                      <button className="p-2 d-lg-block d-none  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>

{/* ///for mobile */}
<button className="p-2 d-lg-none d-block w-100  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>
                  </div>
                  <div className="col-lg-4 col-12 mt-4"> 
                          <img src="./images1/f1.webp" className="w-100"/>
                  </div>
              </div>
          </div>

          <div className="container d-flex mt-5">
              <div className="row justify-content-center">
                  <div className="col-lg-4 col-12 text-center">
                      <img src="./images1/re.webp" className="w-75 d-lg-block d-none"/>
                  </div>
                  <div className="col-lg-6 col-12 mt-3">
                      <h3 className="fs-1 text-primary" style={{fontWeight:'300'}}>Real Estate Rental and Service Management</h3>
                      <p className="text-dark">Escape the constraints of disjointed software systems for managing your real estate rental and service operations. Embrace our cloud ERP software to seamlessly consolidate all tasks onto one unified platform. Manage inventory, finances, workflows, and projects effortlessly through a mobile-ready ERP system accessible anytime, anywhere. Discover tailored ERP solutions designed specifically for real estate rental and service businesses for a comprehensive approach to managing your operations..</p>
                      <button className="p-2 d-lg-block d-none  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>

                      {/* ///for mobile */}
                      <button className="p-2 d-lg-none d-block w-100  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>
                  </div>
                  {/* ///for mobile */}
                  <div className="col-lg-4 col-12 text-center">
                      <img src="./images1/re.webp" className="w-75 d-lg-none d-block m-auto mt-4"/>
                  </div>
              </div>
          </div>

          <div className="container d-flex w-100 mt-5" style={{backgroundColor:'white',boxShadow:'-5px 0 10px rgba(0, 0, 0, 0.1),5px 0 10px rgba(0, 0, 0, 0.1)'}}>
              <div className="row justify-content-center align-items-center">
                  <div className="col-lg-6 col-12 p-2">
                      <h2 className="text-primary">Field Service Management</h2>
                      <p className="text-dark">
Distinguish yourself from competitors with  iClimb's dependable cloud-based field service management solutions. Showcase your efficiency both in the field and behind the scenes using the advanced real-time reporting tools integrated into our ERP systems. Elevate performance and maximize ROI through customizable cloud-based modules tailored to meet your specific needs..</p>
                      <button className="p-2 d-lg-block d-none  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>

{/* ///for mobile */}
<button className="p-2 d-lg-none d-block w-100  fs-6" style={{background:'skyblue',color:'white',borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>
                  </div>
                  <div className="col-lg-4 col-12 mt-4"> 
                          <img src="./images1/f2.webp" className="w-100"/>
                  </div>
              </div>
          </div>


          <div className="container d-flex mt-5">
              <div className="row justify-content-center">
                  <div className="col-lg-4 col-12 text-center">
                      <img src="./images1/w2.webp" className="w-75 d-lg-block d-none"/>
                  </div>
                  <div className="col-lg-6 col-12 mt-3">
                      <h3 className="fs-1 text-primary" style={{fontWeight:'300'}}>Waste Management</h3>
                      <p className="text-dark">Effortlessly dominate waste management and disposal with our versatile ERP systems. Select from a diverse range of modules and tailor them to align with your business needs. Establish a notable presence in the waste management industry by adopting responsible and eco-friendly disposal practices supported by automated tools and workflows..</p>
                      <button className="p-2 d-lg-block d-none  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>

                      {/* ///for mobile */}
                      <button className="p-2 d-lg-none d-block w-100  fs-6 bg-primary text-white" style={{borderRadius:'20px',border:'none'}}>Know ERP in Detail</button>
                  </div>
                  {/* ///for mobile */}
                  <div className="col-lg-4 col-12 text-center">
                      <img src="./images1/w2.webp" className="w-75 d-lg-none d-block m-auto mt-4"/>
                  </div>
              </div>
          </div>
<div className="text-center mt-5" style={{color:"skyblue"}}>
  <h2 className="text-primary">Why Choose Vertical-Specific ERP Software?</h2>
</div>
          
          <div className="container d-flex mt-5">
              <div className="row justify-content-center">
                  <div className="col-lg-4 col-12 text-center">
                      <img src="./images1/i1.webp" className="w-75 d-lg-block d-none"/>
                  </div>
                  <div className="col-lg-6 col-12 mt-3">
                  
                      <p className="text-dark">While generic ERP solutions are equipped with modules to manage operations across various industries, they often lack the specialized functionalities unique to specific verticals.  iClimb's industry-specific ERP solutions effectively address these gaps, providing tailored features to streamline processes and automate operations. The key advantages of our ERP systems delivery include:.</p>
                       <div>
                          <p>1.<span className="mx-3 text-dark">Equipped with industry-ready templates</span></p>
                          <p>2.<span className="mx-3 text-dark">Can be customized to suit your specific needs</span></p>
                          <p>3.<span className="mx-3 text-dark">Quickly deployable and easily configurable</span></p>
                       </div>
                  </div>
                  {/* ///for mobile */}
                  <div className="col-lg-4 col-12 text-center">
                      <img src="./images1/i1.webp" className="w-75 d-lg-none d-block m-auto mt-4"/>
                  </div>
              </div>
          </div>
      </>
  )
}
export default ErpVerticals;
