import { Link } from "react-router-dom";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud, faPeopleGroup, faMagnifyingGlassChart, faHospitalUser } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import TeamMember from "../include/team";

const About = () => {
    const [searchValue, setSearchValue]= useState("");
    return (
        <>
            {/* <!-- Page Header Start --> */}
            <div className="container-fluid py-5 page-header">
                <div className="container text-center py-5">
                    <h1 className="display-2 text-white mb-4 animated slideInDown">ABOUT US</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center mb-0 animated slideInDown">
                            <li className="breadcrumb-item" id="breadcrumbitem">
                                <Link className="text-white fs-6" to="/home">Home</Link>
                            </li>
                            <li className="breadcrumb-item text-white fs-6" id="breadcrumbitem" aria-current="page">About</li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/* 2 about start */}
            <div className="container pb-5 ">
                <div className="row pb-5">
                    <div className="col-lg-6">
                        <h2 className="text-primary">ABOUTS US</h2>
                        <p className="fs-5 text-dark">At I-Climb Pvt Ltd, we are dedicated to being your
                            reliable partner in navigating the fast-paced realms of IT Recruitment and Consulting business. Our commitment lies in providing high-quality, cost-effective services that empower your business to achieve its goals. With a client-centric approach, we prioritize your success, blending passion for technology, unwavering commitment to service, and a relentless focus on excellence.
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <img src="./img/ourclient/meeting.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
            {/* 2 abouts End */}
            {/* About Start */}
            <div className="container-fluid py-5">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay=".3s">
                            <div className="about-img">
                                <div className="rotate-left bg-dark"></div>
                                <div className="rotate-right bg-dark"></div>
                                <img src="./img/slider/iclimb-banner-3.jpg" className="img-fluid h-100" alt="img" />
                                <div className="bg-white experiences">
                                    <h1 className="display-3 text-primary">7</h1>
                                    <h6 className="fw-bold text-primary">Years Of Experiences</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay=".6s">
                            <div className="about-item overflow-hidden">
                                <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
                                    About iClimbs</h5>
                                <h1 className="display-5 mb-2 text-primary"> Your trusted partner for success in the fast-paced world of IT.
                                </h1>
                                <p className="fs-5">At iClimbs, we are committed to delivering high-quality, cost-effective services that empower
                                    your business to achieve its goals.We take pride in our client-centric approach, ensuring
                                    that your success is our top priority</p>
                                <div className="row">
                                    <div className="col-md-3 col-sm-6 ">
                                        <div className="text-center">
                                            <div className="p-4 bg-dark rounded d-flex align-items-center justify-content-center">
                                                <FontAwesomeIcon icon={faCloud} className="text-info fa-4x" />
                                            </div>
                                            <div className="my-2">
                                                <h6 className="text-primary">CLOUD AND</h6>
                                                <h6 className="text-primary">INFRASTRUCTURE</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="text-center">
                                            <div className="p-4 bg-dark rounded d-flex align-items-center justify-content-center">
                                                <FontAwesomeIcon icon={faPeopleGroup} className="text-info fa-4x" />
                                            </div>
                                            <div className="my-2">
                                                <h6 className="text-primary">DIGITAL</h6>
                                                <h6 className="text-primary">MARKETING</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="text-center">
                                            <div className="p-4 bg-dark rounded d-flex align-items-center justify-content-center">
                                                <FontAwesomeIcon icon={faMagnifyingGlassChart} className="text-info fa-4x" />
                                            </div>
                                            <div className="my-2">
                                                <h6 className="text-primary">DATA &</h6>
                                                <h6 className="text-primary">ANALYTICS</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="text-center">
                                            <div className="p-4 bg-dark rounded d-flex align-items-center justify-content-center">
                                                <FontAwesomeIcon icon={faHospitalUser} className="text-info fa-4x" />
                                            </div>
                                            <div className="my-2">
                                                <h6 className="text-primary">STAFFING</h6>
                                                <h6 className="text-primary"> SOLUTION</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" className="btn btn-primary border-0 rounded-pill px-4 py-3 mt-5">Find Services</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About End */}
            {/* our Team member start  */}

                {/* <TeamMember /> */}
            
            {/* our Team member End  */}
            {/* Call To Action Start */}
            <div className="reveal">
                <div className="container-fluid py-5 call-to-action wow fadeInUp" data-wow-delay=".3s" style={{ margin: "6rem 0" }}>
                    <div className="container">
                        <div className="row g-4">
                            <div className="col-lg-6">
                                <img src="./img/slider/iclimb-banner-4.jpg" className="img-fluid w-100 rounded-circle p-5" alt="" />
                            </div>
                            <div className="col-lg-6 my-auto">
                                <div className="text-start mt-4">
                                    <h1 className="pb-4 text-white">Sign Up To Our Newsletter To Get The Latest Offers</h1>
                                </div>
                                <form action="/home">
                                    <div className="form-group">
                                        <div className="d-flex call-btn">
                                            <input
                                                type="search"
                                                className="form-control py-3 px-4 w-100 border-0 rounded-0 rounded-end rounded-pill"
                                                name="search-input"
                                                value={searchValue}
                                                onChange={(e) => setSearchValue(e.target.value)} // Assuming you're using a state variable to manage input value
                                                placeholder="Enter Your Email Address"
                                                required="Please enter a valid email"
                                            />
                                            <button type="email" value="Search Now!" className="btn btn-info border-0 rounded-pill rounded rounded-start px-5">Subscribe</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About;