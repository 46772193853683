import { Link } from 'react-router-dom';
import ReachUs from '../include/reachus';
import './healthcare.css'
const Healthcare = () => {
    return (
        <>
            <div className="main">
            <div className="row me-0">
                    <div className="col-md-12 position-relative px-0">
                        <img src="./img/barner.jpg" height="350px" width="100%"/>
                        <h1 className="position-absolute top-0 start-0 p-5 text-light">Healthcare</h1>
                        <h5 className="position-absolute top-0 start-0 p-5 mt-5 text-white" >
                            Robust healthcare consuling service and healthcare technology solution for
                            <br/>enhanced quality,transparency,and access</h5>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className=" row  mt-2 text-dark">
                        <div className="col-md-1 col-sm-6  ">

                            <h6 style={{marginLeft: "10px", textSizeAdjust: "2px"}}>Home
                                <i className="fa-solid fa-chevron-right" style={{fontSize: "small", marginLeft: "10px"}}></i>
                            </h6>
                        </div>
                        <div className="col-md-1 col-sm-6 ">
                            <h6>Industries
                                <i className="fa-solid fa-chevron-right" style={{fontSize: "small", width: "5px", marginLeft: "5px"}}></i>
                            </h6>
                        </div>
                        <div className="col-md-1 col-sm-6 ">
                            <h6>Healthcare </h6>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mt-5">
                    <div className="row">
                        <div className="col-md-2">
                            <h2 style={{ color:`var(--bs-info)` }}>Healthcare</h2>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mt-2">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
                            <p className="text-dark">
                                Some of the fastest growing and most active areas of society right now are in the Healthcare space. Population growth, longer
                                lifespans, increasing chronic conditions, unstable economic forces, a war for talent, and the
                                ever-increasing pressures of compliance continuously test the ingenuity of healthcare providers.
                                The need accelerate innovation and deepen patient-centricity has never been more urgent. But
                                today’s leaders also know they must balance this need with increasingly complex financial and
                                regulatory constraints.

                            </p>

                            <p className="text-dark">
                                iClimbs has worked with our customers across the payer, provider, healthcare product manufacturer, pharmacy, and benefits
                                manager landscape, among others to provide a deep and broad set of business solutions ranging
                                from new and exciting consulting strategies to bold technology solutions across the enterprise.
                                Our consultants and solution partners work seamlessly with our customers across the entire value
                                chain to improve acquisition, increase margins, accelerate and improve clinical decision-making,
                                enable self-service capabilities, and step up to incredibly complex regulatory compliance expectations
                                such as HIPAA, GDPR, CCPA, and PIPEDA.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
                            <p className="text-dark font-weight-bold">
                                Our services for payers and providers include core capabilities to take on the challenges of today and the future, including:
                            </p>
                            <ul className='text-li'>
                                <li className='fs-6'>
                                    Enhanced customer management, acquisition, retention, engagement, and communication
                                </li>
                                <li className='fs-6'>
                                    Robust Cyber-Security and interoperability capabilities and solutions
                                </li>
                                <li className='fs-6'>
                                    Cutting edge digital health solutions including Digital front-door, healthcare mobility, telehealth
                                </li>
                                <li className='fs-6'>
                                    solutions, wearables/biometrics, and IoHT
                                </li>
                                <li className='fs-6'>
                                    Broader adoption of value-based and localized care models such as population health and hyper-personalized care management
                                </li>
                                <li className='fs-6'>
                                    More efficient and effective revenue-cycle management utilizing automation-driven approaches and predictive analytics

                                </li>
                                <li className='fs-6'>
                                    Acceleration and application of enterprise administrative platform transformation solutions
                                </li>
                                <li className='fs-6'>Resilient and robust data security and privacy solutions</li>
                                <li className='fs-6'>
                                    Enhanced consumer experience through highly personalized and self-service capabilities
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
                            <p className="text-justify text-dark">
                                Our healthcare professionals are working with our customers every day to drive seamless adoption of new and evolving processes
                                and solutions across the healthcare industry. Our Fosfor suite of products has been a driving
                                force for clients deploying cognitive and ML capabilities to improve decision-making, allowing
                                them to leverage data engineering, cloud, AI, ML, process automation, predictive analytics, and
                                collaboration tools, to make the world a healthier place for all of us.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <ReachUs />
                        </div>
                    </div>
                </div>
                <div className="container-fluid" style={{ backgroundColor: "lightgray" }}>
                    <div className="row mt-2  mt-5 pt-5">
                        <h1 className="text-center" style={{ color: "blue" }}>Our Offerings</h1>
                    </div>
                    <div className="row bg-primary reveal">
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-4 d-flex justify-content-center" style={{marginTop:"50px", marginBottom: "100px"}}>
                            <div className="card" style={{ width: "300px", height: "500px", boxShadow: "2px 2px 3px gray" }}>
                                <img className="" src="./img/ct scan.jpg" alt="Card image" style={{ width: "100%" }} />
                                <div className="card-body">
                                    <h5 className="card-title" style={{ color: "blue" }}>Operate to Transform</h5>
                                    <p className="card-text mt-4">Reduce operating cost by managed services leveraging AIOps, intelligent RPA, test automation
                                        for high productivity Application and Infrastructure Maintenance.</p>
                                    <Link to="/infrastructure-state-management-service" className="btn btn-primary  mt-4 mx-5">Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-4 d-flex justify-content-center" style={{ marginTop: "50px", marginBottom: "100px" }}>
                            <div className="card" style={{ width: "300px", height: "500px", boxShadow: "2px 2px 3px gray" }}>
                                <img className="" src="./img/driven.jpg" alt="Card image" style={{ width: "100%" }} />
                                <div className="card-body">
                                    <h5 className="card-title" style={{color: "blue"}}>Data Driven Organizations</h5>
                                    <p className="card-text mt-4">Integrating and ingesting data from multiple healthcare databases for mining, analysis and
                                        decision making. Read about our experience on Health Data Insights and Compliance & Regulatory
                                        Reforms.
                                    </p>
                                    
                                    <a href="#" className="btn btn-primary  mt-1 mx-5">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-4 d-flex justify-content-center" style={{marginTop:"50px", marginBottom:"100px"}}>
                            <div className="card" style={{width:"300px", height: "500px", boxShadow: "2px 2px 3px gray"}}>
                                <img className="" src="./img/experience.jpg" alt="Card image" style={{ width: "100%" }} />
                                <div className="card-body">
                                    <h5 className="card-title " style={{ color: "blue" }}>Experience Transformation</h5>
                                    <p className="card-text mt-4">Our design thinking approach helps reimagine journey maps across entire healthcare value
                                        chain enhancing customer engagement and improving member & provider experience.</p>
                                        <a href="#" className="btn btn-primary  mt-4 mx-5">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-4 d-flex justify-content-center" style={{ marginTop: "50px", marginBottom: "100px" }}>
                            <div className="card" style={{ width: "300px", height: "500px", boxShadow: "2px 2px 3px gray" }}>
                                <img className="" src="./img/digitl.jpg" alt="Card image" style={{ width: "100%" }} />
                                <div className="card-body">
                                    <h5 className="card-title" style={{color: "blue"}}>Digitizing the Core </h5>
                                    <p className="card-text mt-3">Modernizing and consolidating healthcare applications and infrastructure making both payer
                                        and provider organizations agile and responsive. Know more about our Technology Transformation
                                        offerings and Healthcare Process Automation.
                                    </p>
                                    <a href="#" className="btn btn-primary  mt-0 mx-5">Read More</a>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mt-5 py-5 bg-primary">
                    <div className="row mt-3">
                        <h2 className="text-center text-white">Solutions & Accelerators</h2>
                    </div>
                    <div className="row mt-5 py-5 bg-primary reveal">
                        {/* <!-- Card 1 --> */}
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
                            <div className="card" style={{ width: "300px", height: "370px", boxShadow: "2px 2px 3px gray" }}>
                                <div className="row heading pt-3 gx-0">
                                    <div className="col-md-3 col-3 ">
                                        <img src="./img/men.png" alt="Card image" width="50px" height="50px" />
                                    </div>
                                    <div className="col-md-9 col-9">
                                        <h4 className="p-3 text-white">iCEOn</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card-text mt-3 p-2 p-sm-0 text-dark  text-center">iClimbs offers platforms and solution accelerators redefining the healthcare operating
                                            model by empowering member and provider with optimal touch and rich interactions.
                                        </div>
                                        <a href="#" className="btn btn-primary  mt-5 ">Know More</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Card 2 --> */}
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
                            <div className="card" style={{ width: "300px", height: "370px", boxShadow: "2px 2px 3px gray" }}>
                                <div className="row heading pt-3 gx-0" style={{ backgroundColor: "orange" }}>
                                    <div className="col-md-3 col-3 ">
                                        <img src="./img/head.png" alt="Card image" width="50px" height="50px" />
                                    </div>
                                    <div className="col-md-9 col-9">
                                        <h4 className="p-3 text-white">Lumin</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card-text mt-3 p-2 text-dark  text-center">
                                            Transform curiosity into limitless insight.<br/>
                                            Illuminating Curiosity, Unveiling Limitless Insights.
                                        </div>
                                        <a href="#" className="btn btn-primary  mt-1 mx-2">Know More</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Card 3 --> */}
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
                            <div className="card" style={{ width: "300px", height: "370px", boxShadow: "2px 2px 3px gray" }}>
                                <div className="row heading pt-3 gx-0" style={{ backgroundColor: `rgb(4, 76, 129)` }}>
                                    <div className="col-md-3 col-3">
                                        <img src="./img/head2.png" alt="Card image" width="50px" height="50px" />
                                    </div>
                                    <div className="col-md-9 col-9">
                                        <h4 className="p-3 text-white">Aspect</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <center>
                                            <div className="card-text mt-3 p-2 text-dark  text-center">
                                                Healthcare organizations are triggered by Artificial Intelligence & Cognitive technologies to ease the data intake process
                                                thereby increasing operational efficiency and reducing cost and effort.
                                            </div>
                                        </center>
                                        <a href="#" className="btn btn-primary  mt-5 ">Know More</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Card 4 --> */}
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
                            <div className="card" style={{width:"300px", height: "370px", boxShadow: "2px 2px 3px gray"}}>
                                <div className="row heading pt-3  gx-0 bg-primary">
                                    <div className=" col-md-3 col-3">
                                        <img src="./img/eye.png" alt="Card image" width="50px" height="50px" />
                                    </div>
                                    <div className="col-md-9 col-9">
                                        <h4 className="p-3 text-white">PrivateEye</h4>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-md-12 ">
                                        <div className="card-text mt-3 p-2 text-dark  text-center">
                                            Healthcare organizations undergo process and rule changes due to stringent regulatory reforms. Iclimb offers consulting service
                                            and tools to ensure privacy compliance.
                                        </div>
                                        <a href="# " className="btn btn-primary mt-5">Know More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
            )
}
export default Healthcare;