
import React from "react";
import "./gct.css";
import GCTRightSideBar from "./GCTRightSideBar";
import { Link } from "react-router-dom";

const GovConsltingTranjAdv = () => {
  return (
    <>
      {/* /////////////////Banner start //////////////////// */}
      <div className="container-fluid imghere position-relative">
        <div
          className="position-absolute"
          style={{ top: "50%", transform: "translateY(-50%)", left: "8%" }}
        >
          <h4 className="text-white">Consulting Services</h4>
          <p className="text-white my-4 pe-5 p-md-0">
            Helping institutions realise their goals through our expert
            consulting
          </p>
          <div className="btn btn-primary border-0 px-4 py-3 fw-bold rounded-0 w-50">
            <Link to="/consulting">Learn More</Link>
          </div>
        </div>
      </div>

      {/* ///////////////////Banner End//////////////////// */}
      {/* ////////// overview start //////// */}
      <div className="container-fluid text-dark mx-auto ">
        <div className="row my-5">
          <div className="col-1"></div>
          <div className="col-lg-6">
            <h1 className="fw-normal">Overview</h1>
            <p className="fs-5">
              Governments around the world face many complex issues and are constantly challenged to deliver public services in the most efficient way. We, at IClimbs, leverage our myriad of experiences to solve important problems faced by the society. We have worked extensively with the Indian Government (at both center and state levels) and foreign government, and have managed large programmes involving IT vendor selection, transition management and running applications that requires multi-dimensional competence right from business process engineering, application development and assessment to security / enterprise risk assessment, and cutting edge consulting in the area of emerging technologies like blockchain and IoT. We have rich domain experience in areas like income Tax, indirect taxes, logistics, telecom, banking, health, education, public awareness campaigns, skill development, etc.
            </p>
          </div>
          <GCTRightSideBar />
        </div>
      </div>
      {/* /////////////// overview end ///// */}
      <div className="container-fluid bg-primary text-white mx-auto pb-5">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center py-3" data-aos="zoom-in" data-aos-duration="1000">
              Management consulting & Government Advisory
            </h1>
          </div>
        </div>
        <div className="row my-5 text-white">
          <div className="col-1"></div>
          <div className="col-lg-6">
            <h1 className="fw-normal">How iClimbs India Can Help</h1>
            <p className="font-weight-bold">
              At iClimbs India, our team of advisory experts assist and guide
              organisations on:
            </p>
            <ul className="p-0 m-0 HowGCT-White">
              <li>Digital/ IT Strategy & Transformation</li>
              <li>Program & Project Management</li>
              <li>Smart City Solutions Consulting</li>
              <li>Process Design, Automation and Transformation</li>
              <li>
                Impact Assessment, Feasibility Study, Public Private Partnership
              </li>
              <li>
                Policy Advisory & SOP Preparation Services – CSR Policy,
                Procurement Policy
              </li>
              <li>
                e-Governance Consultancy (BPR, DPR, RFP, Bid Process,
                Transaction Advisory)
              </li>
            </ul>
          </div>
          <div class="col-lg-5 pt-5 overflow-hidden" data-aos="fade-right" data-aos-duration="1000">
            <img src="./images/Consulting_advi-gov.webp" alt="img-fluid hover" style={{ aspectRatio: 2 / 1, objectFit: "contain", width: "100%" }} />
          </div>
        </div>
      </div>
      <div className="conatiner-fluid py-5">
        <div className="row mx-0">
          <div className="col-lg-12">
            <div className="container">
              <div className="row pt-5 pb-5 text-dark">
                <div className="col-lg-6 pt-3" data-aos="fade-right" data-aos-duration="1000">
                  <img src="./img/ourclient/consulting.png" className="img-fluid" alt="consulting" />
                </div>
                <div className="col-lg-6">
                  <h2 className="text-primary">Consulting And Transaction Advisory</h2>
                  <p className="fs-5 text-dark">
                    In the realm of both government and the private sector, our
                    technology and data consulting services go beyond conventional
                    guidance. We work in close partnership with your team to
                    understand the specific challenges and objectives unique to your
                    domain. Equipping you with the necessary tools, we enable you to
                    optimize the value of your IT investments by implementing
                    state-of-the-art technologies and crafting data-driven strategies
                    that foster innovation and growth.
                    <br />
                    <Link to="/consulting">
                      <div className="btn btn-lg btn-primary mt-3 fs-6 rounded-0">
                        Learn More
                      </div>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ///////Insight////////// */}
      {/* <div className="container my-5">
        <div className="row">
          <div className="col-lg-5 mx-auto">
            <img
              className="w-100"
              src="./images/Insights.jpg" alt="" />
          </div>
          <div className="col-lg-6 mx-2 my-5 m-auto ms-auto">
            <h1 className="text-dark">Related Insights</h1>
            <p className="text-dark my-4">
              Explore our recent thought leadership for updates and expert views
            </p>
            <div className="btn btn-lg btn-primary fs-6 rounded-0">
              Learn More
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default GovConsltingTranjAdv;