import { Link } from "react-router-dom";
import './slider2.css'

function Slider2() {
    return(
            // <!-- Carousel Start -->
            <div className="container-fluid carousel px-0">
              <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
                <ol className="carousel-indicators">
                  <li data-bs-target="#carouselId" data-bs-slide-to="0" className="active" aria-current="true"
                    aria-label="First slide"></li>
                  <li data-bs-target="#carouselId" data-bs-slide-to="1" aria-label="First slide"></li>
                  <li data-bs-target="#carouselId" data-bs-slide-to="2" aria-label="Second slide"></li>
                  <li data-bs-target="#carouselId" data-bs-slide-to="3" aria-label="Third slide"></li>
                  <li data-bs-target="#carouselId" data-bs-slide-to="4" aria-label="Fourth slide"></li>
                  <li data-bs-target="#carouselId" data-bs-slide-to="5" aria-label="Five slide"></li>
                </ol>
                <div className="carousel-inner" role="listbox">
                  <div className="carousel-item active">
                    <img src="./img/slider/iot-banner.jpg" style={{ width: '100%', aspectRatio: '19/6', objectFit: 'cover' }} className="img-fluid w-100 set-slider-img" alt="Second slide" />
                    <div className="carousel-caption text-primary">
                      <div className="container carousel-content">
                        <h1 className="display-5 mb-0 mb-md-2 mt-sm-1 animated slideInDown">
                          IOT Tracking System
                        </h1>
                        <h5 className="fw-light mb-md-5 mb-3 animated slideInDown" style={{ lineHeight: '150%' }}>
                          Expansion of IoT applications across various industries like <br className="d-md-block d-none" />
                          Logistics, Transportation, and Manufacturing
                        </h5>
                        <a href="" className="box-button text-white px-5 py-3  text-center rounded-3">
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src="./img/slider/position.png" style={{ width: '100%', aspectRatio: '19/6', objectFit: 'cover' }} className="img-fluid w-100 set-slider-img" alt="First slide" />
                    <div className="carousel-caption text-primary">
                      <div className="container carousel-content">
                        <h1 className="display-5 mb-0 mb-md-4 mt-sm-1 animated slideInDown">
                          I Climb has been
                        </h1>
                        <h5 className="fw-light mb-5 animated slideInDown" style={{ lineHeight: '150%' }}>
                          Positioned as a visionary in the 2024 for <br className="d-md-block d-none" /> 
                          Public Cloud IT
                          Transformation Services
                        </h5>
                        <a href="" className="box-button text-white px-5 py-3 text-center rounded-3">
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src="./img/slider/banking-and-financial-slide.jpg" style={{ width: '100%', aspectRatio: '19/6', objectFit: 'cover' }} className="img-fluid w-100 set-slider-img" alt="First slide" />
                    <div className="carousel-caption">
                      <div className="container carousel-content">
                        <h1 className="text-primary display-5 mb-0 mb-md-4 mt-sm-0 animated slideInDown">
                          Financial and Banking
                        </h1>
                        <h5 className=" text-primary mb-md-5 mb-3 animated slideInDown" style={{ lineHeight: '150%' }}>
                          "Empowering Your Financial Future, Secure your wealth, <br className="d-md-block d-none" />
                          bank
                          on our expertise, prosperity awaits.
                        </h5>
                        <Link to="./financial-banking-service" className="box-button text-white px-5 py-3 text-center rounded-3" id="">
                          Learn More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src="./img/slider/IT-Recruitment-Staffing-Solutions.jpg" style={{ width: '100%', aspectRatio: '19/6', objectFit: 'cover' }} className="img-fluid w-100 set-slider-img" alt="Second slide" />
                    <div className="carousel-caption">
                      <div className="container carousel-content">
                        <h1 className="text-primary display-5 mb-0 mb-md-4 mt-sm-0 animated slideInDown">
                          IT Recruitment &
                           Staffing Solutions
                        </h1>
        
                        <h5 className=" text-primary fw-light mb-md-5 mb-3 animated slideInDown" style={{ lineHeight: '150%' }}>
                          "Empowering Futures, Connecting Talent <br className="d-md-block d-none" />
                          Your IT Recruitment & Staffing Solutions Partner"
                        </h5>
                        <Link to="/it-recruitment-staffing-service" className="box-button text-white px-5 py-3 text-center rounded-3">
                          Learn More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src="./img/slider/Healthcare5.jpg" style={{ width: '100%', aspectRatio: '19/6', objectFit: 'cover' }} className="img-fluid w-100 set-slider-img" alt="Second slide" />
                    <div className="carousel-caption">
                      <div className="container carousel-content">
                        <h1 className="text-primary display-5 mb-0 mb-md-4 mt-sm-0 animated slideInDown">
                          Healthcare
                        </h1>
                        <h5 className=" text-primary fw-light mb-md-5 mb-3 animated slideInDown" style={{ lineHeight: '150%' }}>
                          "Empowering Health through Excellence 
                          Join us<br className="d-md-block d-none" /> 
                          on the
                          Healthcare Journey." begins here
                        </h5>
                        <Link to="/life-science" className="box-button text-white px-5 py-3 text-center rounded-3" id="">
                          Learn More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src="./img/slider/Education.jpg 2.jpg" style={{ width: '100%', aspectRatio: '19/6', objectFit: 'cover' }} className="img-fluid w-100 set-slider-img" alt="Second slide" />
                    <div className="carousel-caption">
                      <div className="container carousel-content">
                        <h1 className="text-primary display-5 mb-0 mb-md-4 mt-sm-0 animated slideInDown">
                          Education <br />
                          Manangement System
                        </h1>
                        <h5 className=" text-primary fw-light mb-md-5 mb-3 animated slideInDown" style={{ lineHeight: '150%' }}>
                          Focus on Data Analytics for student performance assessment <br />
                          and personalized Learning Experience.
                        </h5>
                        <a href="#" className="box-button text-white px-5 py-3 text-center rounded-3" id="">
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <button className="carousel-control-prev btn btn-primary border border-2 border-start-0 border-primary"
                  type="button" data-bs-target="#carouselId" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next btn btn-primary border border-2 border-end-0 border-primary" type="button"
                  data-bs-target="#carouselId" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            // <!-- Carousel End -->
          )

}

export default Slider2;