import React from "react";
import './infrastructure-state.css'
import GetYourQuote from "../include/get-your-qutes";
import AsideNavMenu from "../include/aside-nav-menu";
import { Link } from "react-router-dom";
const FaclityManagement = () => {
  return (
    <>
      <div class="ttm-page-title-row">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="title-box ttm-textcolor-white">
                <div class="page-title-heading">
                  <h1 class="title text-white">Facility Management Service</h1>
                </div>
                <div class="breadcrumb-wrapper text-white">
                  <span>
                    <Link title="Homepage" to="/home">
                      <i class="fa-solid fa-house"></i>&nbsp;&nbsp;Home
                    </Link>
                  </span>
                  <span class="ttm-bread-sep">&nbsp; | &nbsp;</span>
                  <span> Facility Management </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div className="container">
          <div className="row mt-5">
            <div class="col-lg-9">
              <div class="container-fluid text-dark">
                <div class="row">
                  <div class="col-md-12">
                    <img class="img-fluid" src="./img/Facility-Management-Services.jpg" alt="Facility Management Services" />
                  </div>
                </div>
                <div className="row py-5">
                  <div class="col-md-12 mb-30 wow fadeInUp reveal" data-wow-delay=".3s">
                    <h1>Facility Management Service</h1>
                    <p className="fs-5">
                      Every organization or an apartment in society must have facility management staff. It serves the residents
                      with its services and keeps the environment clean. An apartment or an organization with a good and honest
                      housekeeping staff service will help the people to live in harmony and a clean environment.
                    </p>
                    <p className="fs-5">
                      Building support services handle the building maintenance task. They maintain the common area of an organization
                      or an apartment and perform a wide range of tasks such as cleaning the common area, wiping down the stairs,
                      or dusting. Choosing facility management as a career is very challenging. As now more and more people
                      are aware of the cleanness and maintenance of their belongings, the demand for having a housekeeping
                      management service or a building support service is very valuable. There is minimum education required
                      for enrolling in facility management. Proper guidance from our institution and training will make the
                      candidate eligible for any housekeeping job.
                    </p>
                    <p className="fs-5">
                      We provide you with the best facility management programs that will help you in getting jobs in housekeeping
                      management services or facility maintenance staff service. We not only train you with the best programs
                      but also help you to increase the conversation technique that will help you in getting jobs after completing
                      our certification programs.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pb-15 wow fadeInUp reveal" data-wow-delay=".3s">
                    <h3>Why Should you as an Aspiring Candidate Select Us?</h3>
                    <p className="fs-5">The candidate who wants to enroll themselves as facility management can admission themselves in our esteemed
                      institution. We provide them with the best facility to skilled them in their respective jobs. We give
                      our candidates the best facility management services so that they can easily get to work after completing
                      our certification course. </p>
                    <p className="fs-5">
                      <strong>We equipped our candidates with various initiatives such as:</strong>
                    </p>
                    <ul className="text-li">
                      <li>
                        <strong>A Proper Waste Segregation Technique:</strong> It is a very important technical skill that every candidate
                        should learn. Housekeeping service is mainly applied in the apartment or organization where there is
                        a need of segregating waste according to biodegradable and non-biodegradable. Therefore, proper knowledge
                        about those things is important before collecting waste from the respective places.
                      </li>
                      <li>They are fully trained in maintaining the apartments so that they can stop any accident beforehand.</li>
                      <li>
                        <strong>Proper Training is an Awareness Program:</strong> The candidates who are enrolled in our institution will
                        get the proper training to prevent accidents and fire in any organization. They are also trained in
                        creating a better hygienic condition which led to improving the health condition.
                      </li>
                      <li>Fire Alarm and Fire Fighting Systems</li>
                      <li>
                        <strong>Time Management:</strong> Proper time management is needed while working under Facility Management. Our
                        candidates are trained in managing time which reduces the operation and handling time of any work they
                        are doing.
                      </li>
                    </ul>
                    <p className="fs-5">
                      Above all, we provide 100% placement of our candidates throughout the country. We, as professional facility
                      management services, provide our candidates with intense proficiency in their language and give them
                      proper training on how to behave with their recruiters. Candidates who are willing to join our institution
                      can easily contact our official website.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pb-15 wow fadeInUp reveal" data-wow-delay=".3s">
                    <h3>What do we provide to our clients?</h3>
                    <p className="fs-5">
                      Adopting a good facility management service will reduce the capital cost of an apartment or organization.
                      It involves the changing of results and reduces emission operational cost waste and increases the productivity
                      of any organization or workplace. Our Facility management staff services offer a candidate who can prevent
                      accidents and fires in an apartment. It also helps in reducing disposable cost and operation and handling
                      time of any apartment.
                    </p>
                    <p className="fs-5">
                      We offer you the best candidates for your apartment or organization. We are dealing with disciplined candidates
                      from Facility Management services that are equipped with knowledgeable and experienced corresponding
                      fields.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pb-15 wow fadeInUp reveal" data-wow-delay=".3s">
                    <h3>Why do clients choose us?</h3>
                    <ul className="text-li">
                      <li>
                        Our housekeeping staff service and building staff service are properly groomed and will be dressed with
                        a pleasant personality and cooperativeness.
                      </li>
                      <li>
                        As our candidates are placed throughout the country, we present our staff well versed in a different
                        language.
                      </li>
                      <li>
                        All staff are supervised regularly and maintained a timesheet to ensure that they meet the cleaning standard
                        and guidelines of the apartment.
                      </li>
                      <li>
                        We provide valid ID cards to our candidates so that you can easily hire them without thinking of their
                        identity.
                      </li>
                      <li>Our building support services were well prepared in handling any accidents that occurred during their
                        services.
                      </li>
                      <li>
                        We offer a team of candidates who are skilled specialists in cleaning terraces, housing society offices,
                        gym maintenance, kitchen stewarding, exterior window cleaning, laundry service, horticulture, marble
                        polishing, and many more.
                      </li>
                      <li>They are also trained in cleaning or undertaking minor repair in any organization or apartment.</li>
                    </ul>
                    <p className="fs-5">
                      To conclude, we provide you with the best Facility Management services that concentrate on managing all
                      housekeeping services. We are always focused on placing well-trained and mannerly candidates into your
                      sector. We also promise you to serve at our best with exceptional facilities. To know more about our
                      services, just click on our official website and we are happy to give our service in the best possible
                      way.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h1>Service includes</h1>
                    <ul class="nav widget-menu">
                      <li class="nav-item border">
                        <Link class="nav-link" to="#">Housekeeping Staff</Link>
                      </li>
                      <li class="nav-item border">
                        <Link class="nav-link" to="#">Housekeeping Management</Link>
                      </li>
                      <li class="nav-item border">
                        <Link class="nav-link" to="#">Facility Maintenance Staff</Link>
                      </li>
                      <li class="nav-item border">
                        <Link class="nav-link" to="#">Building Support</Link>
                      </li>
                      <li class="nav-item border">
                        <Link class="nav-link" to="#">Building Staff</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div className="row mb-5">
                <div className="col">
                  <AsideNavMenu />
                </div>
              </div>
              {/* <!-- form --> */}
              <div className="row">
                <div className="col">
                  <GetYourQuote />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default FaclityManagement;