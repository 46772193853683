const NoPage=()=>{
    return(
        <>
        <section>
            <h1>Page Not Found 404</h1>
        </section>
        </>
    )
}

export default NoPage;