import { Link } from "react-router-dom";

const LifeScience = () => {
    return (
        <>
            <div id="full_slider_1"
                class="avia-fullwidth-slider main_color avia-shadow avia-builder-el-0 el_before_av_slideshow_full_custom_mobile avia-builder-el-first container-fluid no-padding">
                <div data-size="no scaling" data-lightbox_size="large" data-animation="slide" data-ids="138879"
                    data-video_counter="0" data-autoplay="false" data-bg_slider="false" data-slide_height=""
                    data-handle="av_slideshow_full_custom" data-interval="5000" data-class="" data-css_id=""
                    data-scroll_down="" data-control_layout="av-control-default" data-custom_markup="" data-perma_caption=""
                    data-autoplay_stopper="" data-image_attachment="" data-alttext="" data-custom_id="" data-src=""
                    data-position="top left" data-repeat="no-repeat" data-attach="scroll" data-stretch=""
                    class="avia-slideshow avia-slideshow-1 av-control-default av-default-height-applied avia-slideshow-no-scaling av_slideshow_full_custom avia-slide-slider">
                    <div class="carousel-inner">
                        <div class="carousel-item active position-relative">
                            <img src="./images/life-science-banner.jpg" class="d-block w-100 img-fluid" alt="Life science" style={{width: "100%",aspectRatio: 16/5, objectFit: "cover",position: "center"}}/>
                                <div class="position-absolute col-lg-4 col-md-6 col-8" style={{left: "10%",top: "50%",zIndex: 50,transform:"translateY(-50%)"}}>
                                    <h2 class="text-white">Enable integrated patient engagements with targeted digital experiences</h2>
                                    <h3 class="text-warning mt-5">Life Sciences
                                    </h3>
                                    <p class="text-white">We help you to enable holistic patient experiences, accelerate clinical trials data analysis for scientific research purposes, understand the gap between insights and action and reimagine care delivery models with connected care solutions.</p>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="breadcrumb" class="container-fluid  bg-light py-2">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <Link to="/index" title="iClimb" class="text-primary" rel="home">Home</Link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <Link to="#" title="Services">Services</Link>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">Life Science
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- What make Iclimbs Different Start  --> */}
            <div class="container">
                <div class="row ">
                    <h1 class="text-center text-primary">What makes iClimbs different
                    </h1>
                </div>
                <div class="row text-center">
                    <div class="col-lg-3 col-md-6 my-3" >
                        <img class="" src="images/Life-science-1.png" alt="Domain of Life Science" width="20%" />
                        <div class="card-body">
                            <h5 class="card-title mb-5" style={{color: "orangered",textAlign: "center"}}>Deep domain expertise
                            </h5>
                            <p class="card-text ">Our team has deep expertise in Life Sciences and Healthcare across a
                                multitude of challenges facing this industry.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 my-3" >
                        <img src="images/Life-science-2.png" alt="World-class Experience Design capabilities"
                            width="20%"/>
                            <div class="card-body">
                                <h5 class="card-title mb-4" style={{color: "orangered",textAlign: "center"}}>
                                    World-class Experience Design capabilities
                                </h5>
                                <p class="card-text ">Our Design Studio combines design thinking, data science and innovative
                                    technology to create patient-centric experiences that deliver also significant business
                                    impact
                                </p>
                            </div>
                    </div>
                    <div class="col-lg-3 col-md-6 my-3" >
                        <img src="images/Life-science-3.png" alt="Proprietary AI and Data platforms" width="20%"/>
                            <div class="card-body">
                                <h5 class="card-title mb-1" style={{color: "orangered",textAlign: "center"}}>Strong data and
                                    Proprietary AI and Data platforms
                                </h5>
                                <p class="card-text ">Our proprietary cloud native platforms use AI/ML models to accelerate
                                    problem discovery and evaluate and monitor recommended actions.
                                </p>
                            </div>
                    </div>
                    <div class="col-lg-3 col-md-6 my-3" >
                        <img class="" src="images/Life-science-4.png" alt="Proprietary AI and Data platforms" width="20%" />
                            <div class="card-body">
                                <h5 class="card-title" style={{color: "orangered",textAlign: "center"}}>Strong data and
                                    Strong product engineering and cloud enablement capabilities
                                </h5>
                                <p class="card-text ">We have strong product engineering and cloud enablement capabilities to
                                    speed up and scale launch of digital solutions of your customers.
                                </p>
                            </div>
                    </div>
                </div>
            </div>
            {/* <!-- What make Iclimbs Different End  --> */}
            <div class="container reveal">
                <div class="row mb-3">
                    <div class="col-md-12">
                        <h1 class="text-center text-dark">Our Practice Areas</h1>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col-md-4 col-sm-6 my-3">
                        <img src="./images/Life-science-5.png" alt="Patient Engagement" />
                            <h3 class="text-primary mb-3">Patient Engagement</h3>
                            <h6 class="mb-4">Empower integrated patient engagements</h6>
                            <a href="#PatientEngagement" class="bg-primary rounded p-3 text-light">
                                Learn More
                                <img src="./img/rthtyjr6j.png" alt="" />
                            </a>
                    </div>
                    <div class="col-md-4 col-sm-6 my-3">
                        <img src="./images/Life-science-6.png" alt="Insights to Action" />
                            <h3 class="text-primary mb-3">Insights to Action</h3>
                            <h6 class="mb-4">Solve problems with actionable insights</h6>
                            <a href="#insightsAction" class="bg-primary rounded p-3 text-light">
                                Learn More
                                <img src="./img/rthtyjr6j.png" alt="" />
                            </a>
                        </div>
                    <div class="col-md-4 col-sm-12 my-3">
                        <img src="./images/Life-science-7.png" alt="Connected Care" />
                            <h3 class="text-primary mb-3">Connected Care</h3>
                            <h6 class="mb-4">Reimagine care delivery models</h6>
                            <a href="#ConnectCare" class="bg-primary rounded p-3 text-light">
                                Learn More
                                <img src="./img/rthtyjr6j.png" alt="" />
                            </a>
                    </div>
                </div>
            </div>
            <div class="container reveal my-5 pt-5 text-dark" id="PatientEngagement">
                <div class="row my-2">
                    <div class="col-lg-6 col-md-12 pt-4 setImage text-center">
                        <img src="./images/Life-science-patient-engagement.jpg" class="img-fluid" alt="Patient Engagement" />
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="row" >
                            <div class="col-md-12">
                                <h1 class="text-primary">Patient Engagement​</h1>
                                <h6>Our Patient Experience solution brings together redesign combined with data-driven insights from existing service interactions to deliver targeted interventions. Our offerings include:</h6>
                                <ul class="text-li">
                                    <li><strong>Patient journey diagnostic—</strong> We identify elements of friction across the journey from initial diagnosis, symptoms assessment, condition management to care and assess opportunities for direct interventions by a pharmaceutical or medical device brand. Our deliverables include intent and persona research and multi-model touchpoint analysis.</li>
                                    <li><strong>Diagnostic— </strong>Our proprietary AI platform surfaces root causes for friction in current programs and channel such as co-pay, market access, other brand interventions. We deliver data science models for anomalies and recommended actions.</li>
                                    <li> <strong>Experience redesign and targeted interventions— </strong> Our Design Studio can redesign and deliver improved digital experiences. We can also track and measure uplift from targeted interventions using our AI platforms.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container my-5 pt-5 text-dark" id="insightsAction">
                <div class="row my-2">
                    <div class="col-lg-6 col-md-12 d-block d-lg-none setImage text-center">
                        <img src="./images/Life-Science-insights-to-actions.jpg" class="img-fluid" alt="Insights to Action" />
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <h1 class="text-primary">Insights to Action</h1>
                                <h6>
                                    Our problem solving framework, complemented with our AI platform capabilities, relevant industry domain expertise and data science models, help address challenges in specific areas such as:
                                </h6>
                                <ul class="text-li">
                                    <li><strong>Channel management insights—</strong> Our channel management insights driven by data science models (anomaly detection, real world simulation engine and recommendation engine) provide intelligent forecasting so that you can manage the inventory management processes without risking your reputation with partners and customers.</li>
                                    <li><strong>Multichannel marketing and campaign performance—</strong> Our domain expertise along with our predictive models will help you to reframe your decision making approaches to brand marketing and campaign management.</li>
                                    <li><strong>Payer analysis and contracting strategy optimization—</strong> We provide full lifecycle testing services, domain libraries, test data management and anonymization techniques for regulated environments.
                                    </li>
                                    <li><strong>Patient experience— </strong> We provide actionable insights for managing patient experiences across multiple touchpoints. Our AI platform generates granular level insights that will uplift patient experiences across their journey.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 d-none d-lg-block setImage text-center">
                        <img src="./images/Life-Science-insights-to-actions.jpg" class="img-fluid" alt="Insights to Action" />
                    </div>
                </div>
            </div>
            <div class="ccontainer-fluid-md my-5 pt-5 text-dark" id="ConnectCare">
                <div class="row my-2 me-0">
                    <div class="col-lg-6 col-md-12 setImage text-center">
                        <img src="./images/Life-Science-connected-care.jpg" class="img-fluid" alt="Connected Care" />
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="row" >
                            <div class="col-md-12">
                                <h1 class="text-primary">Connected Care</h1>
                                <h6>
                                    Our connected care solutions help the MedTech industry to improve health outcomes, lower cost of care and uplift experiences of patients and healthcare staff. We rapidly ideate and launch new digital solutions that holistically deliver value across a patient’s journey by building a closed loop and connected digital ecosystem.
                                </h6>
                                <ul class="text-li">
                                    <li><strong>Innovation as a service—</strong> We can help you with innovation from developing new use cases to care journey interventions. Our platforms allow rapid launch and test of new experiments. We also do a device impact assessment from security, endpoints to regulatory impact.</li>
                                    <li><strong>Digital product launch—</strong> Our product engineering expertise coupled with our deep cloud enablement capabilities will help you to launch digital solutions for your customers.</li>
                                    <li><strong>Closed loop analytics—</strong> We partner with you to derive value from a connected ecosystem by developing actionable recommendations for marketing, sales, R&D and patient care. Our solutions include care journey optimization models and next best actions using our proprietary AI platform.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default LifeScience;