/* globals $*/
import React from 'react'
import { useEffect } from 'react'
function Missionvision() {
    useEffect(() => {
        // AOS.init()
    }, [])

    return (
        <div className="container bg-primary rounded p-5 ">
            <h1 className='text-info text-center'>Mission & Vision</h1>
            <div className="row my-5" data-aos="fade-up" data-aos-duration="1000">
                <div className='col-lg-3 col-12 text-lg-start text-center my-4 my-lg-auto'>
                    <img src="./images/mission.png" className='mx-auto' alt="iClimbs Mission" style={{ width: '80%', aspectRatio: 1 / 1, objectFit: 'contain' }} />
                </div>
                {/* <div className='col-1 h-50 bg-white' style={{ width: '2px' }}></div> */}
                <div className='my-auto col-1 mt-lg-2 d-none d-lg-block' style={{ borderLeft: '2px solid white' }}>
                    <div className='col-12 text-center text-info font-style h5'>M</div>
                    <div className='col-12 text-center text-info font-style h5'>I</div>
                    <div className='col-12 text-center text-info font-style h5'>S</div>
                    <div className='col-12 text-center text-info font-style h5'>S</div>
                    <div className='col-12 text-center text-info font-style h5'>I</div>
                    <div className='col-12 text-center text-info font-style h5'>O</div>
                    <div className='col-12 text-center text-info font-style h5'>N</div>
                </div>
                <div className='col-lg pe-lg-5 p-0'>
                    <p className='font-style text-white' style={{ lineHeight: '220%' }}>At I-CLIMB, our mission is to    empower businesses and organizations
                        through innovative and reliable IT solutions. We strive to be a trusted
                        par tner, delivering cutting-edge technology services that enhance
                        efficiency, foster growth, and adapt to the dynamic needs of the
                        digital landscape. By combining technical exper tise with a clientcentric approach, we aim to create sustainable and impactful
                        solutions that propel our clients towards success in the everevolving world of information technology.</p>
                </div>
            </div>

            {/* //////////Vision start/////////////// */}
            {/* To desktop vision  */}
            <div className='col-lg-3 col-12 text-center my-4 my-lg-auto d-block d-lg-none'>
                <img src="./images/vision.png" className='mx-auto' alt="iClimbs Vision" style={{ width: '80%', aspectRatio: 1 / 1, objectFit: 'contain' }} />
            </div>
            <div className="row" data-aos="fade-up" data-aos-duration="1000" style={{ marginTop: '5rem' }}>
                <div className='col-lg pe-lg-5 p-0'>
                    <p className='font-style text-white' style={{ lineHeight: '220%' }}>I-CLIMB envisions a future where businesses seamlessly integrate
                        and leverage IT solutions to achieve their full potential. Our vision is
                        to be at the forefront of technological advancement, driving digital
                        transformation for our clients. We aspire to be recognized globally
                        as a leading provider of comprehensive IT solutions, renowned for
                        our commitment to excellence, innovation, and customer
                        satisfaction.</p>
                </div>
                {/* ////////////Vision text in vertical ///////////////// */}
                <div className='my-auto col-1 mt-lg-2 d-none d-lg-block' style={{ borderRight: '2px solid white' }}>
                    <div className='col-12 text-center text-info font-style h5'>V</div>
                    <div className='col-12 text-center text-info font-style h5'>I</div>
                    <div className='col-12 text-center text-info font-style h5'>S</div>
                    <div className='col-12 text-center text-info font-style h5'>I</div>
                    <div className='col-12 text-center text-info font-style h5'>O</div>
                    <div className='col-12 text-center text-info font-style h5'>N</div>
                </div>

                {/* To desktop vision  */}
                <div className='col-lg-3 col-12 text-center my-4 my-lg-auto d-none d-lg-block'>
                    <img src="./images/vision.png" className='mx-auto' alt="iClimbs Vision" style={{ width: '80%', aspectRatio: 1 / 1, objectFit: 'contain' }} />
                </div>
                {/* <div className='col-1 h-50 bg-white' style={{ width: '2px' }}></div> */}

            </div>
        </div>
    )
}

export default Missionvision