/* global AOS */
import { useEffect } from 'react';
import './Webdesign.css'

function Webdesign() {
    useEffect(() => {
        AOS.init();
    })
    return (

        <div id="wrap-all">
            <div className="banner-img">
                <img src="./img/web-designing-mission-banner.jpg" alt="" />
            </div>
            <div className='container'>
                <div className="row">
                    <div className="about-design w-100 col-12">
                        <div className="content">
                            <h1 className='text-primary py-3'>WEBSITE DESIGINING AGENCEY</h1>
                        </div>
                        <p className='fs-5 text-dark'>iClimbs is the leading Website Designing Agency Company in Delhi/NCR, consistently ranked ahead in specialized high-end services in the spectrum of web, new media and related business.</p>
                        <p className='fs-5 text-dark'>The company is reckoned as one of the most trusted web designing and development companies, owing to its proven expertise over the in-depth understanding of wide arena of digital marketing services. We have an excellent track record of over 10 years, since the time iClimbs was incepted till present day in serving our esteemed clients with the branding, online promotion, and website development for their important products and services.
                            Our visionary endeavor is to flip our client's businesses into profit centers rather than merely being the profit centers.</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid bg-primary py-3">
                <div className="row">
                    <div className="col-md-12">
                        <div className="container reveal">
                            <div className="row">
                                <div className="col-md-12 text-white">
                                    <div className="about-design w-100 col-12">
                                        <div className="content text-dark fs-5">
                                            <h1 className='py-3 text-white'>WEBSITE DESIGINING</h1>

                                        </div>
                                        <p className='fs-5'>A key towards successful branding of any business entity lies in the standard, high-performance website. The corporate giants are always looking out for website designing agency for core branding of their products, meeting high end business standards.</p>
                                        <span className='fs-5'>"We are the right partners for all your website designing and development needs, with an industry specific approach and creative zeal to designing something, unique, special yet worth noticing!</span>
                                        <p className='fs-5'>Our designs are comprehensive, highly scalable and with well thought out graphics and layouts. Our experience in core designing has been leveraged across the industries, as a company that delivers user friendly, hand-crafted and innovative solutions; surprisingly in a cost-effective manner!
                                            For us website designing is a specialty of molding your imagination into a creative master piece, serving as a real platform to present your business goals to the clients.
                                            We have mastered in.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <div className="col-md-12">
                        <h1 className='text-primary text-center py-5' data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine">HTML WEBSITE FOR STARTUPS</h1>
                        <p className='fs-5 text-dark'>Variety of websites have been presented by us to cater our client's requirements. Our HTML website designing concepts are empowered to convert your visitors into customers. We have a large database of happy clientele that are generating good business deals. The major benefits of the concept can be pointed as:.</p>
                        <div className="lines">
                            <p>1)</p>
                            <p className="line">Full customization is possible with zero limitation</p>
                        </div>
                        <div className="lines">
                            <p>2)</p>
                            <p className="line">Maximized preference for Google and search engine optimization</p>
                        </div>
                        <div className="lines">
                            <p>3)</p>
                            <p className="line">Easy maintenance</p>
                        </div>
                        <div className="lines">
                            <p>4)</p>
                            <p className="line">Minimum hosting charges</p>
                        </div>

                        {/* <ol className='text-li'>
                            <li>Full customization is possible with zero limitation</li>
                            <li>Maximized preference for Google and search engine optimization</li>
                            <li>Easy maintenance</li>
                            <li>Minimum hosting charges</li>
                        </ol> */}
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className='text-primary text-center py-3 reveal'>
                            WORDPRESS WEBSITE DESIGNING
                        </h1>
                        <p className='fs-5 text-dark'>Wordpress website designing can be the ideal choice, if you wish to frequently update or keep posting latest news on your website. The kind of open source platform is beneficial for offering competitive advantage with multiple advanced features and benefits. These kinds of websites are our forte and are currently serving many national as well as international clients for the same.</p>
                        <ol className='text-li'>
                            <li>Cost-effective solution for all your market needs</li>
                            <li>Easy to edit or add on any new posted elements, without expertise</li>
                            <li>Attractive designs and advanced features possible helpful in easy uplifting</li>
                            <li>Hassle-free installation of any advanced features through plugins.</li>
                            <li>Easy maintenance.</li>
                        </ol>
                        <h6 className='text-primary' data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            Our website designing process has to pass through number of stages, in order to really take a leap ahead, and can be noted as:
                        </h6>
                    <div data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div class="lines">
                            <p>1)</p>
                            <p class="line"> Strategizing and discovery</p>
                        </div>
                        <div class="lines">
                            <p>2)</p>
                            <p class="line"> Information architecture</p>
                        </div>
                        <div class="lines">
                            <p>3)</p>
                            <p class="line">Creative consultation</p>
                        </div>
                        <div class="lines">
                            <p>4)</p>
                            <p class="line">Coding and development</p>
                        </div>
                        <div class="lines">
                            <p>5)</p>
                            <p class="line">Quality assurance</p>
                        </div>
                        <div class="lines">
                            <p>6)</p>
                            <p class="line">Launch and optimization</p>
                        </div>
                    </div>
                        {/* <ol className='text-li' data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <li>Strategizing and discovery</li>
                            <li>Information architecture</li>
                            <li>Creative consultation</li>
                            <li>Coding and development</li>
                            <li>Quality assurance</li>
                            <li>Launch and optimization</li>
                        </ol> */}
                    </div>
                </div>
            </div>
            <div class="ui-ux" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                <img src="./img//ui-ux-design.png" alt="" />
            </div>
            <div class=" conatiner-fluid">
                <div className="row p-0 m-0">
                    <div class="faculty d-none d-lg-inline-block col-lg-8 px-0 m-auto">
                        <h1 className='text-primary'>WHY PEOPLE CHOOSE TO US ?</h1>
                        <p className='fs-5 text-dark'>Custom designed websites with strategic insights to generate greater brand engagement, higher conversions and measurable results.</p>
                        <div class="lines">
                            <p>1)</p>
                            <p class="line">Successfully designed 3500+ websites</p>
                        </div>
                        <div class="lines">
                            <p>2)</p>
                            <p class="line">Web 2.0 based designing and development</p>
                        </div>
                        <div class="lines">
                            <p>3)</p>
                            <p class="line">Responsive Websites in all devices</p>
                        </div>
                        <div class="lines">
                            <p>4)</p>
                            <p class="line"> Fast loading websites</p>
                        </div>
                        <div class="lines">
                            <p>5)</p>
                            <p class="line"> All websites W3C Validated</p>
                        </div>
                        <div class="lines">
                            <p>6)</p>
                            <p class="line">All websites cross browser compatible</p>
                        </div>
                        <div class="lines">
                            <p>6)</p>
                            <p class="line">SEO Semantic and clean coding</p>
                        </div>
                        <div class="lines">
                            <p>6)</p>
                            <p class="line"> Separate Quality Department</p>
                        </div>
                        <div class="lines">
                            <p>6)</p>
                            <p class="line">Professional, appealing and relevant websites</p>
                        </div>
                    </div>

                    {/* for mobile */}

                    <div class="faculty d-lg-none w-100 col-lg-6 m-auto">
                        <h1 className='text-primary'>WHY PEOPLE CHOOSE TO US ?</h1>
                        <p>Custom designed websites with strategic insights to generate greater brand engagement, higher conversions and measurable results.</p>
                        <div class="lines">
                            <p>1)</p>
                            <p class="line">Successfully designed 3500+ websites</p>
                        </div>
                        <div class="lines">
                            <p>2)</p>
                            <p class="line">Web 2.0 based designing and development</p>
                        </div>
                        <div class="lines">
                            <p>3)</p>
                            <p class="line">Responsive Websites in all devices</p>
                        </div>
                        <div class="lines">
                            <p>4)</p>
                            <p class="line"> Fast loading websites</p>
                        </div>
                        <div class="lines">
                            <p>5)</p>
                            <p class="line"> All websites W3C Validated</p>
                        </div>
                        <div class="lines">
                            <p>6)</p>
                            <p class="line">All websites cross browser compatible</p>
                        </div>
                        <div class="lines">
                            <p>6)</p>
                            <p class="line">SEO Semantic and clean coding</p>
                        </div>
                        <div class="lines">
                            <p>6)</p>
                            <p class="line"> Separate Quality Department</p>
                        </div>
                        <div class="lines">
                            <p>6)</p>
                            <p class="line">Professional, appealing and relevant websites</p>
                        </div>
                    </div>
                    {/* to desktop  */}
                    <div class="imges col-4 text-center m-auto m-0 d-lg-inline-block d-none">
                        <img src="./img/web-designing-banner-chandigarh.png" alt="" />
                    </div>
                    {/* to mobilew  */}
                    <div class="imges col-12 text-center m-auto m-0 d-lg-none">
                        <img src="./img/web-designing-banner-chandigarh.png" alt="" />
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Webdesign;