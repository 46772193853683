import React from "react";
import "./gct.css";
import GCTRightSideBar from "./GCTRightSideBar";

const DataAnalyticsEmergingTechnologies = () => {
    return (
        <>
            {/* /////////////////Banner Data Analysis//////////////////// */}
            <div className="container-fluid data-analysis-imghere position-relative">
                <div className="position-absolute"
                    style={{ top: "50%", transform: "translateY(-50%)", left: "8%" }}
                >
                    <h4 className="text-white">Data Analytics & Emerging Technologies</h4>
                    <p className="text-white my-4 pe-5 p-md-0">
                        Simplifying digital management with data-driven insights
                    </p>
                    <div className="btn btn-primary border-0 px-4 py-3 fw-bold rounded-0 w-50">
                        Learn More
                    </div>
                </div>
            </div>

            {/* //////////////////Banner End///////////////////// */}

            <div className="container-fluid text-dark mx-auto ">
                <div className="row my-5">
                    <div className="col-1"></div>
                    <div className="col-lg-6">
                        <h1 className="fw-normal pt-3">Overview</h1>
                        <p className="fs-5">
                            Data plays a critical role in shaping the future and getting insights into actions. At iClimbs India, our data analytics solutions are omni-platform, and our data experts deliver solutions both on open source and license-based products.
                        </p>
                    </div>
                    <GCTRightSideBar />
                </div>
            </div>
            {/* Data Anaylsis Emerging start  */}
            <div className="container my-5">
                <div className="row py-3">
                    <div className="col-lg-12">
                        <h1 className="fw-normal text-primary" data-aos="zoom-in" data-aos-duration="1000">
                            How iClimbs India Can Help
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 mx-2 my-5 m-auto ms-auto">
                        <h1 className="text-dark" data-aos="fade-up" data-aos-duration="1000">Related Insights</h1>
                        <ul className="p-0 m-0 HowGCT" data-aos="fade-right" data-aos-duration="1000">
                            <li> Data Management & Governance</li>
                            <li>Big Data Analytics</li>
                            <li>Predictive Analytics</li>
                            <li>Artificial Intelligence and Machine Learning (AI & ML)</li>
                            <li>Blockchain</li>
                            <li>IoT and RFID Solutions  </li>
                            <li>Web Intelligence     </li>
                            <li>Robotic Process Automation (RPA)</li>
                            <li>Natural Language Processing (NLP)</li>
                            <li>Chatbots</li>
                        </ul>
                        <div className="btn btn-lg btn-primary fs-6 rounded-0">
                            Learn More
                        </div>
                    </div>
                    <div className="col-lg-5 mx-auto pt-5 mt-3">
                        <img className="w-100" src="./images/data-analysis.jpg" alt="" />
                    </div>
                </div>
            </div>

            {/* ///////Insight////////// */}

            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-5 mx-auto">
                        <img
                            className="w-100"
                            src="./images/Insights.jpg" alt="" />
                    </div>
                    <div className="col-lg-6 mx-2 my-5 m-auto ms-auto">
                        <h1 className="text-dark">Related Insights</h1>
                        <p className="text-dark my-4">
                            Explore our recent thought leadership for updates and expert views
                        </p>
                        <div className="btn btn-lg btn-primary fs-6 rounded-0">
                            Learn More
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DataAnalyticsEmergingTechnologies;