/* global $, AOS */
import { useEffect } from "react";
import './erpSolution.css';
const ErpSolution = () => {
  useEffect(() => {
    AOS.init();
    let animateText = document.querySelector("#animateText");
    let stri = ["Efficient", "Dynamic", "Powerful"];
    let res = "";
    let a = 0;
    let index = 0;
    let f = 0;
    function animate() {
      if (index == stri[a].length) {
        fun();
      }
      fun2();
    }
    async function fun() {
      res = stri[a];
      ++a;
      index = 0;
      res = "";
      if (a == stri.length) {
        a = 0;
      }
    }
    async function fun2() {
      res += stri[a][index];
      animateText.innerText = res;
      index++;
    }
    let demo = setInterval(animate, 400);
  }, []);
  return (
    <>
      <div class="container row mx-auto my-5">
        <div class="col-md-6">
          <h1 class="h1 fw-bold text-primary">
            Low-code-based ERP alternative:
            <span
              class="bg-warning text-dark"
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              id="animateText"
            ></span>
          </h1>
          <p class="my-4">
          Build, manage, automate, and integrate custom ERP solutions that reflect your organization's unique business processes, while staying in control of your spend, with zero hidden costs.
          </p>
          <div class="btn btn-danger p-3">BOOK A FREE DEMO</div>
          <ul class="p-0 my-4">
            <li class="my-2">✅ Highly customizable</li>
            <li class="my-2">✅ Easy integrations with 100+ applications</li>
            <li class="my-2">
              ✅ Multi-device capability - Web and mobile apps
            </li>
            <li class="my-2">✅ Cloud-based hosting options</li>
            <li class="my-2">
              ✅ Enterprise-grade security - ISO/IEC 27001, SOC 2 TYPE II, GDPR
            </li>
            <li class="my-2">✅ Simplified user governance</li>
          </ul>
        </div>

        {/* <!-- ///////////////////////// --> */}
        <div class="col-md-4 ms-auto my-4 my-md-auto">
          <img
            src="https://i.pinimg.com/474x/a6/ba/40/a6ba4097decff11819c5235628194eaf.jpg"
            alt=""
            style={{
              width: "100%",
              aspectRatio: 9 / 10,
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />
        </div>
      </div>
      {/* <!-- /////////////////////////////////////// --> */}
      <div class="container-fluid bg-dark py-5">
        <div class="col-lg-6 px-4 px-md-0 mx-auto">
          <h1 class="text-light text-center" style={{ wordWrap: "no-wrap" }}>
            iClimbs Creator: Sealing the gaps in traditional ERP
          </h1>
        </div>
        <table class="col-8 mx-auto my-4" style={{ borderRadius: "50px" }}>
          <th class="p-3 fw-bold h5 text-light rounded-3 text-start border border-white">
            Features
          </th>
          <th
            class="p-3 fw-bold h5 text-light text-center rounded border border-white"
            style={{ backgroundColor: "rgb(46, 46, 46)" }}
          >
            iClimbs Creator
          </th>
          <th class="p-3 fw-bold h5 text-light rounded text-center border border-white">
            Other ERP solutions
          </th>
          <tr className="fs-5">
            <td class="p-3 text-light text-start border border-white">
              Build your own application
            </td>
            <td
              class="p-3 text-light text-center border border-white"
              style={{ backgroundColor: "rgb(46, 46, 46)" }}
            >
              ✅
            </td>
            <td class="p-3 text-light text-center border border-white">❌</td>
          </tr>
          <tr className="fs-5">
            <td class="p-3 text-light text-start border border-white">
              Drag-and-drop interface
            </td>
            <td
              class="p-3 text-light text-center border border-white"
              style={{ backgroundColor: "rgb(46, 46, 46)" }}
            >
              ✅
            </td>
            <td class="p-3 text-light text-center border border-white">❌</td>
          </tr>
          <tr className="fs-5">
            <td class="p-3 text-light text-start border border-white">
              One-click deployment
            </td>
            <td
              class="p-3 text-light text-center border border-white"
              style={{ backgroundColor: "rgb(46, 46, 46)" }}
            >
              ✅
            </td>
            <td class="p-3 text-light text-center border border-white">❌</td>
          </tr>
          <tr className="fs-5">
            <td class="p-3 text-light text-start border border-white">
            50+ no code integrations
            </td>
            <td
              class="p-3 text-light text-center border border-white"
              style={{ backgroundColor: "rgb(46, 46, 46)" }}
            >
              ✅
            </td>
            <td class="p-3 text-light text-center border border-white">❌</td>
          </tr>
          <tr className="fs-5">
            <td class="p-3 text-light text-start border border-white">
            AI-powered BI & analytics
            </td>
            <td
              class="p-3 text-light text-center border border-white"
              style={{ backgroundColor: "rgb(46, 46, 46)" }}
            >
              ✅
            </td>
            <td class="p-3 text-light text-center border border-white">❌</td>
          </tr>
          <tr className="fs-5">
            <td class="p-3 text-light text-start border border-white">
            Rebranding & custom domain
            </td>
            <td
              class="p-3 text-light text-center border border-white"
              style={{ backgroundColor: "rgb(46, 46, 46)" }}
            >
              ✅
            </td>
            <td class="p-3 text-light text-center border border-white">❌</td>
          </tr>
          <tr className="fs-5">
            <td class="p-3 text-light text-start border border-white">
            Unlimited customization
            </td>
            <td
              class="p-3 text-light text-center border border-white"
              style={{ backgroundColor: "rgb(46, 46, 46)" }}
            >
              ✅
            </td>
            <td class="p-3 text-light text-center border border-white">❌</td>
          </tr>
          <tr className="fs-5">
            <td class="p-3 text-light text-start border border-white">
            Autoscaling
            </td>
            <td
              class="p-3 text-light text-center border border-white"
              style={{ backgroundColor: "rgb(46, 46, 46)" }}
            >
              ✅
            </td>
            <td class="p-3 text-light text-center border border-white">❌</td>
          </tr>
          <tr className="fs-5">
            <td class="p-3 text-light text-start border border-white">
            Automatic upgrades
            </td>
            <td
              class="p-3 text-light text-center border border-white"
              style={{ backgroundColor: "rgb(46, 46, 46)" }}
            >
              ✅
            </td>
            <td class="p-3 text-light text-center border border-white">❌</td>
          </tr>
          <tr className="fs-5">
            <td class="p-3 text-light text-start border border-white">
            Unified data services
            </td>
            <td
              class="p-3 text-light text-center border border-white"
              style={{ backgroundColor: "rgb(46, 46, 46)" }}
            >
              ✅
            </td>
            <td class="p-3 text-light text-center border border-white">❌</td>
          </tr>
        </table>
      </div>
      {/* <!-- //////////////////////// --> */}
      <div class="container row p-0 mx-auto my-5">
        <h1 class="text-center text-primary">How can iClimbs Creator help me?</h1>
        <div className="col-12 row p-0 m-0" style={{ position: 'relative' }}>
              <div className="col-md-6 mx-auto border border-2 p-5">
                <div className="w-75 mx-auto">
                    <h1 className="text-dark text-start p-0 m-0" style={{ fontSize: '7rem', opacity: '0.2' }}>01</h1>
                    <i className="text-dark mb-4 h1 fa-solid fa-desktop" style={{ opacity: '0.7' }}></i>
                    <h1 className="text-dark">Build Any Solution</h1>
                    <p className="my-3">
                    Easily build an HRM, CRM, ERP, or an application for almost any
                        other use case, with visual building blocks and minimal to zero
                        code components.
                    </p>
                </div>
            </div>
            <div className="col-md-6 mx-auto border border-2 p-5">
                <div className="w-75 mx-auto">
                    <h1 className="text-dark text-start p-0 m-0" style={{ fontSize: '7rem', opacity: '0.2' }}>02</h1>
                    <i className="text-dark mb-4 h1 fa-solid fa-microchip" style={{ opacity: '0.7' }}></i>
                    <h1 className="text-dark">Modern technology</h1>
                    <p className="my-3">
                        Embrace progress and adopt the latest technologies, like artificial intelligence, augmented reality, and Web3—without all the heavy lifting required in an out-of-the-box ERP system.
                    </p>
                </div>
            </div>
            <div className="col-md-6 mx-auto border border-2 p-5">
                <div className="w-75 mx-auto">
                    <h1 className="text-dark text-start p-0 m-0" style={{ fontSize: '7rem', opacity: '0.2' }}>03</h1>
                    <i className="text-dark mb-4 h1 fa-solid fa-gauge" style={{ opacity: '0.7' }}></i>
                    <h1 className="text-dark">Faster time to market</h1>
                    <p className="my-3">
                    A unified platform with visual building blocks, prebuilt code snippets, and built-in connectors helps you roll out applications faster.
                    </p>
                </div>
            </div>
            <div className="col-md-6 mx-auto border border-2 p-5">
                <div className="w-75 mx-auto">
                    <h1 className="text-dark text-start p-0 m-0" style={{ fontSize: '7rem', opacity: '0.2' }}>04</h1>
                    <i className="text-dark mb-4 h1 fa-solid fa-bars-progress" style={{ opacity: '0.7' }}></i>
                    <h1 className="text-dark">Upscale</h1>
                    <p className="my-3">
                        Transition from online databases and spreadsheets to a scalable low-code solution that can improve operational visibility and adapt to your company’s growth, regardless of its size.
                    </p>
                </div>
            </div>
            <div id="shape" className="text-center d-none d-md-block">
                <h3 className="text-white" style={{ zIndex: '12' }}>
                    iClimbs Creator<span className="d-block">ERP</span>
                </h3>
            </div>
        </div>
      </div>
      {/* <!-- //////////////////////////////////// --> */}
      <div
        class="container p-5 mx-auto text-white rounded-3"
        id="security"
        style={{ backgroundColor: "rgb(36, 87, 239)" }}
      >
        <div class="col-12 text-center">
          <i class="fa-solid fa-lock mb-4" style={{ fontSize: "3.8rem" }}></i>
          <h1>Steadfast security & management</h1>
          <h6 class="mt-4">
            Built-in enterprise-grade security that provides unwavering
            protection
          </h6>
        </div>

        <div class="col-12 row mx-auto my-5">
          <div class="col-md-1"></div>
          <div class="col-md col-12 my-4 my-md-0">
            <h5 class="text-start text-decoration-underline">
              <a href="#" class="text-white">
                Security for your apps
              </a>
            </h5>
            <ul class="text-start p-0 m-0">
              <li>Data encryption</li>
              <li>Data portability</li>
              <li>Data retention</li>
              <li>API security</li>
              <li>Session management</li>
              <li>IP retention</li>
              <li>Password policy</li>
              <li>MFA (Multi-Factor Authentication)</li>
            </ul>
          </div>
          <div class="col-md col-12 my-4 my-md-0">
            <h5 class="text-start">
              <a href="#" class="text-white text-decoration-underline">
                Security at iClimbs
              </a>
            </h5>
            <ul class="text-start p-0 m-0">
              <li>Data center security</li>
              <li>Network security</li>
              <li>Disaster security</li>
              <li>Business continuity</li>
              <li>Customer controls for security</li>
            </ul>
          </div>
          <div class="col-md col-12 mx-auto my-4 my-md-0">
            <div class="w-100">
              <h5 class="text-start">
                <a href="#" class="text-white text-decoration-underline">
                  Compliance
                </a>
              </h5>
              <ul class="text-start p-0 m-0">
                <li>GDPR</li>
                <li>ISO/IEC 27001</li>
                <li>SOC 2 + HIPAA</li>
              </ul>
            </div>
            <div class="col-md-1"></div>
          </div>
        </div>
      </div>
      {/* <!-- /////////////////////////////////// --> */}
      <div class="container p-4 p-lg-0 my-5">
        <div class="row">
          <div class="col-lg-6 m-auto mb-5 mb-lg-0">
            <img
              src="./images/personalized-erp.webp"
              alt=""
              style={{
                display: "block",
                margin: "auto",
                width: "100%",
                aspectRatio: 16 / 9,
                objectFit: "contain",
              }}
            />
          </div>
          <div class="col-lg-6 text-dark">
            <h1>Personalized portals</h1>
            <p class="">
              Create dedicated portals that enable customers and business
              partners to interact with your company's applications and data
              from a single point of access. Portals can be customized with
              preferred permission sets that allow you to control who sees what.
              Portal domains can also be rebranded with your custom domain,
              while mobile apps can be whitelabelled for both Android and iOS.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default ErpSolution;
