import { React, Link } from "react";
import "./gct.css";

const GCTRightSideBar = () => {
  return (
    // <h1>Sidce bar</h1>
    <div
      className="col-lg-4 ms-auto shadow-lg my-5 my-lg-0 border-primary"
      style={{ borderLeft: "4px solid" }}
    >
      <h4 className="my-4 px-4 ">Our Government Advisory Services</h4>

      {/* /////////////////////////////// */}
      <div className="w-100 px-4 ">
        <hr className="w-100" />
        <div className="w-100 d-flex my-auto">
          <a href="/management-consulting-and-government-advisory"><h5 className="my-auto text-primary">Consulting Services</h5></a>
          <i class="ms-auto my-auto fa-solid fa-chevron-right me-2"></i>
        </div>
      </div>
      {/* /////////////////// */}
      {/* /////////////////////////////// */}
      <div className="w-100 px-4 ">
        <hr className="w-100" />
        <div className="w-100 d-flex my-auto">
          <a href="/data-analytics-emerging-technologies"><h5 className="my-auto text-primary">Data Analytics & Emerging Technologies</h5></a>
          <i class="ms-auto my-auto fa-solid fa-chevron-right me-2"></i>
        </div>
      </div>
      {/* /////////////////// */}
      {/* /////////////////////////////// */}
      <div className="w-100 px-4 ">
        <hr className="w-100" />
        <div className="w-100 d-flex my-auto">
          <a href="/public-sector-cyber-security-services"><h5 className="my-auto text-primary">Public Sector Cyber Security Services</h5></a>
          <i class="ms-auto my-auto fa-solid fa-chevron-right me-2"></i>
        </div>
      </div>
      {/* /////////////////// */}
      {/* /////////////////////////////// */}
      <div className="w-100 px-4 ">
        <hr className="w-100" />
        <div className="w-100 d-flex my-auto">
          <a href="/software-development-system-integration"><h5 className="my-auto text-primary">Software Development and System Integration</h5></a>
          <i class="ms-auto my-auto fa-solid fa-chevron-right me-2"></i>
        </div>
        <hr className="w-100" />
      </div>
      {/* /////////////////// */}
    </div>
  )
}
export default GCTRightSideBar;
