
import { React, useEffect } from "react";
const SoftwareDevelopment = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/wow/1.1.2/wow.min.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            // Initialize Wow.js after the script has loaded
            new window.WOW().init();
        };

        // Cleanup function
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (<>
        <div>
            {/* Carousel */}
            <div className="container-fluid carousel px-0 mb-5 pb-5">
                <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner" role="listbox">
                        <div className="carousel-item active">
                            <img src='./images/banner.jpg' className="img-fluid w-100" alt="Second slide" style={{ aspectRatio: '19/6', objectFit: 'cover' }} />
                            <div className="carousel-caption">
                                <div className="container carousel-content">
                                    <h1 className="text-white display-1 mb-4 animated slideInDown">Software Development</h1>
                                    <h4 className="text-white mb-4 animated slideInDown">Measuring programming progress by lines of code is like measuring aircraft building progress by weight.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* About Software Development */}
            <div className="container wow fadeInUp reveal" data-wow-delay=".3s">
                <div className="row">
                    <div className="col-lg-6 order-lg-1 order-2 wow fadeInUp" data-wow-delay=".6s">
                        <h1 className="mt-lg-5 mt-3 text-primary">Software Development</h1>
                        <section>
                            <h2 className="mt-4 text-dark">1. Planning and Requirement Analysis</h2>
                            <p>Understand the problem or need for which the software is being developed. Define the requirements and specifications of the software. Plan the project, set goals, and establish timelines.</p>
                        </section>
                        <section>
                            <h2 className="mt-4 text-dark">2. Design</h2>
                            <p>Create a blueprint or design for the software based on the requirements. Decide on the architecture, structure, and user interface of the software. Consider scalability, maintainability, and other design principles.</p>
                        </section>
                    </div>
                    <div className="col-lg-6 order-lg-2 order-1 wow fadeInUp" data-wow-delay=".9s">
                        <img className="w-100" src='./images/about_right.jpg' alt="" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <img className="w-100" src='./images/about_img.png' alt="" />
                    </div>
                    <div className="col-lg-6">
                        <section>
                            <h2 className="mt-lg-4 mt-3 text-primary">3. Implementation (Coding)</h2>
                            <p>Write the actual code based on the design. Follow coding standards and best practices. Use programming languages and tools suitable for the project.</p>
                        </section>

                        <section>
                            <h2 className="mt-4 text-primary">4. Testing</h2>
                            <p>Conduct various levels of testing to ensure the software meets the specified requirements. Identify and fix bugs and issues. Perform unit testing, integration testing, system testing, and acceptance testing.</p>
                        </section>

                        <section>
                            <h2 className="mt-4 text-primary">5. Deployment</h2>
                            <p>Release the software for use by end-users. Plan and execute deployment strategies. Provide documentation and support for users.</p>
                        </section>
                    </div>
                </div>
            </div>

            {/* Software Development */}
            <div className="container-fluid bg-primary wow fadeInUp reveal" data-wow-delay=".3s">
                <div className="row">
                    <div className="col-md-6 wow fadeInUp" data-wow-delay=".6s">
                        <h1 className="text-white text-center mt-3">Software Development</h1>
                        <h5 className="text-white text-center border border-white w-100 p-3 mt-5">
                            Software development is a dynamic and ever-evolving field, and successful development often requires a combination of technical skills, problem-solving abilities, and effective communication within development teams and with stakeholders. The industry continues to adapt to new technologies, methodologies, and best practices.
                        </h5>
                    </div>
                    <div className="col-md-6 wow fadeInUp" data-wow-delay=".6s">
                        <img className="w-100 h-100" src='./images/software_img.jpg' alt="" />
                    </div>
                </div>
            </div>


            {/* <!--what be do start--> */}

            <div class="container-fluid  p-lg-5 wow fadeInUp reveal" data-wow-delay=".3s">
                <h1 class="text-dark text-center  ">What We Do</h1>
                <h6 class="text-dark text-center my-3">Many modern software development processes follow agile methodologies, emphasizing iterative development, collaboration,
                    and adaptability. Agile frameworks, such as Scrum or Kanban, help teams respond to changes and deliver value
                    incrementally.
                </h6>
                <div class="row">
                    <div class="col-md-4 border border-dark wow fadeInUp" data-wow-delay=".6s">
                        <h1 class="text-dark text-center rounded-circle">1</h1>
                        <h3 class="text-dark text-center my-2">Software Development</h3>
                        <h6 class="text-dark text-center">
                            Software development is a dynamic and ever-evolving field, and successful development often requires a combination of technical
                            skills, problem-solving abilities, and effective communication within development teams and with
                            stakeholders. The industry continues to adapt to new technologies, methodologies, and best practices.
                            Understand the problem or need for which the software is being developed. Define the requirements
                            and specifications of the software. Plan the project, set goals, and establish timelines. Address
                            and fix any issues or bugs that arise post-deployment. Implement updates, improvements, and new features
                            as needed. Ensure the software remains compatible with evolving technologies.
                        </h6>
                    </div>
                    <div class="col-md-4 border border-dark wow fadeInUp" data-wow-delay=".9s">
                        <h1 class="text-dark text-center">2</h1>
                        <h3 class="text-dark text-center my-2">App Development</h3>
                        <h6 class="text-dark text-justify">
                            Android app development is the process of creating applications for devices running the Android operating system. The development
                            process typically involves several key stages. Initially, developers engage in brainstorming and
                            idea generation to conceptualize innovative app concepts tailored to specific user needs. Planning
                            and design follow, where detailed blueprints, wireframes, and prototypes are created to guide the
                            development process. The actual coding is executed in the development phase, utilizing programming
                            languages such as Java or Kotlin, and frameworks like Android Studio. Rigorous testing is crucial
                            to identify and rectify bugs, ensuring the app's functionality and stability across diverse devices.
                        </h6>
                    </div>
                    <div class="col-md-4 border border-dark wow fadeInUp" data-wow-delay=".12s">
                        <h1 class="text-dark text-center ">3</h1>
                        <h3 class="text-dark text-center my-2">Web Development</h3>
                        <h6 class="text-dark text-center border-2">
                            The provided HTML template serves as a foundational structure for an app development webpage. Utilizing Bootstrap for responsive
                            design, the template outlines key stages in the app development process. It begins with "Idea Generation,"
                            emphasizing the importance of brainstorming innovative concepts tailored to the target audience.
                            Subsequent sections cover "Planning and Design," encouraging detailed plans, wireframes, and prototypes
                            for effective visualization. The "Development" section underscores the coding phase, emphasizing
                            the use of suitable languages and frameworks. Thorough "Testing" is highlighted to ensure functionality
                            and stability across various devices.
                        </h6>
                    </div>
                </div>
            </div>

            {/* <!---Our team end--> */}
            <div class="container-fluid">
                <h1 class="text-dark text-center my-3">Request A Call back</h1>
                <h5 class="text-dark text-center my-3">We're thrilled that you've reached out to us! Your interest in our services means a lot. Our team is eager to
                    connect with you and address any questions or concerns you may have.</h5>


                <div class="row bg-primary">
                    <div class="col-md-6 p-lg-2">

                        <iframe class="w-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14025.07054877449!2d77.06788697649769!3d28.501594411265565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d194154c22cf7%3A0x409982753fe0a0d4!2sPhase%20V%2C%20Udyog%20Vihar%2C%20Sector%2019%2C%20Gurugram%2C%20Haryana%20122022!5e0!3m2!1sen!2sin!4v1702120543974!5m2!1sen!2sin"
                            width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                    <div class="col-md-6 p-lg-4">
                        <div class="row">
                            <div class="col-md-12 my-3 form-group">
                                <input class="form-control" placeholder="Name" type="text" name="Name" />
                            </div>
                            <div class="col-md-12 form-group my-3">
                                <input class="form-control" placeholder="Email" type="email" name="Email" />
                            </div>
                            <div class="col-md-12 form-group my-3">
                                <input class="form-control" placeholder="Phone Number" type="text" name="Phone Number" />
                            </div>
                            <div class="col-md-12 form-group my-3">
                                <input class="form-control" placeholder="Message" type="text" Message="Name" />
                            </div>
                            <div class="col-md-12 form-group my-3">
                                <button class="btn btn-success">Send</button>
                            </div>
                            <div class="col-md-12 form-group my-3">
                                <a href="tel:+919899697667" class="text-white">
                                    <i class="fa fa-phone" aria-hidden="true"></i>&nbsp;
                                    (+91) 9899697667
                                </a>
                                <br />
                                <a href="mailto:develop@iclimbs.com" class="text-white">
                                    <i class="fa fa-envelope" aria-hidden="true"></i>&nbsp;
                                    develop@iclimbs.com
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* //////////////////////////////////////////// */}

    </>
    )
}
export default SoftwareDevelopment;