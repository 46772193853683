import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import reportWebVitals from "./reportWebVitals";
import Home from "./components/home";
import Contact from "./components/contact";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./components/about";
import NoPage from "./components/NoPages";
// import Investor from './components/investor';
import DigitalMarketing from "./components/digital-marketing";
import Healthcare from "./components/healthcare";
// import HiTech from './components/hi-tech';
// import AWS from './components/aws';
import Traveler from "./components/traveler";
import UsaStaffing from "./components/usa-staffing";
import InfrastructureStateManagementServices from "./components/infrastrcture-state-management";
import ItManagementService from "./components/it-management-services";
import ParamedicalStaffing from "./components/paramedical-staffing";
import FaclityManagement from "./components/faclity-management";
import CloudAndInrastrctureManagement from "./components/cloud-infra";
import Cybersecurity from "./components/cybersercuity";
import SoftwareDevelopment from "./components/software";
// import Consulting from './components/Consulting';
// import Interactive from './components/intractive';
// import Data from './components/data';
// import Enterprice from './components/enterprice';
// import App from './App';
import PayRollManagement from "./components/payroll-management";
import ParamedicalHealthServices from "./components/paramedical-health-services";
import LifeScience from "./components/life-science";
import ErpSolution from "./components/erp-solution";
import ErpVerticals from "./components/erp-verticals";
import ItRecruitmentStaffingService from "./components/it-recruitment-staffing-service";
//import InformationCommunicationTechnology from "./components/information-communication-technology";
import Telecom from "./components/telecom";
//import ManagementConsultingAndGovernmentAdvisory from "./components/management-consulting-and-overnment-advisory";
import FinancialBankingService from "./components/financial-banking-service";
import Menu from "./include/menu";
import "./components/Webdesign";
import Webdesign from "./components/Webdesign";
import BDO from "./components/BDO";

import GovConsltingTranjAdv from "./gov-cunsulting-transaction-advisery/gov-cunsulting-tranj-adv";
import DataAnalyticsEmergingTechnologies from "./gov-cunsulting-transaction-advisery/data-analysis-emerging-techno";
import PublicSectoreCyberSecurity from "./gov-cunsulting-transaction-advisery/public-sector-cyberSecurity";
import SoftwareDevelopmentSystemIntegration from "./gov-cunsulting-transaction-advisery/software-development-system-integration";
import Consulting from "./components/Consulting";
import PrivacyPolicy from "./components/privacy-policy";
import Footer3 from "./include/footer3";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Menu />
    <Routes>
      <Route index path="/" element={<Home />} />
      <Route index path="/home" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route
        path="/financial-banking-service"
        element={<FinancialBankingService />}
      />
      <Route path="/it-software-hardware" element={<SoftwareDevelopment />} />
      <Route path="/medical-healthcare" element={<Healthcare />} />
      <Route path="/transport-railway-logistics" element={<Traveler />} />
      {/* gct start  */}

      {/* <Route
        path="/management-consulting-and-government-advisory"
        element={<ManagementConsultingAndGovernmentAdvisory />}
      /> */}
      <Route
        path="/management-consulting-and-government-advisory"
        element={<GovConsltingTranjAdv />}
      />
      <Route path="/data-analytics-emerging-technologies" element={<DataAnalyticsEmergingTechnologies />} />
      <Route path="/data-analytics-emerging-technologies" element={<DataAnalyticsEmergingTechnologies />} />
      <Route path="/public-sector-cyber-security-services" element={<PublicSectoreCyberSecurity />}/>
      <Route path="/software-development-system-integration" element={<SoftwareDevelopmentSystemIntegration />} />
      <Route path="/consulting" element={<Consulting />} />
      {/* gct end  */}
      <Route path="/skill-development" element={<SoftwareDevelopment />} />
      <Route path="/tourism" element={<Traveler />} />
      <Route path="/telecom" element={<Telecom />} />
      {/* /////// Industries End //////////// */}
      {/* /////// Services Begain ///       */}
      <Route path="/information-communication-technology" element={<BDO />} />
      <Route
        path="/it-recruitment-staffing-service"
        element={<ItRecruitmentStaffingService />}
      />
      <Route path="/erp-solution" element={<ErpVerticals />} />
      <Route path="/erp" element={<ErpSolution />} />
      <Route path="/cyber-security" element={<Cybersecurity />} />
      <Route path="/digital-marketing" element={<DigitalMarketing />} />
      <Route path="/software-development" element={<SoftwareDevelopment />} />
      <Route
        path="/cloud-and-infra-management"
        element={<CloudAndInrastrctureManagement />}
      />

      <Route
        path="/usa-recruitment-and-staffing-services"
        element={<UsaStaffing />}
      />
      <Route
        path="/infrastructure-state-management-service"
        element={<InfrastructureStateManagementServices />}
      />
      <Route path="/it-management-service" element={<ItManagementService />} />
      <Route
        path="/facility-management-service"
        element={<FaclityManagement />}
      />
      <Route path="/web-design" element={<Webdesign />} />
      <Route path="/life-science" element={<LifeScience />} />
      <Route
        path="/para-medical-staffing-service"
        element={<ParamedicalStaffing />}
      />
      <Route
        path="/paramedical-health-services"
        element={<ParamedicalHealthServices />}
      />
      <Route path="/payroll-management" element={<PayRollManagement />} />
      {/* ////////// Footer Route ////// */}
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="*" element={<NoPage />} />
    </Routes>
    <Footer3 />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
