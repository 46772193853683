/* globals AOS */
import React, { useEffect } from "react";

const DeliverableValue = () => {
  // Ensure that useEffect hook is used if necessary for initializing scroll animations
  useEffect(() => {
    // Initialize any scroll animation library if required
    AOS.init() //for Animate On Scroll library
  }, []);
  return (
    <>
      <div id="next-level-growth" class="container-fluid overflow-hidden">
        <div class="container py-5">
          <div class="row my-auto">
            <div class="col-md-12">
              <h1 class="display-5 mb-5 text-dark text-center">
                Why we?
              </h1>
            </div>
            <div className="row my-5 mx-auto" data-aos="fade-right" data-aos-duration="1000">
              <div className="col-lg-4 text-center my-auto">
                <img src="./images/Integrity.png" alt="" className="" style={{ width: '80%', aspectRatio: 1 / 1, objectFit: 'cover' }} />
              </div>
              <div className="col d-none d-lg-block"></div>
              <div className="col-lg-6 my-4 my-lg-0">
                <h1 className="text-dark">Integrity</h1>
                <p className="fw-bold mt-4 lh-lg font-style">Integrity is integral at I-CLIMB, both as a commitment and a virtue.
                  Our business operations adhere to the highest moral standards,
                  ensuring transparency, truthfulness, and confidence in all
                  transactions. Your company merits a partner dedicated to honesty
                  as the bedrock of our collaboration, fostering a culture of reliability
                  and accountability.</p>
              </div>
            </div>

            {/* ////////////////// */}
            <div className="row my-5 mx-auto d-none d-lg-flex" data-aos="fade-left" data-aos-delay="100" data-aos-duration="1000">
              <div className="col-lg-6 my-4 my-lg-0">
                <h1 className="text-dark">Excellence</h1>
                <p className="fw-bold mt-4 lh-lg font-style">Aim for perfection in all endeavors. Our commitment involves
                  pushing boundaries, establishing new benchmarks, and delivering
                  solutions that exceed expectations. Whether it's cutting-edge IT
                  solutions or unparalleled customer service, our offerings are
                  interwoven by a shared dedication to perfection. Propel your
                  company to greater heights with I-CLIMB.</p>
              </div>
              <div className="col d-none d-lg-block"></div>
              <div className="col-lg-4 text-center my-auto">
                <img src="./images/excellence.png" alt="" className="" style={{ width: '100%', aspectRatio: 16 / 9, objectFit: 'contain' }} />
              </div>
            </div>

            {/* /////////Narsingh@1990/////for mobile view//////////////// */}
            <div className="row my-5 mx-auto d-block d-lg-none" data-aos="fade-left" data-aos-delay="100" data-aos-duration="1000">
              <div className="col-lg-4 text-center my-auto">
                <img src="./images/excellence.png" alt="" className="" style={{ width: '80%', aspectRatio: 1 / 1, objectFit: 'cover' }} />
              </div>
              <div className="col-lg-6 my-4 my-lg-0">
                <h1 className="text-dark">Excellence</h1>
                <p className="fw-bold mt-4 lh-lg font-style">Aim for perfection in all endeavors. Our commitment involves
                  pushing boundaries, establishing new benchmarks, and delivering
                  solutions that exceed expectations. Whether it's cutting-edge IT
                  solutions or unparalleled customer service, our offerings are
                  interwoven by a shared dedication to perfection. Propel your
                  company to greater heights with I-CLIMB.</p>
              </div>
            </div>
            {/* ////////////////////////// */}

            {/* /////////////////// */}
            <div className="row my-5 mx-auto" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
              <div className="col-lg-4 text-center my-auto">
                <img src="./images/collaboration.png" alt="iclibms collaboration" className="" style={{ width: '80%', aspectRatio: 1 / 1, objectFit: 'cover' }} />
              </div>
              <div className="col d-none d-lg-block"></div>
              <div className="col-lg-6 my-5 my-lg-0">
                <h1 className="text-dark">Collaboration</h1>
                <p className="fw-bold mt-4 lh-lg font-style">We understand that collaboration fuels creativity. ICLIMB is not
                  just a service provider; we serve as your collaborative partner on
                  the journey to success. Our teams work closely together,
                  combining our knowledge to overcome challenges and capitalize
                  on opportunities. Together, we strengthen each other's capabilities
                  to achieve common objectives</p>
              </div>
            </div>
            {/* ////////////////////////// */}

            <div className="row my-5 mx-auto d-none d-lg-flex" data-aos="fade-left" data-aos-delay="100" data-aos-duration="1000">
              <div className="col-lg-6 my-4 my-lg-0">
                <h1 className="text-dark">Innovation</h1>
                <p className="fw-bold mt-4 lh-lg font-style">Embrace progress with ICLIMB's dedicated approach to innovation.
                  We lead the charge in technical advancements within the rapidly
                  evolving IT landscape. Our solutions ensure your company not only
                  stays current but also anticipates future trends, staying ahead of
                  the curve. For us, innovation isn't just a buzzword; it's the driving
                  force behind every solution we deliver</p>
              </div>
              <div className="col d-none d-lg-block"></div>
              <div className="col-lg-4 text-center my-auto">
                <img src="./images/innovation.png" alt="" className="" style={{ width: '80%', aspectRatio: 1 / 1, objectFit: 'cover' }} />
              </div>
            </div>
            {/* //////////////for mobile view//////////// */}

            <div className="row my-5 mx-auto d-block d-lg-none" data-aos="fade-left" data-aos-delay="100" data-aos-duration="1000">
              <div className="col-lg-4 text-center my-auto">
                <img src="./images/innovation.png" alt="" className="" style={{ width: '80%', aspectRatio: 1 / 1, objectFit: 'cover' }} />
              </div>
              <div className="col-lg-6 my-4 my-lg-0">
                <h1 className="text-dark">Innovation</h1>
                <p className="fw-bold mt-4 lh-lg font-style">Embrace progress with ICLIMB's dedicated approach to innovation.
                  We lead the charge in technical advancements within the rapidly
                  evolving IT landscape. Our solutions ensure your company not only
                  stays current but also anticipates future trends, staying ahead of
                  the curve. For us, innovation isn't just a buzzword; it's the driving
                  force behind every solution we deliver.</p>
              </div>
            </div>

            {/* ////////////////////////// */}
          </div>
          {/* <!-- desktop mode  --> */}
          {/* <div class="row d-none d-md-flex">
            <div class="col-12 container bg-primary py-4" data-aos="fade-right" data-aos-duration="1000">
              <div class="row my-auto" style={{ backgroundColor: `rgb(79, 79, 79)` }}>
                <div class="col-md-3 bg-white" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="500">
                  <img id="values-image" src="./images/valueimage1.jpg" class="img-fluid my-5 mx-4" alt=""
                    style={{ mixBlendMode: "multiply" }} />
                </div>
                <div class="col-md-8 p-1 m-auto" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="500">
                  <div class="leaderInfo p-md-0 p-4">
                    <div class="name m-0 p-0 h1 text-start text-white my-2">
                      Integrity
                    </div>
                    <p class="text-white">
                      Integrity is integral at I-CLIMB, both as a commitment
                      and a virtue. Our business operations adhere to the
                      highest moral standards, ensuring transparency,
                      truthfulness, and confidence in all transactions. Your
                      company merits a partner dedicated to honesty as the
                      bedrock of our collaboration, fostering a culture of
                      reliability and accountability.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 container" data-aos="fade-left" data-aos-duration="1000"
              style={{ backgroundColor: `rgb(230, 230, 230)` }}>
              <div class="row">
                <div class="col-md-8 p-1 m-auto" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="500">
                  <div class="leaderInfo p-md-0 p-4">
                    <div class="name h1 text-start text-dark my-2">
                      Excellence
                    </div>

                    <p>
                      Aim for perfection in all endeavors. Our commitment
                      involves pushing boundaries, establishing new
                      benchmarks, and delivering solutions that exceed
                      expectations. Whether it's cutting-edge IT solutions or
                      unparalleled customer service, our offerings are
                      interwoven by a shared dedication to perfection. Propel
                      your company to greater heights with I-CLIMB
                    </p>
                  </div>
                </div>
                <div class="col-md-3 bg-white" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="500">
                  <img id="values-image" src="./images/valueimage2.jpg" class="img-fluid m-5" alt="" />
                </div>
              </div>
            </div>
            <div class="col-12 mt-4 container" data-aos="fade-right" data-aos-duration="1000"
              style={{ backgroundColor: `rgb(79, 79, 79)` }}>
              <div class="row">
                <div class="col-md-3 bg-white" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="500">
                  <img id="values-image" src="./images/valueimage3.jpg" class="img-fluid m-5" alt="Mr." />
                </div>
                <div class="col-md-8 p-1 m-auto" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="500">
                  <div class="leaderInfo p-md-0 p-4">
                    <div class="name h1 text-start text-white my-2">
                      Collaboration
                    </div>
                    <p class="text-white">
                      We understand that collaboration fuels creativity.
                      ICLIMB is not just a service provider; we serve as your
                      collaborative partner on the journey to success. Our
                      teams work closely together, combining our knowledge to
                      overcome challenges and capitalize on opportunities.
                      Together, we strengthen each other's capabilities to
                      achieve common objectives.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 container mt-4" style={{
              backgroundColor:
                `rgb(230, 230, 230)`
            }} data-aos="fade-left" data-aos-duration="1000">
              <div class="row">
                <div class="col-md-8 p-1 m-auto" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="500">
                  <div class="leaderInfo p-md-0 p-4">
                    <div class="name h1 text-start text-dark my-2">
                      Innovation
                    </div>
                    <p>
                      Embrace progress with ICLIMB's dedicated approach to
                      innovation. We lead the charge in technical advancements
                      within the rapidly evolving IT landscape. Our solutions
                      ensure your company not only stays current but also
                      anticipates future trends, staying ahead of the curve. For
                      us, innovation isn't just a buzzword; it's the driving
                      force behind every solution we deliver.
                    </p>
                  </div>
                </div>
                <div class="col-md-3 bg-white" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="500">
                  <img id="values-image" src="./images/valueimage4.jpg" class="img-fluid m-5" alt="Mr." />
                </div>
              </div>
            </div>
          </div> */}
          {/* <!-- desktop mode end  --> */}
          {/* <!-- Mobile mod  --> */}
          {/* <div class="row d-md-none d-flex ">
            <div class="col-12 container bg-primary py-4" data-aos="fade-right" data-aos-duration="1000">
              <div class="row my-auto" style={{ backgroundColor: `rgb(79, 79, 79)` }}>
                <div class="col-md-3 bg-white" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="500">
                  <img id="values-image" src="./images/valueimage1.jpg" class="img-fluid my-5 mx-4" alt="Mr."
                    style={{ mixBlendMode: "multiply" }} />
                </div>
                <div class="col-md-8 p-1 m-auto" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="500">
                  <div class="leaderInfo p-md-0 p-4">
                    <div class="name m-0 p-0 h1 text-start text-white my-2">
                      Integrity
                    </div>

                    <p class="text-white">
                      Integrity is integral at I-CLIMB, both as a commitment
                      and a virtue. Our business operations adhere to the
                      highest moral standards, ensuring transparency,
                      truthfulness, and confidence in all transactions. Your
                      company merits a partner dedicated to honesty as the
                      bedrock of our collaboration, fostering a culture of
                      reliability and accountability.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 container" data-aos="fade-left" data-aos-duration="1000"
              style={{ backgroundColor: `rgb(230, 230, 230)` }}>
              <div class="row">
                <div class="col-md-3 bg-white" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="500">
                  <img id="values-image" src="./images/valueimage2.jpg" class="img-fluid m-5" alt="" />
                </div>
                <div class="col-md-8 p-1 m-auto" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="500">
                  <div class="leaderInfo p-md-0 p-4">
                    <div class="name h1 text-start text-dark my-2">
                      Excellence
                    </div>
                    <p>
                      Aim for perfection in all endeavors. Our commitment
                      involves pushing boundaries, establishing new
                      benchmarks, and delivering solutions that exceed
                      expectations. Whether it's cutting-edge IT solutions or
                      unparalleled customer service, our offerings are
                      interwoven by a shared dedication to perfection. Propel
                      your company to greater heights with I-CLIMB
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-4 container" data-aos="fade-right" data-aos-duration="1000"
              style={{ backgroundColor: `rgb(79, 79, 79)` }}>
              <div class="row">
                <div class="col-md-3 bg-white" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="500">
                  <img id="values-image" src="./images/valueimage3.jpg" class="img-fluid m-5" alt="Mr." />
                </div>
                <div class="col-md-8 p-1 m-auto" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="500">
                  <div class="leaderInfo p-md-0 p-4">
                    <div class="name h1 text-start text-white my-2">
                      Collaboration
                    </div>
                    <p class="text-white">
                      We understand that collaboration fuels creativity.
                      ICLIMB is not just a service provider; we serve as your
                      collaborative partner on the journey to success. Our
                      teams work closely together, combining our knowledge to
                      overcome challenges and capitalize on opportunities.
                      Together, we strengthen each other's capabilities to
                      achieve common objectives.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 container mt-4" style={{
              backgroundColor:
                `rgb(230, 230, 230)`
            }} data-aos="fade-left" data-aos-duration="1000">
              <div class="row">
                <div class="col-md-3 bg-white" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="500">
                  <img id="values-image" src="./images/valueimage4.jpg" class="img-fluid m-5" alt="Mr." />
                </div>
                <div class="col-md-8 p-1 m-auto" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="500">
                  <div class="leaderInfo p-md-0 p-4">
                    <div class="name h1 text-start text-dark my-2">
                      Innovation
                    </div>
                    <p>
                      Embrace progress with ICLIMB's dedicated approach to
                      innovation. We lead the charge in technical advancements
                      within the rapidly evolving IT landscape. Our solutions
                      ensure your company not only stays current but also
                      anticipates future trends, staying ahead of the curve. For
                      us, innovation isn't just a buzzword; it's the driving
                      force behind every solution we deliver.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <!-- end mobile mode  --> */}
        </div>
      </div>
    </>
  );
};

export default DeliverableValue;
