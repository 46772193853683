/* global $, WOW */
import { useState, useEffect, useRef } from "react";
import { Outlet, Link } from "react-router-dom";

const Menu = () => {
  const [submenuVisible, setSubmenuVisible] = useState(false);
  const [menuSub, setmenuSub] = useState(false);
  const [itSoftware, setitSoftware] = useState(false)
  const [Recruitment, setRecruitment] = useState(false)
  const [Para, setPara] = useState(false)
  useEffect(() => {
    // Spinner
    var spinner = function () {
      setTimeout(function () {
        if ($("#spinner").length > 0) {
          $("#spinner").removeClass("show");
        }
      }, 1);
    };
    spinner(0);
    // Initiate the wowjs
    new WOW().init();

    // Back to top button
    $(window).scroll(function () {
      if ($(this).scrollTop() > 300) {
        $(".back-to-top").fadeIn("slow");
      } else {
        $(".back-to-top").fadeOut("slow");
      }
    });

    $(".back-to-top").click(function () {
      $("html, body").animate({ scrollTop: 0 }, 1500, "easeInOutExpo");
      return false;
    });
  }, []); // Ensure the useEffect dependency array is empty if there are no dependencies

  ////////// To reavel Animation className //////////////////
  useEffect(() => {
    if ($(window).width() > 992) {
      function reveal() {
        var reveals = document.querySelectorAll(".reveal");
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
      }
      window.addEventListener("scroll", reveal);
    }
  }, []);
  let userefrence = useRef(null)
  useEffect(() => {
    setSubmenuVisible(false); // sub menu visible and hide
    let sIndustries = document.querySelector("#sIndustries");
    let IndustriesSubmenu = document.querySelector("#IndustriesSubmenu");
    let sservices = document.querySelector("#sservices");
    let ServicesSubmenu = document.querySelector("#ServicesSubmenu");
    let subsubmenu = document.querySelector("#subsubmenu");
    let RecruitmentMenu = document.querySelector("#RecruitmentMenu");
    let RecruitmentMenu1 = document.querySelector("#RecruitmentMenu1");
    let subsubmenu1 = document.querySelector("#subsubmenu1");
    let RecruitmentMenu2 = document.querySelector("#RecruitmentMenu2");
    let subsubmenu2 = document.querySelector("#subsubmenu2");
    let hamburgerMenu = document.querySelector("#hamburgerMenu");
    let mobileMenu = document.querySelector("#mobileMenu");
    let bar1 = document.querySelector("#bar1");
    let bar2 = document.querySelector("#bar2");
    let bar3 = document.querySelector("#bar3");
    // Rest of your code
    //if(sIndustries && IndustriesSubmenu) {
    sIndustries.addEventListener("click", (e) => {
      ServicesSubmenu.classList.remove("Industries-submenu");
      subsubmenu.classList.remove("Industries-submenu");
      IndustriesSubmenu.classList.toggle("Industries-submenu");
    });
    // Add event listeners for other elements
    //}
    sservices.addEventListener("click", () => {
      IndustriesSubmenu.classList.remove("Industries-submenu");
      subsubmenu.classList.remove("Industries-submenu");
      ServicesSubmenu.classList.toggle("Industries-submenu");
    });

    RecruitmentMenu.addEventListener("click", () => {
      IndustriesSubmenu.classList.remove("Industries-submenu");
      subsubmenu1.classList.remove("Industries-submenu");
      subsubmenu2.classList.remove("Industries-submenu");
      subsubmenu.classList.toggle("Industries-submenu");
    });

    RecruitmentMenu1.addEventListener("click", () => {
      IndustriesSubmenu.classList.remove("Industries-submenu");
      subsubmenu.classList.remove("Industries-submenu");
      subsubmenu2.classList.remove("Industries-submenu");
      subsubmenu1.classList.toggle("Industries-submenu");
    });

    RecruitmentMenu2.addEventListener("click", () => {
      IndustriesSubmenu.classList.remove("Industries-submenu");
      subsubmenu.classList.remove("Industries-submenu");
      subsubmenu1.classList.remove("Industries-submenu");
      subsubmenu2.classList.toggle("Industries-submenu");
    });
    hamburgerMenu.addEventListener("click", () => {
      bar1.classList.toggle("bar1");
      bar2.classList.toggle("bar2");
      bar3.classList.toggle("bar3");
      mobileMenu.classList.toggle("Industries-submenu");
    });

    document.addEventListener('click', closenavbar)
  }, []); // Empty dependency array ensures this runs only once after component mount

  function closenavbar(e) {
    if (!userefrence.current.contains(e.target)) {
      setSubmenuVisible(false)
      setmenuSub(false)
      setPara(false)
      setRecruitment(false)
      setitSoftware(false)
    }
  }

  return (
    <div
      className="container-fluid p-0 m-0 row bg-primary my-auto px-lg-4 px-2"
      id="sMenubar"

    >
      <div className="col-lg-5 col-11 px-0 ps-lg-5">
        <div className="row">
          <div className="col-md-12">
            <Link
              to="/home"
              style={{
                position: "relative",
                display: "inlineBlock",
                width: "350px",
              }}
            >
              <img src="./img/iclimbs-logo.png" alt="iClimbs logo" />
            </Link>
          </div>
        </div>
      </div>
      <div className="d-flex d-lg-none px-0 col-1 my-auto" id="hamburgerMenu">
        <div className="d-lg-none d-block ms-auto">
          <div
            style={{
              height: "3px",
              width: "25px",
              background: "white",
              margin: "6px 0",
            }}
            id="bar1"
          ></div>
          <div
            style={{
              height: "3px",
              width: "25px",
              background: "white",
              margin: "6px 0",
            }}
            id="bar2"
          ></div>
          <div
            style={{
              height: "3px",
              width: "25px",
              background: "white",
              margin: "6px 0",
            }}
            id="bar3"
          ></div>
        </div>
      </div>
      <div
        className="col-lg-7 d-lg-block d-none col-12 my-auto pe-lg-5 p-0 m-0"
        id="mobileMenu"
      >
        <ul
          className="d-lg-flex justify-content-end align-items-center my-auto p-0 m-0"
          id="mobileposition"
          ref={userefrence}
        >
          <li className="my-3" onClick={() => {
            setSubmenuVisible(false)
            setmenuSub(false)
          }}>
            <Link to="/home" className="text-white p-lg-4">
              Home
            </Link>
          </li>
          <li className="position-relative my-3" onMouseEnter={() => {
            setmenuSub(!menuSub)
            setSubmenuVisible(false)
          }}
            onMouseLeave={() => { setmenuSub(false) }}>
            <Link to="#" className="text-white p-lg-4" id="sIndustries" >
              Industries
              <span style={{ fontSize: "10px" }}>
                {" "}
                <i className="fa-solid fa-chevron-down"></i>
              </span>
            </Link>
            <ul
              className={`position-absolute bg-light p-0 ${menuSub ? "d-block" : "d-none"
                }`}
              style={{
                zIndex: 100,
                top: "180%",
                left: "0%",
                borderRadius: "10px",
              }}
              id="IndustriesSubmenu"
            >
              <Link
                to="/it-software-hardware"
                className="py-4 text-primary"
                onClick={() => setmenuSub(false)}
              >
                <li className="">IT Software & Hardware</li>
              </Link>
              <Link
                to="/medical-healthcare"
                className="py-4 text-primary"
                onClick={() => setmenuSub(false)}
              >
                <li className=""> Medical & Healthcare</li>
              </Link>
              {/* <!--<a className="text-primary" href="./education.html">
                                <li className=""> Education </li>
                            </Link>--> */}
              <Link
                to="/transport-railway-logistics"
                className="text-primary"
                onClick={() => setmenuSub(false)}
              >
                <li className=""> Transport, Railway & Logistics</li>
              </Link>
              <Link
                to="/financial-banking-service"
                className="text-primary"
                onClick={() => setmenuSub(false)}
              >
                <li> Financial & Banking </li>
              </Link>
              <Link
                to="/management-consulting-and-government-advisory"
                className="text-primary"
                onClick={() => setmenuSub(false)}
              >
                <li> Management consulting & Government Advisory </li>
              </Link>
              <Link
                to="/skill-development"
                className="text-primary"
                onClick={() => setmenuSub(false)}
              >
                <li> Skill & Development </li>
              </Link>
              <Link
                to="/tourism"
                className="text-primary"
                onClick={() => setmenuSub(false)}
              >
                <li> Tourism </li>
              </Link>
              <Link
                to="/telecom"
                className="text-primary"
                onClick={() => setmenuSub(false)}
              >
                <li> Telecom </li>
              </Link>
              {/* <!--                            
                            <a className="text-primary" href="./Hi-Tech.html">
                                <li className=""> Hi Tech </li>
                            </Link>
                            <a className="text-primary" href="./aws.html">
                                <li className=""> AWS </li>
                            </Link>
                        --> */}
            </ul>
          </li>
          {/* <!-- ///////////////////////////////////////////// --> */}
          <li className="position-relative my-3" onMouseEnter={() => {
            setSubmenuVisible(true)
            setmenuSub(false)
          }}
            onMouseLeave={() => {
              setSubmenuVisible(false)
            }}
          >
            <Link to="#" className="text-white p-lg-4" id="sservices">
              Services
              <span className="ms-1" style={{ fontSize: "10px" }}>
                <i className="fa-solid fa-chevron-down"></i>
              </span>
            </Link>
            <ul
              className={`position-absolute bg-light p-0 ${submenuVisible ? "d-block" : "d-none"
                }`}
              style={{
                zIndex: 100,
                top: "170%",
                left: "0%",
                borderRadius: "10px",
              }}
              id="ServicesSubmenu"
            >
              {/* <!-- //// Begin IT Software & Hardware//////// /   --> */}
              <Link to="#" className="text-primary">
                <li className="position-relative" id="RecruitmentMenu1" onMouseEnter={() => {
                  setitSoftware(true)
                  setPara(false)
                  setRecruitment(false)
                }}
                  onMouseLeave={() => { setitSoftware(false) }}>
                  IT Software & Hardware
                  <span className="" style={{ fontSize: "10px" }}>
                    <i className="fa-solid fa-chevron-down"></i>
                  </span>
                  <ul
                    className={`border border-5 border-black position-absolute ${itSoftware ? 'd-block' : 'd-none'} bg-light p-0`}
                    style={{ top: "15%", right: "100%", borderRadius: "10px" }}
                    id="subsubmenu1"
                  >
                    <Link
                      to="/information-communication-technology"
                      onClick={() => setSubmenuVisible(!submenuVisible)}
                    >
                      <li>Software ICT/IOT Solution</li>
                    </Link>
                    <Link
                      to="/it-recruitment-staffing-service"
                      onClick={() => setSubmenuVisible(!submenuVisible)}
                    >
                      <li>IT Recruitment & Staffing</li>
                    </Link>
                    <Link
                      to="/erp-solution"
                      onClick={() => setSubmenuVisible(!submenuVisible)}
                    >
                      <li>ERP Solution</li>
                    </Link>
                    <Link
                      to="/cyber-security"
                      onClick={() => setSubmenuVisible(!submenuVisible)}
                    >
                      <li>Cyber Security</li>
                    </Link>
                    <Link
                      to="/digital-marketing"
                      onClick={() => setSubmenuVisible(!submenuVisible)}
                    >
                      <li>Digital Marketing</li>
                    </Link>
                    <Link
                      to="/web-design"
                      onClick={() => setSubmenuVisible(!submenuVisible)}
                    >
                      <li>Web Designing & Development</li>
                    </Link>
                    <Link
                      to="/cloud-and-infra-management"
                      onClick={() => setSubmenuVisible(!submenuVisible)}
                    >
                      <li>Cloud & Infra Management</li>
                    </Link>
                  </ul>
                </li>
              </Link>
              {/* <!-- /////////End IT Software & Hardware///////////////// --> */}
              <Link to="#" className="text-primary">
                <li className="position-relative" id="RecruitmentMenu" onMouseEnter={() => {
                  setRecruitment(true)
                  setitSoftware(false)
                  setPara(false)
                }}
                  onMouseLeave={() => { setRecruitment(false) }}>
                  {" "}
                  Recruitment & Staffing Services
                  <span className="" style={{ fontSize: "10px" }}>
                    <i className="fa-solid fa-chevron-down"></i>
                  </span>
                  <ul
                    className={`border border-5 border-black position-absolute ${Recruitment ? 'd-block' : 'd-none'} bg-light p-0`}
                    style={{ top: "15%", right: "100%", borderRadius: "10px" }}
                    id="subsubmenu"
                  >
                    <Link
                      to="/usa-recruitment-and-staffing-services"
                      onClick={() => setSubmenuVisible(!submenuVisible)}
                    >
                      <li>USA Recruitment & Staffing Services</li>
                    </Link>
                    <Link
                      to="/infrastructure-state-management-service"
                      onClick={() => setSubmenuVisible(!submenuVisible)}
                    >
                      <li>Infrastructure & State Management</li>
                    </Link>
                    <Link
                      to="/it-management-service"
                      onClick={() => setSubmenuVisible(!submenuVisible)}
                    >
                      <li>IT Management Service</li>
                    </Link>
                    <Link
                      to="/facility-management-service"
                      onClick={() => setSubmenuVisible(!submenuVisible)}
                    >
                      <li>Facility Management</li>
                    </Link>
                  </ul>
                </li>
              </Link>
              {/* <!-- ///////////Begin Parmedical and Healthcare//////////////////// --> */}
              <Link to="#" className="text-primary">
                <li className="position-relative" id="RecruitmentMenu2" onMouseEnter={() => {
                  setPara(true)
                  setRecruitment(false)
                  setitSoftware(false)
                }}
                  onMouseLeave={() => {
                    setPara(false)
                  }}>
                  {" "}
                  Paramedical & Healthcare
                  <span className="" style={{ fontSize: "10px" }}>
                    <i className="fa-solid fa-chevron-down"></i>
                  </span>
                  <ul
                    className={`border border-5 border-black position-absolute ${Para ? 'd-block' : 'd-none'} bg-light p-0`}
                    style={{ top: "15%", right: "100%", borderRadius: "10px" }}
                    id="subsubmenu2"
                  >
                    <Link
                      to="./life-science"
                      className="text-primary"
                      onClick={() => setSubmenuVisible(!submenuVisible)}
                    >
                      <li> Life Science </li>
                    </Link>
                    <Link
                      to="/para-medical-staffing-service"
                      onClick={() => setSubmenuVisible(!submenuVisible)}
                    >
                      <li>Para Medical Staff Service</li>
                    </Link>
                    <Link
                      to="/paramedical-health-services"
                      onClick={() => setSubmenuVisible(!submenuVisible)}
                    >
                      <li>Para Medical Health Service</li>
                    </Link>
                  </ul>
                </li>
              </Link>
              {/* <!-- Paramedical & Healthcare End ///////////// --> */}
              <Link
                to="/payroll-management"
                className="text-primary"
                onClick={() => setSubmenuVisible(!submenuVisible)}
              >
                <li className=""> PayRoll Management </li>
              </Link>
            </ul>
          </li>
          {/* <!-- ////////////////////////// --> */}
          <li className="my-3">
            <Link
              to="/about"
              className="text-white p-lg-4"
              onClick={() => {
                setSubmenuVisible(false)
                setmenuSub(false)
              }
              }
            >
              About
            </Link>
          </li>
          <li className="my-3" onClick={() => {
            setSubmenuVisible(false)
            setmenuSub(false)
          }}>
            <Link
              to="/contact"
              className="text-white p-lg-4"

            >
              Contact Us
            </Link>
          </li>
        </ul>
      </div >
      <Outlet />
    </div >
  );
};

export default Menu;
