/* global $ */
import { useEffect } from "react";
import Slider2 from "../include/slider2";
import OurClients from "../include/our-clients";
import DeliverableValue from "../include/delivarable-value";
import { Sevices } from "../include/sevices";
import MissionVision from "../include/Mission&vision";

const Home = () => {
    useEffect(() => {
        ///////////////////////next groth new room invester crouler begin////////
        $('.owl-carousel.next-growth').owlCarousel({
            loop: true,
            margin: 32,
            nav: false,
            dots: true,
            autoplay: true,
            navText: ['<img src="./img/kuimggrsh.png" className="recognitions-speak-prev" />', '<img src="./img/rthtyjr6j.png" className="recognitions-speak-next" />'],
            responsive: {
                0: {
                    items: 1,
                    nav: true,
                    dots: false,
                },
                600: {
                    items: 3
                },
                1000: {
                    items: 3
                }
            }
        });
        //////////////////next groth new room invester crouler end////////
        ///////////////// Client Speak begin ////////////////////////
        $('.owl-carousel.clientspeak').owlCarousel({
            loop: true,
            margin: 32,
            nav: false,
            dots: true,
            autoplay: true,
            autoplayTimeout: 10000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    nav: false,
                    items: 1
                },
                600: {
                    nav: false,
                    items: 1
                },
                992: {
                    items: 1,
                    nav: false
                }
            }
        });
        ////////////////// client speak caraousal end ///////////////
    }, [])
    return (
        <div  style={{
            backgroundImage: `url('./img/homebg.png')`,
            // backgroundSize: "10% 100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "0% 50%",
            backgroundOrigin: "content-box"
        }}>
            <Slider2 />
            {/* ///////////  ////////////////// */}
            <div className="container-fluid bg-primary text-white mb-2 mt-0 d-none d-lg-block">
                <div className="d-flex justify-content-evenly align-items-center">
                    <div className=" py-5 text-center">Cost Effective</div>
                    <div className=" py-5 text-center">|</div>
                    <div className=" py-5 text-center">Expert Team</div>
                    <div className=" py-5 text-center">|</div>
                    <div className=" py-5 text-center">Project Management</div>
                    <div className=" py-5 text-center">|</div>
                    <div className=" py-5 text-center">Cloud Computing Technologies</div>
                    <div className=" py-5 text-center">|</div>
                    <div className=" py-5 text-center">Focus Core Competenicies</div>
                    <div className=" py-5 text-center">|</div>
                    <div className=" py-5 text-center">Scalability</div>
                </div>
            </div>
            <div>
                {/* <!-- Delvirable value begins --> */}
                <DeliverableValue />
                {/* <!-- Delvirable value end --> */}
                <MissionVision />

                {/* Services Start here  */}
                <Sevices />
                {/* Services End here  */}
                {/* our client start here  */}
                {/* <OurClients /> */}
                {/* our Client end here  */}
                {/* <!-- start Our key feature --> */}
                {/* <div class="reveal" id="success-stories">
                    <div class="container">
                        <div class="row p-md-5 p-sm-none" id="success-storie">
                            <div class="col-6">
                                <h1 class="text-primary">
                                    Our Key Features
                                    <span></span>
                                    <span></span>
                                </h1>
                            </div>
                            <div class="col-6 text-right d-flex align-self-center justify-content-end">
                                <a href="#" class="viewall m-0 d-none">View All</a>
                            </div>
                        </div>
                        <div class="row my-3">
                            <div class="py-4 col-md-4 keyFeatures">
                                <div class="ssInfo">
                                    <h4 class=" text-center text-dark">IT Recruitment And Staffing Service</h4>
                                    <div class="ssImg overflow-hidden">
                                        <img src="./img/success_story/it-stafing-services-by-perito-staffing-servcies-llp.png"
                                            class="img-fluid hover" alt="" />
                                    </div>
                                    <h3 class="text-primary">Connecting Talent to Technology: Your Premier IT Recruitment
                                        and Staffing Partner</h3>
                                    <p>
                                        For startups or large enterprises,
                                        our extensive RPO services cater to diverse recruitment needs.
                                        These solutions, crafted for enhanced business productivity and cost-effectiveness,
                                        offer flexibility tailored to your requirements, ensuring the acquisition of top
                                        talent.
                                    </p>
                                    <Link to="/it-recruitment-staffing-service" class="readmore" data-fancybox data-width="960"
                                        data-height="540">Read more </Link>
                                </div>
                            </div>

                            <div class="py-4 col-md-4 keyFeatures">
                                <div class="ssInfo">
                                    <h4 class="text-center text-dark">Paramedical And Health Services</h4>
                                    <div class="ssImg overflow-hidden">
                                        <img src="./img/success_story/hcm-side-03.jpg" class="img-fluid hover"
                                            alt="Transformed multi-channel digital marketing ecosystem for a CPG giant!" />
                                    </div>
                                    <h3 class="text-primary">Excellence in Paramedical and Healthcare Services</h3>
                                    <p>
                                        In the Indian market, our company specializes in paramount paramedical services.
                                        Our trained professionals collaborate seamlessly with medical practitioners,
                                        playing a pivotal role in patient care, diagnostics, and healthcare delivery.
                                        Proficient in diverse disciplines such as Emergency Medical Services, Laboratory
                                        technology, radiography, and physiotherapy, our team contributes significantly to
                                        India's healthcare system.

                                    </p>
                                    <Link to="/life-science" class="readmore" title="iclimbs life science">
                                        Read More
                                        <span class="arrow"></span>
                                    </Link>
                                </div>
                            </div>
                            <div class="py-4 col-md-4 keyFeatures">
                                <div class="ssInfo">
                                    <h4 class="text-center text-dark">Financial and Banking<br></br></h4>
                                    <div class="ssImg overflow-hidden">
                                        <img src="./img/success_story/City-and-smart-building-management-systems.jpg" class="img-fluid hover"
                                            alt="Information Communication Technology" />
                                    </div>
                                    <h3 class="text-primary">Empowering Financial Futures: Your Trusted Banking Partner</h3>
                                    <p>
                                        Accelerate digital transformation for Banks and Wealth Management firms
                                        We combine our domain expertise with proprietary data and payments platforms to
                                        speed up your digital transformation journey.
                                        We have deep experience in wealth and asset management, banking and payments and
                                        have worked with large and growing global clients
                                    </p>
                                    <Link to="/financial-banking-service" class="readmore">
                                        Read More
                                        <span class="arrow"></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <!-- end our key featurs--> */}
            </div>
        </div>
    )
}

export default Home;