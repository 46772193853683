import { useLayoutEffect, useState } from 'react';
import { Link } from "react-router-dom";

const FinancialBankingService = () => {

    const [isMobile, setIsMobile] = useState(false);
    // Update the state based on screen size
    useLayoutEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as per your requirement
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <div id="full_slider_1"
                className="avia-fullwidth-slider main_color avia-shadow avia-builder-el-0 el_before_av_slideshow_full_custom_mobile avia-builder-el-first container-fluid no-padding">
                <div data-size="no scaling" data-lightbox_size="large" data-animation="slide" data-ids="138879"
                    data-video_counter="0" data-autoplay="false" data-bg_slider="false" data-slide_height=""
                    data-handle="av_slideshow_full_custom" data-interval="5000" data-className="" data-css_id=""
                    data-scroll_down="" data-control_layout="av-control-default" data-custom_markup="" data-perma_caption=""
                    data-autoplay_stopper="" data-image_attachment="" data-alttext="" data-custom_id="" data-src=""
                    data-position="top left" data-repeat="no-repeat" data-attach="scroll" data-stretch=""
                    className="avia-slideshow avia-slideshow-1 av-control-default av-default-height-applied avia-slideshow-no-scaling av_slideshow_full_custom avia-slide-slider">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="./images/financial-services-banner.jpg" className="d-block w-100 img-fluid"
                                alt="financial-services" />
                            <div className="position-absolute col-lg-4 col-md-6 col-8" style={{left: "8%",top: "30%",zIndex: 50,transform: `translateY("-50%")`}}>
                                <h2 className="text-white">Accelerate digital transformation for Banks and Wealth Management firms</h2>
                                <h3 className="text-warning mt-5">Financial Services
                                </h3>
                                <p className="text-white">We combine our domain expertise with proprietary data and payments platforms to speed up your digital transformation journey.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="breadcrumb" className="container-fluid  bg-light py-2">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item fs-6">
                                        <Link to="/home" title="iClimb" rel="home" className="text-primary">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item fs-6">
                                        <a href="#" title="Services" className="text-dark">Services</a>
                                    </li>
                                    <li className="breadcrumb-item active fs-6" aria-current="page">
                                        financial services
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row ">
                    <h1 className="text-center text-primary">
                        What makes iClimbs different
                    </h1>
                </div>
                <div className="row text-center">
                    <div className="col-lg-4 col-md-6 my-3">
                        <img src="./images/domain.png" alt="Card image" width="20%" />
                        <div className="card-body">
                            <h5 className="card-title" style={{color: "orangered"}}>
                                Domain expertise
                            </h5>
                            <p className="card-text">
                                We have deep experience in wealth and asset management, banking and
                                payments and have worked with large and growing global clients
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 my-3">
                        <img src="./images/solution.png" alt="Card image" width="20%" />
                        <div className="card-body">
                            <h5 className="card-title" style={{color: "orangered"}}>
                                Solution focus
                            </h5>
                            <p className="card-text">
                                From improving customer engagement, increasing share of revenue from
                                digital channels, driving digital product adoption to optimizing business operations, we
                                provide solutions that drive business impact.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 my-3">
                        <img className="" src="./images/strong.png" alt="Card image" width="20%" />
                        <div className="card-body">
                            <h5 className="card-title" style={{color: "orangered"}}>
                                Strong data and analytics capabilities
                            </h5>
                            <p className="card-text">
                                We leverage data and analytics to uncover issues, generate solutions,
                                implement them and monitor the performance.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <h1 className="text-center text-dark">Our Practice Areas</h1>
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col-lg-4 col-md-6 my-3">
                        <img src="./images/financial-Service-2.png" alt="Banking" />
                        <h3 className="text-primary mb-5">Banking</h3>
                        <a href="#banking" className="bg-primary rounded p-3 text-light">
                            Learn More
                            <img src="./img/rthtyjr6j.png" alt="" />
                        </a>
                    </div>
                    <div className="col-lg-4 col-md-6 my-3">
                        <img src="./images/financial-service-3.png" alt="Wealth and asset management" />
                        <h3 className="text-primary mb-5">Payments</h3>
                        <a href="#payments" className="bg-primary rounded p-3 text-light">
                            Learn More
                            <img src="./img/rthtyjr6j.png" alt="" />
                        </a>
                    </div>
                    <div className="col-lg-4 col-md-12 my-3">
                        <img src="./images/financial-Service-1.png" alt="Wealth and asset management" />
                        <h3 className="text-primary mb-5">Wealth and Asset Management</h3>
                        <a href="#wealth" className="bg-primary rounded p-3 text-light">
                            Learn More
                            <img src="./img/rthtyjr6j.png" alt="" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="container reveal my-5 pt-5 text-dark" id="wealth">
                <div className="row my-2">
                    <div className="col-md-12">
                        <h1 className="text-center text-primary">Wealth and As  set Management​</h1>
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col-md-12">
                        <h3 className="text-center text-primary">Transform your customer value chain</h3>
                    </div>
                </div>
                <div className="row my-2 pb-5">
                    <div className="col-md-12">
                        <h6 className="text-center text-dark">
                            Revolutionize your value chain, from customer engagement to business operations and
                            regulatory/compliance risks.
                        </h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 my-3">
                        <img src="./images/financial_service-4.jpg" className="img-fluid" />
                        <h6 className="my-3">Our client and advisor lifecycle solutions include:</h6>
                        <ul className="text-li">
                            <li>Building a digital portal for customer acquisition, onboarding and retention</li>
                            <li>Advisor workstations to administer superior service to investors</li>
                            <li>Marketing and Sales analytics for cross-sell/upsell personalized campaigns to drive
                                customer retention</li>
                        </ul>
                    </div>
                    <div className="col-lg-4 col-md-6 my-3">
                        <img src="./images/financial-service-5.jpg" className="img-fluid" alt="" />
                        <h6 className="my-3">Our financial and portfolio solutions use Data and AI, Experience Design and
                            Engineering to
                            help with:</h6>
                        <ul className="text-li">
                            <li>Portfolio construction and rebalancing</li>
                            <li>Investor portfolio performance analytics</li>
                            <li>Risk tolerance/goal-based planning</li>
                            <li>Portfolio operations that include modelling, rebalancing, liquidation and tax harvesting
                            </li>
                            <li>Portfolio performance analytics</li>
                        </ul>
                    </div>
                    <div className="col-lg-4 col-md-6 my-3">
                        <img src="./images/financial-service-6.jpg" className="img-fluid" alt="" />
                        <h6 className="my-3">Our operations process optimization and automation deliver transformational change
                            for core
                            operations and record-keeping services. We work with Wealth Managers to:</h6>
                        <ul className="text-li">
                            <li>Design efficient workflows</li>
                            <li>Identify automation opportunities using data science</li>
                            <li>Outsource non-core functions</li>
                        </ul>
                    </div>
                    <div className="col-lg-4 col-md-6 my-3 d-block d-lg-none setImage">
                        <img src="./images/financial-service-7.jpg" className="img-fluid" alt="" />
                        <h6 className="my-3">Our regulatory, compliance and reporting services include:</h6>
                        <ul className="text-li">
                            <li>A trade review system</li>
                            <li>Compliance and regulatory reporting</li>
                            <li>Annual client contact documents</li>
                            <li>Supervision tools and customized solutions for Investment Managers</li>
                        </ul>
                    </div>
                </div>
                <div className="row d-none d-lg-flex">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <img src="./images/financial-service-7.jpg" alt="" />
                        <h6 className="my-3">Our regulatory, compliance and reporting services include:</h6>
                        <ul className="text-li">
                            <li>A trade review system</li>
                            <li>Compliance and regulatory reporting</li>
                            <li>Annual client contact documents</li>
                            <li>Supervision tools and customized solutions for Investment Managers</li>
                        </ul>
                    </div>
                    <div className="col-md-4"></div>
                </div>
            </div>
            <div className="container-fluid bg-primary py-5 my-3 reveal me-0" id="banking">
                <div className="row me-0">
                    <div className="col-md-12 me-0">
                        <div className={isMobile ? "container-fluid" : "container"}>
                            <div className="row">
                                <div className="row my-3">
                                    <div className="col-md-12">
                                        <h1 className="text-center text-white">Banking</h1>
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-md-12">
                                        <h3 className="text-center text-white">Realize value from your digital investments
                                        </h3>
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-md-12">
                                        <p className="text-white">Get true worth from your digital investments
                                            with our digital services and platforms, blended with our deep domain
                                            know-how. Working with us will help you increase the share of revenue from
                                            digital channels and streamline business operations.
                                        </p>
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-md-4">
                                        <img src="./images/financial-Service-banking-1.png" style={{ width: "95%" }} alt="" />
                                        <p className="text-white mt-3">Our CX optimization solutions include journey
                                            optimization across the customer lifecycle and AI/ML focused solutions to
                                            create personalized experiences.</p>

                                    </div>
                                    <div className="col-md-4">
                                        <img src="./images/financial-Service-banking-2.png" style={{ width: "95%" }} alt="" />

                                        <p className="text-white mt-3">Our risk management solutions optimize risk exposure
                                            using AI/ML based modeling and analytics for credit, operational and
                                            regulatory risks.</p>

                                    </div>
                                    <div className="col-md-4">
                                        <img src="./images/financial-Service-banking-3.png" style={{ width: "95%" }} alt="" />

                                        <p className="text-white mt-3">Our operations transformation services include
                                            customer-centric journey re-design and process re-engineering, intelligent
                                            process automation for operations, integrated cloud-based, self-service
                                            analytics, and AI/ML driven efficient capacity management and remediation.
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5 my-3 reveal me-0" id="payments">
                <div className="row me-0">
                    <div className="col-md-12">
                        <div className="container text-dark">
                            <div className="row reveal">
                                <div className="row my-3">
                                    <div className="col-md-12">
                                        <h1 className="text-center text-primary">Payments</h1>
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-md-12">
                                        <h3 className="text-center text-primary">Transform your transaction lifecycle</h3>
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-md-12">
                                        <h6 className="text-center">Our payment solutions enable businesses to
                                            process payments, transfer funds cost-efficiently and manage the transaction
                                            lifecycle. We also support these solutions with analytics.</h6>
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-md-12">
                                        <h6 className="text-center">Working with us will allow you to accelerate
                                            the adoption of new payment methods and channels, reduce processing costs by
                                            speeding up AP automation, automate the regulatory and audit compliance
                                            processes, leverage data and analytics to accelerate product adoption and
                                            reduce operating costs.</h6>
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-md-4">
                                        <img src="./images/financial-Service-Payment-1.png" style={{ width: "95%" }} alt="" />
                                        <p className="mt-3">Our AP automation services will help you with PO
                                            matching and payments reconciliation, native integration with ERP systems
                                            and automation of end-to-end payment cycles.</p>
                                    </div>
                                    <div className="col-md-4">
                                        <img src="./images/financial-Service-Payment-2.png" style={{ width: "95%" }} alt="" />
                                        <p className="mt-3">Our payment rails have ready-to-implement integration
                                            points across ACH, Zelle, Mastercard Send, Prepaid cards and other payment
                                            channels.</p>
                                    </div>
                                    <div className="col-md-4">
                                        <img src="./images/financial-Service-Payment-3.png" style={{ width: "95%" }} alt="" />
                                        <p className="mt-3">Our payment analytics services include anomaly
                                            detection, a prediction engine to forecast transaction volumes and tools for
                                            targeting and campaign management to drive customer adoption.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row my-3">
                    <div className="col-md-12">
                        <h1 className="text-center text-primary">Solution Spotlight</h1>
                    </div>
                </div>
                <div className="row  text-center">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <img src="./images/financial-Service-2.png" alt="Wealth and asset management" />
                        <h3 className="text-primary text-primary">Banking</h3>
                    </div>
                    <div className="col-md-4"> </div>
                </div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h1 className="text-center text-primary">Analytics as a Service</h1>
                    </div>
                </div>
                <div className="row  text-center">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <h6 className="text-primary">We provide integrated analytics, insights and recommendations using our
                            proprietary, cloud-based ML platform to drive impact across the banking value chain.</h6>
                    </div>
                    <div className="col-md-4"> </div>
                </div>
            </div>
        </>
    )
}

export default FinancialBankingService;